import { Button, IconButton } from "@chakra-ui/button";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Center,
  Container,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { Badge } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { useToast } from "@chakra-ui/toast";
import { Editor } from "@tinymce/tinymce-react";
import { Link as ExternalLink } from "@chakra-ui/layout";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import { GiPaintRoller } from "react-icons/gi";
import PageSEO from "../../collections/seo/PageSEO";

function PageEntry() {
  const [page, setPage] = useState();
  const { id } = useParams();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [descriptionReady, setDescriptionReady] = useState(false);
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();
  useEffect(() => {

    if(id){
      setLoading(true);
      setDescriptionReady(false);
      axiosInstance
        .get("/pages/" + id)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          if (data && data.page) {
            setPage(data.page);
          }else{
            toast({
              title: "Page not found",
              description: "The page you are looking for does not exist",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
          
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    
  }, [id]);

  const savePage = () => {
    setLoading(true);

    let submitedPage = { ...page, widgets: page.widgets };
    if (editorRef.current) {
      submitedPage.seo = editorRef.current.getContent();
    }
    delete submitedPage.id;
    delete submitedPage.created;
    delete submitedPage.updated;
    axiosInstance
      .put(`/pages/${page.id}`, submitedPage)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.error === false) {
          //setCollection({ ...collection, image: data.src });
          setPage(submitedPage);
          toast({
            status: "success",
            description: "Page updated successfully",
          });
        } else {
          toast({
            status: "error",
            title: "Error",
            description: data.message,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, try again",
        });
        setLoading(false);
      });
  };
  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p={{ base: 0, md: "5" }} spacing="20px">
          <HStack justify="space-between">
            <HStack spacing="20px">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                color={useColorModeValue("gray.700", "gray.50")}
                icon={<ChevronLeftIcon />}
              />
              <Skeleton isLoaded={!loading}>
                <Heading
                  size="md"
                  minW="200px"
                  minH="25px"
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  {page?.title} {page?.slug === "/" && <Badge colorScheme="green">Front</Badge>}
                </Heading>
              </Skeleton>
            </HStack>
            <HStack>
              <Button
                onClick={() => {
                  savePage();
                }}
                isLoading={loading}
                colorScheme="green"
                disabled={!page || loading}
                size="sm"
              >
                Save
              </Button>
              <Skeleton isLoaded={!loading}>
                <ExternalLink
                  href={`${process.env.REACT_APP_SITE_URL}/page/${page?.slug}`}
                  target="_blank"
                >
                  <IconButton
                    size="sm"
                    color={useColorModeValue("gray.700", "gray.50")}
                    icon={<FaEye />}
                  />
                </ExternalLink>
              </Skeleton>
              <Skeleton isLoaded={!loading}>
                <Link to={`/app/shop/pages/${id}/editor`}>
                  <IconButton
                    size="sm"
                    color={useColorModeValue("gray.700", "gray.50")}
                    icon={<GiPaintRoller />}
                  />
                </Link>
              </Skeleton>
            </HStack>
          </HStack>
          <Grid
            templateColumns={{ base: "100%", md: "8fr 4fr" }}
            gap={"30px"}
            color={useColorModeValue("gray.700", "gray.50")}
          >
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <Skeleton isLoaded={!loading}>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      value={page?.title}
                      onChange={(e) => {
                        setPage({ ...page, title: e.target.value });
                      }}
                      placeholder="Summer page, under $100, Staff Picks"
                    />
                  </FormControl>
                </Skeleton>
                <Skeleton isLoaded={page && !loading && descriptionReady}>
                  <FormControl>
                    <FormLabel>
                      <HStack>
                        <Text>Description / SEO</Text>
                        <Text
                          bg="green.200"
                          color="gray.800"
                          fontWeight={"100"}
                          p="1"
                          fontSize={"0.8em"}
                          borderRadius={"4px"}
                        >
                          recommended
                        </Text>
                      </HStack>
                    </FormLabel>
                    {page && (
                      <Editor
                        tinymceScriptSrc="https://cdn.tiny.cloud/1/sze44wj07kdbdi9w54xvqbn53xi81mgock8dvdjgdolu31x5/tinymce/5/tinymce.min.js"
                        onInit={(evt, editor) => {
                          editorRef.current = editor;
                          setDescriptionReady(true);
                        }}
                        initialValue={page?.seo}
                        init={{
                          branding: false,
                          height: 200,
                          menubar: false,
                          placeholder: "Description...",
                          skin: colorMode === "light" ? "oxide" : "oxide-dark",
                          content_css: colorMode === "light" ? "light" : "dark",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          formats: {
                            removeformat: [
                              {
                                selector: "b,strong,em,i,font,u,strike,code",
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                              {
                                selector: "span",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "code",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "*",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </FormControl>
                </Skeleton>
              </Stack>
            </Stack>
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <Heading size="sm">Visibility</Heading>
                <Skeleton isLoaded={page}>
                  {page && (
                    <RadioGroup
                      defaultValue={page.state ? "visible" : "invisible"}
                      onChange={(e) => {
                        console.log(e);
                        if (e === "visible") {
                          setPage({ ...page, state: 1 });
                        } else {
                          setPage({ ...page, state: 0 });
                        }
                      }}
                    >
                      <Stack>
                        <Radio
                          size="md"
                          value="visible"
                          disabled={page.slug === "/"}
                          //   isChecked={page.state && true}
                          name="1"
                          colorScheme="red"
                        >
                          Visible
                        </Radio>

                        <Radio
                          size="md"
                          name="1"
                          value="invisible"
                          colorScheme="red"
                          disabled={page.slug === "/"}
                          //   isChecked={!page.state && true}
                        >
                          Invisible
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                </Skeleton>
              </Stack>
            </Stack>
          </Grid>
          {/* {collection?.id && <TakealotLinks collection={collection} />} */}
          {page && page?.id && <PageSEO page={page} setPage={setPage} />}
          {/* {collection?.id && <CollectionProducts collection={collection} />} */}
        </Stack>
      </Container>
    </Center>
  );
}

export default PageEntry;
