import React from "react";
import { Center, Container, Stack } from "@chakra-ui/layout";
import HomeGrid from "../../components/shopify/HomeGrid";
import NewProducts from "../../components/shopify/NewProducts";

function ShopHome() {
  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p={0} spacing="40px">
          <HomeGrid />
          <NewProducts />
        </Stack>
      </Container>
    </Center>
  );
}

export default ShopHome;
