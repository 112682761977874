import {
  Image,
  Stack,
  Heading,
  Text,
  Flex,
  Button,
  HStack,
  Skeleton,
  Wrap,
  Badge,
  Box,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function ProductCard({ p, setmainLoading }) {
  const dispatch = useDispatch();

  const [product, setProduct] = useState(p);

  const [loading, setLoading] = useState(false);

  let images = [];
  if (product) {
    images = JSON.parse(product.images);
  }

  useEffect(() => {
    setProduct(p);
  }, [p]);

  return (
    <Stack shadow="sm" bg="white" p="2" borderRadius={"md"}>
      <a>
        <Stack>
          <Skeleton isLoaded={product}>
            <Box position="relative">
              <Image
                h="150px"
                w="100%"
                objectFit="contain"
                src={
                  product && images.length > 0
                    ? images[0].replace("{size}", "pdpxl")
                    : ""
                }
              />
              {loading && (
                <Center
                  h="150px"
                  w="100%"
                  top="0"
                  left="0"
                  right="0"
                  position="absolute"
                  bg="rgba(255,255,255,0.9)"
                >
                  <Spinner color="red.500" />
                </Center>
              )}
            </Box>
          </Skeleton>
          <Skeleton isLoaded={product}>
            <Text align="center" size="sm" noOfLines={2} h="70px" w="100%">
              {product && product.title}
            </Text>
          </Skeleton>
        </Stack>
      </a>

      <Skeleton isLoaded={product}>
        <Stack
          align="center"
          spacing="0px"
          w="100%"
          pb="2"
          borderBottomColor="gray.200"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
        >
          <Text
            color="blue.500"
            cursor="pointer"
            fontSize={"0.9em"}
            as="u"
            // onClick={onOpen}
            fontWeight="bold"
          >
            When do I get it?
          </Text>
        </Stack>
      </Skeleton>

      <a>
        <HStack justify="space-between">
          <Skeleton isLoaded={product}>
            <Heading size="sm" color="#4da60b">
              {product &&
                product.selling_price
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Heading>
          </Skeleton>
          <Skeleton isLoaded={product}>
            <Wrap align="center">
              <Badge colorScheme="green">In Stock</Badge>
            </Wrap>
          </Skeleton>
        </HStack>
      </a>

      <Skeleton isLoaded={product}>
        <Flex direction={{ base: "column", md: "row" }}>
          <Button
            size="sm"
            borderColor={
              product && product.availability ? "#4da60b" : "red.500"
            }
            variant={product && product.availability ? "outline" : "solid"}
            fontSize="0.7em"
            flexGrow="1"
            m="1"
            colorScheme={product && product.availability ? "green" : "red"}
            // onClick={runSync}
            isLoading={loading}
          >
            {product && product.availability ? "Add To Cart" : "Out Of Stock"}
          </Button>

          <a
            href={"#"}
            style={{ flexGrow: 1 }}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="sm"
              borderWidth={"2px"}
              variant="outline"
              w="100%"
              flexGrow="1"
              fontSize="0.7em"
              m="1"
            >
              <HStack>
                <Image
                  src="https://www.pricecheck.co.za/images/pc-new-sm.png"
                  maxH="18px"
                />
                <Text>Price Check</Text>
              </HStack>
            </Button>
          </a>
        </Flex>
      </Skeleton>
    </Stack>
  );
}

export default ProductCard;
