import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BiPencil, BiPlus, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useLinks } from "../../../../../utils/contexts/useLinks";

export default function SingleExploreSection({
  columnIndex,
  rowIndex,
  templateIndex,
  cardIndex,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useSelector((state) => state.pagedata.value);
  const { linksLoading, loadLinks } = useLinks();

  const dispatch = useDispatch();

  const removeCard = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let cards = [
      ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
    ];
    cards.splice(cardIndex, 1);
    console.log(cards);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: {
        ...rows[rowIndex].columns[columnIndex][templateIndex].data,
        cards: cards,
      },
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    console.log(rows);
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];
            let images = [
              ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
            ];
            images[cardIndex] = {
              ...images[cardIndex],
              imageUrl: result.info.url,
            };
            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              data: {
                ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                cards: images,
              },
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }
        }
      }
    );
    myWidget.open();
  };

  const addChild = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];

    let cards = [
      ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
    ];

    const children = [
      ...cards[cardIndex].children,
      {
        label: "Default Title",
        url: "#",
      },
    ];

    cards[cardIndex] = { ...cards[cardIndex], children: children };

    templates[templateIndex] = {
      ...templates[templateIndex],
      data: {
        ...rows[rowIndex].columns[columnIndex][templateIndex].data,
        cards: cards,
      },
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const removeChild = (index) => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];

    let cards = [
      ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
    ];

    let children = [...cards[cardIndex].children];
    children.splice(index, 1);

    cards[cardIndex] = { ...cards[cardIndex], children: children };

    templates[templateIndex] = {
      ...templates[templateIndex],
      data: {
        ...rows[rowIndex].columns[columnIndex][templateIndex].data,
        cards: cards,
      },
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack>
      <HStack>
        <Image
          w="30px"
          h="30px"
          objectFit={"cover"}
          src={
            data.data &&
            data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
              .cards[cardIndex] &&
            data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
              .cards[cardIndex].imageUrl
          }
        />
        <Text flexGrow="1" fontSize={"xs"}>
          {data.data &&
            data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
              .cards[cardIndex] &&
            data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
              .cards[cardIndex].label}
        </Text>
        <ButtonGroup>
          <IconButton size="xs" children={<BiPencil />} onClick={onOpen} />
          <IconButton size="xs" children={<BiTrash />} onClick={removeCard} />
        </ButtonGroup>
      </HStack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit</DrawerHeader>

          <DrawerBody>
            <Stack spacing="20px" pb="20px">
              <Box bg="gray.900" position="relative">
                <Image
                  src={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex].imageUrl
                  }
                  w="100%"
                  opacity="0.5"
                />
                <IconButton
                  children={<BiPencil />}
                  position="absolute"
                  top="48%"
                  left="48%"
                  onClick={showWidget}
                />
              </Box>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  value={
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex].label
                  }
                  placeholder="section title"
                  onChange={(e) => {
                    // update label
                    let rows = [...data.data.widgets];
                    let columns = [...rows[rowIndex].columns];
                    let templates = [...rows[rowIndex].columns[columnIndex]];
                    let images = [
                      ...rows[rowIndex].columns[columnIndex][templateIndex].data
                        .cards,
                    ];
                    images[cardIndex] = {
                      ...images[cardIndex],
                      label: e.target.value,
                    };
                    //console.log(images);
                    //columns.splice(columnIndex, 1);
                    templates[templateIndex] = {
                      ...templates[templateIndex],
                      data: {
                        ...rows[rowIndex].columns[columnIndex][templateIndex]
                          .data,
                        cards: images,
                      },
                    };
                    columns[columnIndex] = templates;

                    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                    dispatch(setPageData({ ...data.data, widgets: rows }));
                  }}
                />
              </FormControl>
              <HStack justify="space-between">
                <Text>Links</Text>
                <IconButton icon={<AddIcon />} onClick={addChild} size="xs" />
              </HStack>

              {data.data &&
                data.data.widgets[rowIndex].columns[columnIndex][
                  templateIndex
                ].data.cards[cardIndex].children.map((child, index) => (
                  <Stack key={index} bg="gray.100" shadow="md" p="3">
                    <HStack justify={"space-between"}>
                      <Text>{child.label}</Text>
                      <IconButton
                        icon={<BiTrash />}
                        colorScheme="red"
                        size="xs"
                        onClick={() => {
                          removeChild(index);
                        }}
                      />
                    </HStack>
                    <Input
                      value={child.label}
                      fontSize="sm"
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        let columns = [...rows[rowIndex].columns];
                        let templates = [
                          ...rows[rowIndex].columns[columnIndex],
                        ];

                        let cards = [
                          ...rows[rowIndex].columns[columnIndex][templateIndex]
                            .data.cards,
                        ];

                        let children = [...cards[cardIndex].children];
                        children[index] = {
                          ...children[index],
                          label: e.target.value,
                        };

                        cards[cardIndex] = {
                          ...cards[cardIndex],
                          children: children,
                        };

                        templates[templateIndex] = {
                          ...templates[templateIndex],
                          data: {
                            ...rows[rowIndex].columns[columnIndex][
                              templateIndex
                            ].data,
                            cards: cards,
                          },
                        };
                        columns[columnIndex] = templates;

                        rows[rowIndex] = {
                          ...rows[rowIndex],
                          columns: columns,
                        };
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                      }}
                      bg="white"
                      placeholder="Link Label"
                    />
                    <AsyncCreatableSelect
                      isClearable
                      value={child.url}
                      isLoading={linksLoading}
                      loadOptions={loadLinks}
                      placeholder="Url"
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        let columns = [...rows[rowIndex].columns];
                        let templates = [
                          ...rows[rowIndex].columns[columnIndex],
                        ];

                        let cards = [
                          ...rows[rowIndex].columns[columnIndex][templateIndex]
                            .data.cards,
                        ];

                        let children = [...cards[cardIndex].children];
                        children[index] = {
                          ...children[index],
                          url: e,
                        };

                        cards[cardIndex] = {
                          ...cards[cardIndex],
                          children: children,
                        };

                        templates[templateIndex] = {
                          ...templates[templateIndex],
                          data: {
                            ...rows[rowIndex].columns[columnIndex][
                              templateIndex
                            ].data,
                            cards: cards,
                          },
                        };
                        columns[columnIndex] = templates;

                        rows[rowIndex] = {
                          ...rows[rowIndex],
                          columns: columns,
                        };
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                      }}
                      bg="white"
                    />
                  </Stack>
                ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
}
