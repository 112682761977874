import { Button, IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { InputLeftAddon } from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";
import {
  Stack,
  Heading,
  HStack,
  Text,
  Center,
  Container,
  Box,
  Grid,
} from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Tbody, Td, Thead, Tr } from "@chakra-ui/table";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import axiosInstance from "../../../utils/axios";

function EditFees() {
  const [settings, setSettings] = useState(null);
  const [fees, setFees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const toast = useToast();

  Array.prototype.sortBy = function (key_func, reverse = false) {
    return this.sort((a, b) => {
      var keyA = key_func(a),
        keyB = key_func(b);
      if (keyA < keyB) return reverse ? 1 : -1;
      if (keyA > keyB) return reverse ? -1 : 1;
      return 0;
    });
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/app/config/")
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setSettings(data);

        setFees(data?.markup?.fees?.sortBy((el) => el.amount) || []);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const saveFees = () => {
    setUploading(true);

    let date = new Date();

    let data = {
      ...settings,
      markup: {
        ...settings.markup,
        fees,
        updated:
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          date.getDate() +
          "-" +
          date.getHours() +
          ":" +
          date.getMinutes(),
      },
    };
    axiosInstance
      .put("/admin/config", data)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          toast({
            description: "Fees update failed",
            status: "error",
          });
        } else {
          //update order
          let newOrder = [...fees].sortBy((el) => el.amount);
          setFees(newOrder);
          toast({
            description: "Fees updated successfully",
            status: "success",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          description: "Fees update failed",
          status: "error",
        });
        console.log(err);
      });
  };

  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p="5">
          {/* The Heading */}
          <HStack justify="space-between">
            <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
              Fees
            </Heading>
          </HStack>

          <Grid templateColumns={{ base: "100%", md: "1fr 1fr" }} gap="10px">
            <Stack spacing="5">
              <FormControl bg="white" p="2" shadow="md" borderRadius={5}>
                <FormLabel htmlFor="zar_rate">ZAR Rate %</FormLabel>
                <Input
                  value={(settings && settings?.markup?.exchange_rate) || ""}
                  placeholder="ZAR Rate"
                  type="number"
                  id="zar_rate"
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      markup: {
                        ...settings?.markup,
                        exchange_rate: parseFloat(e.target.value || 0),
                      },
                    })
                  }
                />
              </FormControl>

              <Stack>
                <HStack justify={"space-between"}>
                  <Heading size="sm">Thresholds</Heading>

                  <HStack>
                    <IconButton
                      colorScheme={"green"}
                      size="sm"
                      icon={<AddIcon />}
                      onClick={() => {
                        setFees([
                          ...fees,
                          {
                            amount: parseFloat(
                              fees[fees.length - 1]?.amount + 1 || 1
                            ),
                            rate: parseFloat(fees[fees.length - 1]?.rate || 35),
                          },
                        ]);
                      }}
                    />
                    <IconButton
                      colorScheme={"blue"}
                      size="sm"
                      icon={<FaSave />}
                      isLoading={loading}
                      disabled={loading}
                      onClick={saveFees}
                    />
                  </HStack>
                </HStack>

                {fees &&
                  fees.map((fee, index) => (
                    <HStack key={index}>
                      <InputGroup>
                        <InputLeftAddon size="xs">Rate %</InputLeftAddon>
                        <Input
                          value={fee.rate}
                          placeholder="Rate"
                          type="number"
                          onChange={(e) => {
                            setFees([
                              ...fees.slice(0, index),
                              {
                                ...fee,
                                rate: parseFloat(e.target.value || 0),
                              },
                              ...fees.slice(index + 1),
                            ]);
                          }}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputLeftAddon size="xs">Amount</InputLeftAddon>
                        <Input
                          value={fee.amount}
                          onChange={(e) => {
                            setFees([
                              ...fees.slice(0, index),
                              {
                                ...fee,
                                amount: parseFloat(e.target.value || 0),
                              },
                              ...fees.slice(index + 1),
                            ]);
                          }}
                        />
                      </InputGroup>

                      <IconButton
                        disabled={index === 0}
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        onClick={() => {
                          setFees(
                            fees
                              .filter((f, i) => i !== index)
                              .sortBy((el) => el.amount)
                          );
                        }}
                      />
                    </HStack>
                  ))}
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Container>
    </Center>
  );
}

export default EditFees;
