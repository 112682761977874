import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Center, Circle, Heading, Stack, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import React, { useEffect, useState } from "react";
import { FcHighPriority } from "react-icons/fc";
import axiosInstance from "../../utils/axios";

function EmptyChat() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  const url =
    process.env.REACT_APP_INSTANCE_URL ||
    "https://api.chat-api.com/instance334898/";
  const token = process.env.REACT_APP_INSTANCE_TOKEN || "clnmpczxu5yowz3a";
  const MINUTE_MS = 30000;

  const getStatus = () => {
    setLoading(true);
    axiosInstance
      .get(url + "status?token=" + token)
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        setStatus(data);
        console.log(data);
      })
      .catch((err) => {
        setStatus({ error: err.message });
        setLoading(false);
        console.log(err.message);
      });
  };
  useEffect(() => {
    getStatus();
    const interval = setInterval(() => {
      getStatus();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  return (
    <Center h="100%" w="100%">
      <Stack align="center" justify="center">
        <Spinner
          display={loading && status === null ? "block" : "none"}
          color={useColorModeValue("gray.500", "gray.400")}
        />

        <Stack align="center" display={!status?.error ? "none" : "flex"}>
          <Circle
            size="120px"
            borderColor="white"
            borderWidth="3px"
            shadow="md"
            fontSize="3em"
            bg={useColorModeValue("gray.100", "gray.400")}
          >
            <FcHighPriority />
          </Circle>
          <Heading size="md">
            <Text color={useColorModeValue("gray.700", "gray.100")}>
              Phone Not Connected
            </Text>
          </Heading>
          <Text color="red.400">{status?.error}</Text>
        </Stack>

        <Stack
          align="center"
          display={status?.accountStatus === "authenticated" ? "flex" : "none"}
        >
          <Circle
            size="120px"
            borderColor="white"
            borderWidth="3px"
            shadow="md"
            fontSize="3em"
            bg={useColorModeValue("green.100", "green.100")}
          >
            <Image
              src={"https://app.chat-api.com/assets/images/wifi-signal.png"}
            />
          </Circle>
          <Heading size="md">
            <Text color={useColorModeValue("gray.700", "gray.100")}>
              Keep phone connected
            </Text>
          </Heading>
          <Text color="green.400">Phone Connected</Text>
        </Stack>

        <Stack
          align="center"
          display={status?.accountStatus === "got qr code" ? "flex" : "none"}
        >
          <Box
            size="120px"
            borderColor="white"
            borderWidth="3px"
            shadow="md"
            fontSize="3em"
            bg={useColorModeValue("green.100", "green.100")}
          >
            <Image src={status?.qrCode} />
          </Box>
          <Heading size="md">
            <Text color={useColorModeValue("gray.700", "gray.100")}>
              Please Scan QR Code
            </Text>
          </Heading>
          <Text color="gray.400">Unathenticated</Text>
        </Stack>
      </Stack>
    </Center>
  );
}

export default EmptyChat;
