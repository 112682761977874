import { useColorModeValue } from "@chakra-ui/color-mode";
import { Circle, Stack, Text } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { BiUser } from "react-icons/bi";
import { BsCart, BsTruck, BsListNested } from "react-icons/bs";
import React from "react";
import ProfileInfo from "./ProfileInfo";
import ProfileTags from "./ProfileTags";
import ClientChatOrders from "../orders/ClientChatOrders";
import ClientChatCart from "../cart/ClientChatCart";
import ProfileNotes from "./ProfileNotes";

function ChatTopHalf({ tags, setTags }) {
  return (
    <Tabs isFitted>
      <TabList
        position="sticky"
        top="0"
        zIndex="2"
        bg={useColorModeValue("gray.100", "gray.800")}
      >
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Circle
            my="1px"
            size="40px"
            bg={useColorModeValue("gray.700", "transparent")}
            color={useColorModeValue("gray.50", "gray.50")}
            borderWidth="1px"
            borderColor={useColorModeValue("gray.700", "gray.600")}
          >
            <BiUser />
          </Circle>
        </Tab>
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Circle
            size="40px"
            my="1px"
            bg={useColorModeValue("gray.700", "transparent")}
            color={useColorModeValue("gray.50", "gray.50")}
            borderWidth="1px"
            borderColor={useColorModeValue("gray.700", "gray.600")}
            position="relative"
          >
            <BsCart />
            <Text
              position="absolute"
              top="-1"
              right="-3"
              bg="red.500"
              px="2"
              py="1"
              fontSize="xs"
              borderRadius="full"
              color="white"
            >
              0
            </Text>
          </Circle>
        </Tab>
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Circle
            size="40px"
            my="1px"
            bg={useColorModeValue("gray.700", "transparent")}
            color={useColorModeValue("gray.50", "gray.50")}
            borderWidth="1px"
            borderColor={useColorModeValue("gray.700", "gray.600")}
            position="relative"
          >
            <BsTruck />
            <Text
              position="absolute"
              top="-1"
              right="-3"
              bg="red.500"
              px="2"
              py="1"
              fontSize="xs"
              borderRadius="full"
              color="white"
            >
              0
            </Text>
          </Circle>
        </Tab>
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Circle
            size="40px"
            my="1px"
            bg={useColorModeValue("gray.700", "transparent")}
            color={useColorModeValue("gray.50", "gray.50")}
            borderWidth="1px"
            borderColor={useColorModeValue("gray.700", "gray.600")}
            position="relative"
          >
            <BsListNested />
          </Circle>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack spacing="40px">
            <ProfileInfo />
            <ProfileTags tags={tags} setTags={setTags} />
            <ProfileNotes />
          </Stack>
        </TabPanel>
        <TabPanel>
          <ClientChatCart />
        </TabPanel>
        <TabPanel>
          <ClientChatOrders />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default ChatTopHalf;
