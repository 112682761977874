import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Skeleton,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { BiPencil } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/axios";
import { setConfig } from "../../utils/redux/configSlice";

function SettingsPage() {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config.value.config);
  console.log(config);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/app/config/")
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        dispatch(setConfig(data));
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const toast = useToast();

  const saveConfig = () => {
    setLoading(true);
    axiosInstance
      .put("/admin/config", config)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          toast({
            description: "Menu update failed",
            status: "error",
          });
        } else {
          toast({
            description: "Menu updated successfully",
            status: "success",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          description: "Menu update failed",
          status: "error",
        });
        console.log(err);
      });
  };

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            dispatch(setConfig({ ...config, topMessage: result.info.url }));
          }
        }
      }
    );
    myWidget.open();
  };
  const showMobileWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            dispatch(
              setConfig({ ...config, topMessageMobile: result.info.url })
            );
          }
        }
      }
    );
    myWidget.open();
  };
  return (
    <Stack
      w="100%"
      h="100%"
      overflowY="scroll"
      p="5"
      spacing="30px"
      position="relative"
    >
      <HStack justify={"space-between"}>
        <Stack>
          <Heading>Settings</Heading>
          <Text>Update Site Settings</Text>
        </Stack>
        <Button isLoading={loading} onClick={saveConfig}>
          Save
        </Button>
      </HStack>

      <Grid templateColumns={{ base: "100%", md: "1fr 1fr" }} gap="10px">
        <Stack spacing="40px">
          <Text fontWeight={600}>Site Appearance</Text>
          <HStack bg="white" p="3" borderRadius={4} shadow="md">
            <FormControl>
              <FormLabel>Background Color</FormLabel>
              <Skeleton isLoaded={config && config.colors}>
                <SketchPicker
                  color={
                    config && config.colors ? config.colors.background : "#fff"
                  }
                  onChangeComplete={(color) => {
                    dispatch(
                      setConfig({
                        ...config,
                        colors: { ...config.colors, background: color.hex },
                      })
                    );
                  }}
                />
              </Skeleton>
            </FormControl>
            <FormControl>
              <FormLabel>Background Text-Color</FormLabel>
              <Skeleton isLoaded={config && config.colors}>
                <SketchPicker
                  color={
                    config && config.colors
                      ? config.colors.backgroundText
                      : "#fff"
                  }
                  onChangeComplete={(color) => {
                    dispatch(
                      setConfig({
                        ...config,
                        colors: { ...config.colors, backgroundText: color.hex },
                      })
                    );
                  }}
                />
              </Skeleton>
            </FormControl>
          </HStack>
          <Stack>
            <Text fontWeight={600}>Top Banner : Desktop</Text>
            <Box bg="gray.900" position="relative">
              <Image src={config && config.topMessage} w="100%" opacity="0.5" />
              <IconButton
                children={<BiPencil />}
                position="absolute"
                top="1"
                left="48%"
                size="xs"
                onClick={showWidget}
              />
            </Box>
            <Text fontWeight={600}>Top Banner : Mobile</Text>
            <Box bg="gray.900" position="relative">
              <Image
                src={config && config.topMessageMobile}
                fallbackSrc={config && config.topMessage}
                w="100%"
                opacity="0.5"
              />
              <IconButton
                children={<BiPencil />}
                position="absolute"
                top="1"
                left="48%"
                size="xs"
                onClick={showMobileWidget}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <FormControl>
              <Text fontWeight={600}>Site Name</Text>
              <Input
                bg="white"
                value={config && config.title}
                onChange={(e) => {
                  dispatch(setConfig({ ...config, title: e.target.value }));
                }}
              />
            </FormControl>
          </Stack>
          <Stack>
            <Text fontWeight={600}>About Us</Text>
            <Skeleton isLoaded={config && "about" in config}>
              <Textarea
                bg="white"
                value={config && config.about}
                onChange={(e) => {
                  dispatch(setConfig({ ...config, about: e.target.value }));
                }}
              />
            </Skeleton>
          </Stack>
        </Stack>
        <Stack bg="white" p="2" borderRadius={2}>
          <Text>Social Links</Text>
          <FormControl>
            <FormLabel>Facebook</FormLabel>
            <Input
              value={config && config.socials && config.socials.facebook}
              onClick={(e) =>
                dispatch(
                  setConfig({
                    ...config,
                    socials: { ...config.socials, facebook: e.target.value },
                  })
                )
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Twitter</FormLabel>
            <Input
              value={config && config.socials && config.socials.twitter}
              onClick={(e) =>
                dispatch(
                  setConfig({
                    ...config,
                    socials: { ...config.socials, twitter: e.target.value },
                  })
                )
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Instagram</FormLabel>
            <Input
              value={config && config.socials && config.socials.instagram}
              onClick={(e) =>
                dispatch(
                  setConfig({
                    ...config,
                    socials: { ...config.socials, instagram: e.target.value },
                  })
                )
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Youtube</FormLabel>
            <Input
              value={config && config.socials && config.socials.youtube}
              onClick={(e) =>
                dispatch(
                  setConfig({
                    ...config,
                    socials: { ...config.socials, youtube: e.target.value },
                  })
                )
              }
            />
          </FormControl>
        </Stack>
      </Grid>
    </Stack>
  );
}

export default SettingsPage;
