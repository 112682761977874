import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl } from "@chakra-ui/form-control";
import { AddIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Box, Center, Heading, HStack, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/table";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import NewTakealot from "./NewTakealot";
import TakealotLink from "./TakealotLink";

function TakealotLinks({ collection, tlinks }) {

  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState(tlinks);

  useEffect(() => {

    setLinks(tlinks);

  },[tlinks]);
  
  return (
    <Box>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        p={5}
        borderRadius="md"
        shadow="md"
      >
        <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
          Takealot Sources
        </Heading>
        {loading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {!loading && (
          <NewTakealot
            collection={collection}
            links={links}
            setLinks={setLinks}
          />
        )}
        <Box d="inline-block">
          <Table style={{ tableLayout: "fixed" }} variant="simple" size="sm">
            <Tbody>
              {links.map((link) => (
                <TakealotLink key={link.id} link={link} setLinks={setLinks} />
              ))}
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Box>
  );
}

export default TakealotLinks;
