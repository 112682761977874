import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import React from "react";

function CaurosrlGridImages({ data }) {
  return (
    <Stack pt="2" bg="white">
      <Heading size={{ base: "sm", md: "md" }} px="3" color="gray.900">
        {data.title}
      </Heading>
      <Box>
        <Splide
          options={{
            perPage: data.data.layout.xl,
            gap: "5px",
            arrows: false,
            lazyLoad: "nearby",
            padding: "5rem",
            rewind: true,
            autoplay: false,
            pagination: false,
            classes: { arrows: "nav-arrow" },
            breakpoints: {
              1200: {
                perPage: data.data.layout.lg,
              },
              975: {
                perPage: data.data.layout.md,
              },
              640: {
                perPage: data.data.layout.base,
              },
            },
          }}
        >
          {data &&
            data.data.cards.map((card, index) => (
              <SplideSlide key={index}>
                <Link href={"#"} passHref>
                  <Stack
                    overflow="hidden"
                    p="0"
                    transition="all 0.3s ease-in-out"
                    align="center"
                    _hover={{ cursor: "pointer" }}
                    pb="3"
                  >
                    <Image
                      src={card.imageUrl}
                      alt={card.label}
                      w="100%"
                      h="auto"
                      p="2"
                    />
                    {card.label && (
                      <Text
                        textAlign="center"
                        fontSize={{
                          base: "0.7em",
                          md: "0.9em",
                          lg: "1em",
                          xl: "1em",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: card.label.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></Text>
                    )}
                  </Stack>
                </Link>
              </SplideSlide>
            ))}
        </Splide>
      </Box>
    </Stack>
  );
}

export default CaurosrlGridImages;
