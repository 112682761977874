import { useColorModeValue } from "@chakra-ui/color-mode";
import { Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { client } from "../../utils/shopify";
import HomeProduct from "./HomeProduct";

function NewProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([null, null, null, null, null]);
  const toast = useToast();

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);

      axiosInstance
        .get("/sundry/new")
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          if (data?.products) {
            setProducts(data.products);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  return (
    <Stack>
      <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
        New Active Products
      </Heading>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        p={"3"}
        borderRadius="md"
        shadow="md"
        spacing="2px"
      >
        {products.map((product, index) => (
          <HomeProduct product={product} index={index} key={index} />
        ))}
      </Stack>
    </Stack>
  );
}

export default NewProducts;
