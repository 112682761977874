import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  BiCaretDown,
  BiCaretRight,
  BiFullscreen,
  BiPencil,
  BiTrash,
} from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setConfig } from "../../../utils/redux/configSlice";
import ChildMainNav from "./ChildMainNav";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function StickyTopMenu({ config, position, provided }) {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isNavOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const removeNav = () => {
    let nav = [...config.stickytop];

    nav.splice(position, 1);

    dispatch(setConfig({ ...config, stickytop: nav }));
  };

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let nav = [...config.stickytop];
            nav[position] = {
              ...nav[position],
              image: result.info.url,
            };

            dispatch(setConfig({ ...config, stickytop: nav }));
          }
        }
      }
    );
    myWidget.open();
  };

  return (
    <>
      <Stack ref={provided.innerRef} {...provided.draggableProps}>
        <HStack
          justify="space-between"
          cursor="pointer"
          p="2"
          shadow="sm"
          borderRadius={"md"}
          borderBottomRadius={isOpen ? "0px" : "md"}
          bg={useColorModeValue("gray.50", "gray.700")}
        >
          <HStack flexGrow="1">
            {isOpen ? <BiCaretDown /> : <BiCaretRight />}
            <Heading
              onClick={onToggle}
              size="xs"
              dangerouslySetInnerHTML={{
                __html: config.stickytop[position].title.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></Heading>
          </HStack>
          <IconButton children={<BiPencil />} onClick={onOpen} size="xs" />

          <IconButton
            children={<BiTrash />}
            onClick={removeNav}
            size="xs"
            colorScheme="red"
          />
          <div {...provided.dragHandleProps}>
            <MdDragIndicator />
          </div>
        </HStack>
      </Stack>

      <Drawer isOpen={isNavOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent color={useColorModeValue("gray.800", "gray.50")}>
          <DrawerCloseButton />
          <DrawerHeader>Edit Menu</DrawerHeader>

          <DrawerBody>
            <Stack>
              <Box bg="blue.50" p="3" borderRadius={"5px"} position="relative">
                <Image
                  src={config.stickytop[position].image}
                  w="100%"
                  opacity="0.5"
                />
                <IconButton
                  children={<BiPencil />}
                  position="absolute"
                  top="48%"
                  left="48%"
                  onClick={showWidget}
                />
              </Box>

              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  value={config.stickytop[position].title}
                  onChange={(e) => {
                    let nav = [...config.stickytop];
                    nav[position] = {
                      ...nav[position],
                      title: e.target.value,
                    };

                    dispatch(setConfig({ ...config, stickytop: nav }));
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Url</FormLabel>
                <Input
                  value={config.stickytop[position].url}
                  onChange={(e) => {
                    let nav = [...config.stickytop];
                    nav[position] = {
                      ...nav[position],
                      url: e.target.value,
                    };

                    dispatch(setConfig({ ...config, stickytop: nav }));
                  }}
                />
              </FormControl>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default StickyTopMenu;
