import { PlusSquareIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";

function TextSection({ columnIndex, rowIndex, templateIndex }) {
  const data = useSelector((state) => state.pagedata.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const removeSection = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    templates.splice(templateIndex, 1);

    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack
      p="2"
      borderColor={"gray.100"}
      borderWidth={"1px"}
      borderStyle={"solid"}
    >
      <HStack align="center">
        <Heading
          size="xs"
          borderBottomWidth={"1px"}
          borderBottomColor={"gray.100"}
          flexGrow="1"
        >
          Text Section
        </Heading>

        <IconButton
          size="xs"
          children={<BiTrash />}
          onClick={() => {
            removeSection();
          }}
        />
      </HStack>
      <FormControl>
        <FormLabel fontSize="xs">Title</FormLabel>
        <Input
          value={
            data &&
            data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
              .title
          }
          fontSize={"xs"}
          onChange={(e) => {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];

            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              title: e.target.value,
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs">Content</FormLabel>
        <Button size="sm" onClick={onOpen}>
          Edit Content
        </Button>
        <Drawer size="md" isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Content</DrawerHeader>

            <DrawerBody>
              <Textarea
                rows="20"
                value={
                  data &&
                  data.data.widgets[rowIndex].columns[columnIndex][
                    templateIndex
                  ].content
                }
                fontSize={"xs"}
                onChange={(e) => {
                  let rows = [...data.data.widgets];
                  let columns = [...rows[rowIndex].columns];
                  let templates = [...rows[rowIndex].columns[columnIndex]];
                  //console.log(images);
                  //columns.splice(columnIndex, 1);
                  templates[templateIndex] = {
                    ...templates[templateIndex],
                    content: e.target.value,
                  };
                  columns[columnIndex] = templates;

                  rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                  dispatch(setPageData({ ...data.data, widgets: rows }));
                }}
              >
                {data &&
                  data.data.widgets[rowIndex].columns[columnIndex][
                    templateIndex
                  ].content}
              </Textarea>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </FormControl>

      <Text fontSize={"sm"}>[visible or hidden]</Text>
      <Grid templateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }} gap="10px">
        <FormControl>
          <FormLabel fontSize="xs">Mobile</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.base
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    base: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
              // setLayout({ ...layout, base: e.target.value });
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Tablet View</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.md
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    md: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Base Laptop</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.lg
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    lg: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Large Screens</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.xl
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    xl: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
      </Grid>
    </Stack>
  );
}

export default TextSection;
