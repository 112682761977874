import { useColorModeValue } from "@chakra-ui/color-mode";
// import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Badge, Box, HStack, Square, Stack, Text } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { MdSend } from "react-icons/md";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/avatar";
import React from "react";
import { BsChatLeft, BsInbox, BsPeople } from "react-icons/bs";
import { Spinner } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ChatItem from "./ChatItem";
import ChatListView from "./ChatListView";
import { useLocation } from "react-router";
import CustomerList from "./customers/CustomerList";

function ChatList() {
  const pool = useSelector((state) => state.pool.value);

  return (
    <Stack
      bg={useColorModeValue("gray.100", "gray.800")}
      spacing="0"
      w="300px"
      minW="300px"
      h="100%"
      overflowY="scroll"
    >
      <Tabs isFitted>
        <TabList>
          <Tab color={useColorModeValue("gray.700", "gray.400")}>
            <HStack position="relative" size="43px">
              <BsChatLeft size="25px" />
              <Text
                fontSize="10px"
                p="1"
                bg={"red.500"}
                color="white"
                borderRadius="10px"
                top="5px"
                left="5px"
                shadow="md"
                visibility={
                  pool.unread > 0 && pool.unread !== "-" ? "visible" : "hidden"
                }
              >
                {pool.unread}
              </Text>
            </HStack>
          </Tab>
          <Tab color={useColorModeValue("gray.700", "gray.400")}>
            <HStack position="relative" size="43px">
              <BsInbox size="30px" />

              <Text
                fontSize="10px"
                p="1"
                bg={"red.500"}
                color="white"
                borderRadius="10px"
                top="5px"
                left="5px"
                shadow="md"
                visibility={
                  pool.incomingChats.length > 0 ? "visible" : "hidden"
                }
              >
                {pool.incomingChats.length}
              </Text>
            </HStack>
          </Tab>
          <Tab color={useColorModeValue("gray.700", "gray.400")}>
            <Square position="relative" size="43px">
              <BsPeople size="25px" />
            </Square>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel h="100%" p={0}>
            <ChatListView chats={pool.activeChats} />
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <ChatListView chats={pool.incomingChats} />
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <CustomerList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default ChatList;
