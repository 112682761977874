// import { useColorModeValue } from "@chakra-ui/color-mode";
import { HStack } from "@chakra-ui/layout";
import React from "react";
import { Route, Routes } from "react-router";
import ChatList from "./ChatList";
import ChatContainer from "./container/ChatContainer";
import EmptyChat from "./EmptyChat";

function ChatFrame() {
  return (
    <HStack h="100%" align="start" justify="start" w="100%" spacing="0">
      <ChatList />
      <Routes>
        <Route index element={<EmptyChat />} />

        <Route path="/:phone" element={<ChatContainer />} />
      </Routes>
    </HStack>
  );
}

export default ChatFrame;
