import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/input";
import { Center, Heading, Stack, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";

function PageSEO({ page, setPage }) {
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    setSlug(page.slug);
  }, [page]);

  const submitPage = () => {
    setLoading(true);
    axiosInstance
      .put("/pages/" + page.id, { slug: slug })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.error) {
          toast({ description: data.error, status: "error", title: "Error" });
        } else {
          toast({
            description: "Page slug updated",
            status: "success",
            title: "Success",
          });
          setPage({ ...page, slug: slug });
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={5}
      borderRadius="md"
      shadow="md"
      spacing="25px"
    >
      <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
        Search Engine Optimisation
      </Heading>

      <Stack spacing="2px">
        <Heading
          size="md"
          fontSize="1.3em"
          mb={0}
          pb={0}
          fontWeight="regular"
          color={useColorModeValue("blue.500", "cyan.200")}
        >
          {page.title}
        </Heading>
        <Text
          mt="0"
          color="green.600"
          fontSize="sm"
        >{`https://${process.env.REACT_APP_SHOPIFY_DOMAIN}/page/${page.slug}`}</Text>
      </Stack>

      <Stack color={useColorModeValue("gray.700", "gray.50")}>
        <Text>URL and slug</Text>
        <InputGroup size="sm" borderRadius="md">
          <InputLeftAddon
            display={{ base: "none", md: "flex" }}
            children={`https://${process.env.REACT_APP_SHOPIFY_DOMAIN}/page/`}
          />
          <Input
            placeholder="slug"
            pr="2rem"
            value={slug}
            onChange={(e) => {
              setSlug(e.target.value);
            }}
            borderRadius="md"
          />
          <InputRightElement width="3.8rem">
            <Button
              isLoading={loading}
              h="1.75rem"
              size="sm"
              disabled={page && page.slug === "/"}
              onClick={submitPage}
            >
              {"Save"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Stack>
    </Stack>
  );
}

export default PageSEO;
