import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Badge, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import React from "react";

function CollectionProduct({ index, product }) {


  return (
    <HStack
      w="100%"
      bg={useColorModeValue("gray.50", "gray.900")}
      _hover={{ bg: useColorModeValue("gray.100", "gray.900") }}
      cursor="pointer"
      py={5}
      px={2}
    >
      <Text
        fontSize="sm"
        px="5"
        color={useColorModeValue("gray.700", "gray.50")}
      >
        {index + 1}.
      </Text>

      <Skeleton isLoaded={product}>
        <Image
          src={
            product && JSON.parse(product.images)[0].replace("{size}", "fb")
          }
          //fallbackSrc="https://placehold.it/500x"
          w="50px"
          h="50px"
          borderRadius="sm"
        />
      </Skeleton>
      <Stack flexGrow="1" color={useColorModeValue("gray.700", "gray.50")}>
        <Skeleton isLoaded={product}>
          <Heading size="xs">{product ? product.title : "Title"}</Heading>
        </Skeleton>
        <HStack>
          <Skeleton isLoaded={product}>
            <Text fontSize="0.8em" fontWeight="bold">
              {`US$
        ${
          product ? product.selling_price : "Price"
        }`}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={product}>
            {product && product.availability !== 1 ? (
              <Badge colorScheme="blue" py="1" px="2" borderRadius="full">
                Draft
              </Badge>
            ) : (
              <Badge colorScheme="green" py="1" px="2" borderRadius="full">
                {product && product.availability === 1 ? "Active" : "Draft"}
              </Badge>
            )}
          </Skeleton>
        </HStack>
      </Stack>
    </HStack>
  );
}

export default CollectionProduct;
