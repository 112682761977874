import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from "@chakra-ui/input";
import { Flex, Heading, HStack, Stack, Text, Center } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Portal } from "@chakra-ui/portal";
import { Spinner } from "@chakra-ui/spinner";
import React, { useEffect, useRef, useState } from "react";
import { BiSort } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";
import { debounce } from "throttle-debounce";
import axiosInstance from "../../../../utils/axios";
import { client } from "../../../../utils/shopify";
import BrowseProducts from "./BrowseProducts";
import CollectionProduct from "./CollectionProduct";
import SearchCollectionProducts from "./SearchCollectionProducts";

function CollectionProducts({ collection }) {
 
  const [hasMore, setHasMore] = useState(false);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const sorts = [
    { title: "Lowest Price", value: `["selling_price","ASC"]` },
    { title: "Highest Price", value: `["selling_price","DESC"]` },
    { title: "Most Recent", value: `["created","DESC"]` },
    { title: "Oldest", value: `["created","ASC"]`  },
  ];

  const [sort, setSort] = useState(sorts[0]);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const modal = useDisclosure();
  const ref = useRef();
  const debounceFunc = debounce(1000, false, async (query) => {
    setSearch(query);
    loadProducts(query, 1);
    // if (query.length > 0) {
    //   await modal.onOpen();
    //   ref.current.value = "";
    // }
  });

  useEffect(() => {
    setLoading(true);
    console.log(collection);
    loadProducts(search, page);
}, [collection, sort.value, page]);


const loadProducts = (query, page) =>{
  axiosInstance
    .get("/collections/"+collection.id+"/products", { params: { sort: sort.value, page: page, query: query } })
    .then((res) => res.data)
    .then((data) => {
      console.log(data);
      setLoading(false);
      if (data.status === 200) {
        //setCategory(data.category);
        setCount(data.count);
        if (page > 1) {
          setProducts([...products, ...data.products]);
        } else {
          setProducts(data.products);
        }
        setHasMore(data.pages > page);
      }
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
}
 

 
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={5}
      borderRadius="md"
      shadow="md"
      spacing="25px"
      color={useColorModeValue("gray.700", "gray.50")}
    >
      <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
        Collection Products
      </Heading>
      <Flex direction={{ base: "column", md: "row" }} w="100%">
        <InputGroup pr="0">
          <InputLeftElement children={<SearchIcon />} />
          <Input
            ref={ref}
            placeholder="Search"
            onClick={async () => {
              //await modal.onOpen();
            }}
            onChange={(e) => {
              debounceFunc(e.target.value);
            }}
          />
          <InputRightElement children={loading && <Spinner />} />
        </InputGroup>
        <HStack pl="1" mx="1" mt={{ base: "10px", md: "0px" }}>
          {/* <BrowseProducts collection={collection} /> */}
          <Menu w="100%">
            <MenuButton w="100%">
              <Button size="sm" fontWeight="normal">
                Sort :{" "}
                <Text fontWeight="bold" ml="2">
                  {sort.title}
                </Text>
                <BiSort />
              </Button>
            </MenuButton>
            <MenuList zIndex="3">
              {sorts.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setSort(option);
                  }}
                >
                  <HStack>
                    <BsCheck
                      style={{ opacity: sort.value === option.value ? 1 : 0 }}
                    />
                    <Text>{option.title}</Text>
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      {products.map((product, index) => (
        <CollectionProduct key={index} product={product} index={index} />
      ))}
      {hasMore && (
        <Center>
          <Button isLoading={loading} onClick={() => {setPage(page+1)}}>
            Loard More
          </Button>
        </Center>
      )}

      <SearchCollectionProducts
        search={search}
        setSearch={setSearch}
        modal={modal}
      />
    </Stack>
  );
}

export default CollectionProducts;
