import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";

import AsyncCreatableSelect from "react-select/async-creatable";
import { useLinks } from "../../../../../utils/contexts/useLinks";

function SingleLogo({ rowIndex, columnIndex, templateIndex, imageIndex }) {
  const data = useSelector((state) => state.pagedata.value);

  const dispatch = useDispatch();

  const { linksLoading, loadLinks } = useLinks();

  console.log(data);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const removeImage = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let images = [...rows[rowIndex].columns[columnIndex][templateIndex].data];
    images.splice(imageIndex, 1);
    console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = { ...templates[templateIndex], data: images };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    console.log(rows);
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];
            let images = [
              ...rows[rowIndex].columns[columnIndex][templateIndex].data,
            ];
            images[imageIndex] = {
              ...images[imageIndex],
              imageUrl: result.info.url,
            };
            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              data: images,
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }
        }
      }
    );
    myWidget.open();
  };

  return (
    <>
      {data && (
        <HStack justify="space-between">
          <Image
            w="30px"
            h="30px"
            objectFit={"cover"}
            src={
              data.data &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data[imageIndex] &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data[imageIndex].imageUrl
            }
          />

          <ButtonGroup>
            <IconButton size="xs" children={<BiPencil />} onClick={onOpen} />
            <IconButton
              size="xs"
              children={<BiTrash />}
              onClick={removeImage}
            />
          </ButtonGroup>
        </HStack>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Image Slide</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="2">
            <Stack spacing="20px" pb="20px">
              <Box bg="gray.900" position="relative">
                <Image
                  src={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data[imageIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data[imageIndex].imageUrl
                  }
                  w="100%"
                  opacity="0.5"
                />
                <IconButton
                  children={<BiPencil />}
                  position="absolute"
                  top="48%"
                  left="48%"
                  onClick={showWidget}
                />
              </Box>

              <FormControl>
                <FormLabel fontSize={"xs"}>Url</FormLabel>
                <AsyncCreatableSelect
                  isClearable
                  value={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data[imageIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data[imageIndex].url
                  }
                  isLoading={linksLoading}
                  loadOptions={loadLinks}
                  placeholder="Url"
                  onChange={(e) => {
                    let rows = [...data.data.widgets];
                    let columns = [...rows[rowIndex].columns];
                    let templates = [...rows[rowIndex].columns[columnIndex]];
                    let images = [
                      ...rows[rowIndex].columns[columnIndex][templateIndex]
                        .data,
                    ];
                    images[imageIndex] = {
                      ...images[imageIndex],
                      url: e,
                    };
                    //console.log(images);
                    //columns.splice(columnIndex, 1);
                    templates[templateIndex] = {
                      ...templates[templateIndex],
                      data: images,
                    };
                    columns[columnIndex] = templates;

                    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                    dispatch(setPageData({ ...data.data, widgets: rows }));
                  }}
                />
              </FormControl>

              <Box>
                <Button size="sm" onClick={onClose}>
                  Save
                </Button>
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SingleLogo;
