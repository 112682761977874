import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useState } from "react";
import Slim from "../../../../utils/slim/slim.react";
import "../../../../utils/slim/slim.min.css";
import { Box, Center } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useColorModeValue } from "@chakra-ui/color-mode";
import axiosInstance from "../../../../utils/axios";
import { useToast } from "@chakra-ui/toast";

function EditCollectionImage({ collection, setCollection }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const removeImage = () => {
    //setLoading(true);
    axiosInstance
      .delete(`/collections/${collection.id}/image`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        //setLoading(false);
        if (data.status === "success") {
          setCollection({ ...collection, image: null });
        } else {
          toast({ status: "error", title: "Error", description: data.message });
        }
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, try again",
        });
        //setLoading(false);
      });
  };

  const addImage = (output) => {
    //setLoading(true);
    axiosInstance
      .post(`/collections/${collection.id}/image`, { output })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setCollection({ ...collection, image: data.src });
        } else {
          toast({ status: "error", title: "Error", description: data.message });
        }
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, try again",
        });
        setLoading(false);
      });
  };
  return (
    <Box position="relative">
      <Skeleton isLoaded={!loading}>
        <Slim
          label="Add collection image"
          instantEdit={true}
          maxFileSize={2}
          initialImage={collection.image && process.env.REACT_APP_EXTERNAL_API+collection.image}
          willRemove={(data, remove) => {
            // f.doRemove();

            if (window.confirm("Are you sure?")) {
              removeImage();
              remove();
            }
          }}
          didSave={(e) => {
            addImage(e.output);
          }}
          //   didRemove={() => {
          //     setImage(null);
          //   }}
        >
          <input type="file" hidden="true" name="slim" />
        </Slim>
      </Skeleton>
    </Box>
  );
}

export default EditCollectionImage;
