import Entry from "../pages/basic/Entry";
import { SocketProvider } from "./sockets/useSocket";
const AppRoutes = [
  {
    path: "/app/*",
    element: (
      <SocketProvider>
        <Entry />
      </SocketProvider>
    ),
  },
];
export default AppRoutes;
