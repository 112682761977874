import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/avatar";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Badge, Box, HStack, Stack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import {
  BsCameraVideo,
  BsCheck2,
  BsCheck2All,
  BsDownload,
  BsImageAlt,
  BsMusicNote,
  BsPinMap,
  BsTelephoneForward,
} from "react-icons/bs";
import { Link } from "react-router-dom";

function ChatItem({ chat }) {
  const date = new Date(
    chat.lastMessage ? parseInt(chat.lastMessage.time * 1000) : "now"
  );
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  const [istoday, setIsToday] = useState(false);
  const [isyesterday, setIsYesterDay] = useState(false);
  //check if yesterday is same as date
  useEffect(() => {
    if (
      yesterday.getDay() === date.getDay() &&
      yesterday.getMonth() === date.getMonth() &&
      yesterday.getFullYear() === date.getFullYear()
    ) {
      let yesterday = true;
      setIsToday(false);
      setIsYesterDay(true);
    } else if (
      today.getDay() === date.getDay() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    ) {
      let yesterday = false;
      setIsToday(true);
    } else {
      let istoday = false;
    }
  }, [chat]);

  return (
    <Link to={`/app/chat/${chat.phone}`}>
      <HStack
        py="5"
        px="3"
        spacing="3"
        cursor="pointer"
        _hover={{ bg: useColorModeValue("gray.200", "gray.900") }}
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        borderBottomWidth="1px"
        transition="all 0.35s ease-in-out"
      >
        <Box>
          <Avatar src={chat.avatar} size="md" borderColor="white">
            <AvatarBadge
              bg="green.300"
              boxSize="1.25em"
              fontSize="0.55em"
              shadow="md"
              p="8px"
              color="gray.700"
            >
              {chat.unread}
            </AvatarBadge>
          </Avatar>
        </Box>
        <Stack flexGrow="1">
          <HStack justify="space-between">
            <Text
              fontSize="0.85em"
              color={useColorModeValue("gray.700", "gray.50")}
              fontWeight="600"
            >
              {chat.name ? chat.name : chat.phone.replace("@c.us", "")}
            </Text>

            <Text
              fontSize="0.8em"
              display={chat.unread ? "initial" : "none"}
              color={useColorModeValue("gray.600", "gray.500")}
            >
              {istoday && date.getHours() + ":" + date.getMinutes() + " Hrs"}
              {isyesterday && "Yesterday"}
              {!istoday &&
                !isyesterday &&
                date.getDate() +
                  "/" +
                  (date.getMonth() + 1) +
                  "/" +
                  date.getFullYear()}
            </Text>
          </HStack>
          <HStack>
            {chat?.lastMessage?.from_me &&
              chat?.lastMessage?.status == "sent" && <BsCheck2 />}
            {chat?.lastMessage?.from_me &&
              chat?.lastMessage?.status == "delivered" && <BsCheck2All />}
            {chat?.lastMessage?.from_me &&
              chat?.lastMessage?.status == "viewed" && (
                <BsCheck2All color="rgb(0, 149, 255)" />
              )}

            {chat?.lastMessage?.type === "video" && (
              <BsCameraVideo size="10px" />
            )}
            {chat?.lastMessage?.type === "location" && <BsPinMap size="10px" />}
            {chat?.lastMessage?.type === "ppt" && <BsMusicNote size="10px" />}
            {chat?.lastMessage?.type === "audio" && <BsMusicNote size="10px" />}
            {chat?.lastMessage?.type === "image" && <BsImageAlt size="10px" />}
            {chat?.lastMessage?.type === "document" && (
              <BsDownload size="10px" />
            )}
            {chat?.lastMessage?.type === "call_log" && (
              <BsTelephoneForward size="10px" />
            )}
            <Text
              fontSize="0.75em"
              color={useColorModeValue("gray.600", "gray.500")}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
            >
              {chat?.lastMessage?.type === "chat" && chat?.lastMessage?.body}
              {chat?.lastMessage?.type === "note" && chat?.lastMessage?.body}
              {chat?.lastMessage?.type === "video" && "Video"}
              {chat?.lastMessage?.type === "location" && "Location"}
              {chat?.lastMessage?.type === "ppt" && "Audio"}
              {chat?.lastMessage?.type === "audio" && "Audio"}
              {chat?.lastMessage?.type === "image" && "Image"}
              {chat?.lastMessage?.type === "document" && "Document"}
              {chat?.lastMessage?.type === "call_log" && "Call"}
            </Text>
          </HStack>
          <HStack justify="space-between">
            <Badge
              colorScheme="orange"
              px="3"
              py="1"
              fontWeight="bold"
              borderRadius="full"
              fontSize="0.6em"
            >
              Followup
            </Badge>
            <AvatarGroup size="xs" max={2}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
          </HStack>
        </Stack>
      </HStack>
    </Link>
  );
}

export default ChatItem;
