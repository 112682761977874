import { useColorModeValue } from "@chakra-ui/color-mode";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Heading, HStack, Stack, Center } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Switch } from "@chakra-ui/switch";
import { debounce } from "throttle-debounce";
import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/toast";
import axiosInstance from "../../../utils/axios";
import TakealotProduct from "./TakealotProduct";
import ShopifyProduct from "./ShopifyProduct";
import { Button } from "@chakra-ui/button";
import { client } from "../../../utils/shopify";

function SearchContainer({ tags, setTags }) {
  const [shopify, setShopify] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cursor, setCursor] = useState(null);
  const [searchQuery, setSetQuery] = useState("");

  const [products, setProducts] = useState([]);

  const [fees, setFees] = useState();

  const toast = useToast();

  const debounceFunc = debounce(1000, false, (query) => {
    if (query.length > 0) {
      let fetchtags = [...tags];
      setTags([...fetchtags, query]);
    }
    setSetQuery(query);
    // console.log(query);

    loadProducts(false, query);
  });

  useEffect(() => {
    axiosInstance
      .get("/store/fees")
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.zar_rate) {
          setFees(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const loadProducts = (check, query) => {
    setLoading(true);
    let items = [...products];
    if (shopify) {
      console.log("err");
      let data = { first: 20 };
      console.log(query);
      if (query) {
        data.query = "title:*" + query + "*";
      }
      if (cursor && check) {
        data.after = cursor;
      } else {
        items = [];
      }

      // Build a custom products query using the unoptimized version of the SDK
      const productsQuery = client.graphQLClient.query((root) => {
        root.addConnection("products", { args: { ...data } }, (product) => {
          product.add("title");
          product.add("tags"); // Add fields to be returned
          product.add("priceRange", (priceRange) => {
            priceRange.add("minVariantPrice", (minVariantPrice) => {
              minVariantPrice.add("amount");
            });
          });
          product.add(
            "metafield",
            { args: { key: "takealot", namespace: "global" } },
            (metafield) => {
              metafield.add("key");
              metafield.add("value");
            }
          );
          product.addConnection("images", { args: { first: 1 } }, (image) => {
            image.add("src");
          });
        });
      });

      // Call the send method with the custom products query
      client.graphQLClient
        .send(productsQuery)
        .then(({ model, data }) => {
          // Do something with the products

          setProducts([...items, ...model.products]);

          if (data.products.pageInfo.hasNextPage) {
            console.log(
              data.products.edges[data.products.edges.length - 1].cursor
            );
            setCursor(
              data.products.edges[data.products.edges.length - 1].cursor
            );
          } else {
            setCursor(null);
          }

          // console.log(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      let url =
        "https://api.takealot.com/rest/v-1-10-0/searches/products?filter=Availability:0.0-0.0";

      if (query) {
        url += "&qsearch=" + query;
      }
      if (cursor && check) {
        url += "&after=" + cursor;
      } else {
        items = [];
      }

      console.log(url);
      axiosInstance
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          setLoading(false);
          console.log(data);

          if (data?.sections?.products) {
            setProducts([...items, ...data.sections.products.results]);
            if (!data.sections.products.paging.next_is_after) {
              setCursor(null);
            } else {
              setCursor(data.sections.products.paging.next_is_after);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          toast({
            status: "error",
            description: "Error searching takealot, check your connection",
          });
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    loadProducts(false, searchQuery);
  }, [shopify]);

  return (
    <Stack spacing="20px">
      <HStack>
        <Switch
          isChecked={shopify}
          onChange={() => {
            setCursor(null);
            setProducts([]);
            setShopify(!shopify);
            // loadProducts(false);
          }}
        />
        <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
          Search {shopify ? process.env.REACT_APP_NAME : "Takealot"}
        </Heading>
      </HStack>
      <InputGroup>
        <Input
          bg={useColorModeValue("gray.50", "gray.700")}
          color={useColorModeValue("gray.700", "gray.50")}
          placeholder={`Search ${
            shopify ? process.env.REACT_APP_NAME : "Takealot"
          }`}
          borderRadius="20px"
          shadow="sm"
          fontSize="0.8em"
          onChange={(e) => {
            debounceFunc(e.target.value);
          }}
          // value={phone}
          // onChange={(e) => setPhone(e.target.value)}
          borderColor={useColorModeValue("gray.300", "gray.500")}
        />

        {loading && (
          <InputRightElement
            cursor="pointer"
            color="gray.300"
            children={<Spinner />}
          />
        )}
      </InputGroup>
      {loading && (
        <Center>
          <Spinner />
        </Center>
      )}

      <Stack spacing="2px">
        {fees &&
          products.map((product, index) =>
            !shopify ? (
              <TakealotProduct
                product={product}
                fees={fees}
                key={product.product_views.core.id}
              />
            ) : (
              <ShopifyProduct product={product} fees={fees} key={index} />
            )
          )}

        <Stack color={useColorModeValue("gray.700", "gray.50")}>
          {cursor && (
            <Button
              isLoading={loading}
              onClick={() => {
                loadProducts(true);
              }}
            >
              Load More
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SearchContainer;
