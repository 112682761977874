import {
  Box,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

function HorizontalProductCard({ product }) {
  let images = [];
  if (product) {
    images = JSON.parse(product.images);
  }
  return (
    <Box w="100%" shadow="md" bg="white" px="1">
      <HStack w="100%" py="4">
        <Image
          w="80px"
          h="auto"
          src={
            product && images.length > 0
              ? images[0].replace("{size}", "pdpxl")
              : ""
          }
        />

        <Stack>
          <Skeleton isLoaded={product}>
            <Text noOfLines={1}> {product && product.title}</Text>
          </Skeleton>
          <Skeleton isLoaded={product}>
            <Heading size="sm">
              {"$"}
              {product &&
                product.selling_price
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Heading>
          </Skeleton>
        </Stack>
      </HStack>
    </Box>
  );
}

export default HorizontalProductCard;
