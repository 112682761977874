import { Button, IconButton } from "@chakra-ui/button";
import { useColorModeValue, useColorMode } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import Select from 'react-select'
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Input, InputGroup } from "@chakra-ui/input";
import {
  Center,
  Container,
  Grid,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
  Badge,
  Wrap
} from "@chakra-ui/layout";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Editor } from "@tinymce/tinymce-react";
import axiosInstance from "../../../../utils/axios";
import { useToast } from "@chakra-ui/toast";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDisclosure } from "@chakra-ui/hooks";
import { Checkbox, Skeleton, Switch } from "@chakra-ui/react";
import ProductImage from "../newproduct/ProductImage";
import { TagsInput } from "react-tag-input-component";

function EditProduct() {
  const [images, setImages] = useState([]);
  const [text, setText] = useState("");
  const [tags, setTags] = useState([]);
  const [collections, setCollections] = useState([]);
  const [collects, setCollects] = useState([]);
  const [product, setProduct] = useState({
    title: "",
    images: images,
    type: "manual",
    selling_price: 10,
    vendor: "Dailysale",
  });

  const { id } = useParams();
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();
  const [descriptionReady, setDescriptionReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "huvuru",
      uploadPreset: "ponhwf4e",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        console.log(result);

        setImages([...images, result.info.url]);
        //console.log("Done! Here is the image info: ", result.info);
      }
    }
  );

  const showWidget = () => {
    myWidget.open();
  };

  useEffect(() => {
    setProduct({ ...product, images: images });
  }, [images]);

  useEffect(() => {
    setLoading(true);
    setDescriptionReady(false);
    axiosInstance
      .get("/admin/products/" + id)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data.product) {
          setProduct({
            ...data.product,
            images: JSON.parse(data.product.images),
            vendor: data.product.brand,
          });
    
          setCollections(data.collections.map((col, index) => {
            return {
              value: col.id,
              label: col.title
            }
          }));

          setCollects(data.collects.map((col, index) => {
            return {
              value: col.id,
              label: col.title
            }
          }))

          setTags(JSON.parse(data.product.tags));
          
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // const setImage = (image) => {
  //   setCollection({ ...collection, image });
  // };

  const saveProduct = async (force) => {
    setLoading(true);
    let submitedProduct = { ...product };
    if (editorRef.current) {
      submitedProduct.description = editorRef.current.getContent();
    }
    submitedProduct.force = force;
    submitedProduct.collects = collects;
    console.log(submitedProduct);
    axiosInstance
      .put("/admin/products/" + id, submitedProduct)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        console.log(data);
        if (data?.error) {
          toast({
            status: "error",
            title: "Error",
            description: data.message,
            position: "top",
          });
        } else if (data?.warning) {
          onOpen();
        } else {
          toast({
            status: "success",
            title: "Success",
            description: "Product updated successfully",
            position: "top",
          });
          //   setTimeout(function () {
          //     navigate("/app/shop/products/" + data.id);
          //   }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, contact dev support",
          position: "top",
        });
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p="5" spacing="20px">
          <HStack justify="space-between">
            <HStack spacing="20px">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                color={useColorModeValue("gray.700", "gray.50")}
                icon={<ChevronLeftIcon />}
              />
              <Heading
                size="md"
                color={useColorModeValue("gray.700", "gray.50")}
              >
                {product.title}
              </Heading>
            </HStack>
            <Button
              onClick={() => {
                saveProduct(false);
              }}
              isLoading={loading}
              colorScheme="green"
              disabled={!product?.title || !product?.selling_price || loading}
              size="sm"
            >
              Save
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
              <ModalOverlay />
              <ModalContent
                bg={useColorModeValue("gray.100", "gray.800")}
                color={useColorModeValue("gray.700", "gray.50")}
              >
                <ModalHeader bg={useColorModeValue("gray.50", "gray.900")}>
                  Duplicate Product(s) found
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    A potential duplicate product was found. You can choose to
                    change the title, or create duplicate
                  </Text>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" size="sm" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      onClose();
                      saveProduct(true);
                    }}
                  >
                    Create Copy
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </HStack>
          <Grid
            templateColumns={{ base: "100%", md: "8fr 4fr" }}
            gap={"30px"}
            color={useColorModeValue("gray.700", "gray.50")}
          >
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    value={product?.title}
                    onChange={(e) => {
                      setProduct({ ...product, title: e.target.value });
                    }}
                    placeholder="Macbook air, under $100, Staff Picks"
                  />
                </FormControl>
                <Skeleton isLoaded={product && !loading && descriptionReady}>
                  <FormControl>
                    <FormLabel>Description (optional)</FormLabel>
                    {product && (
                      <Editor
                        tinymceScriptSrc="https://cdn.tiny.cloud/1/sze44wj07kdbdi9w54xvqbn53xi81mgock8dvdjgdolu31x5/tinymce/5/tinymce.min.js"
                        onInit={(evt, editor) => {
                          editorRef.current = editor;
                          setDescriptionReady(true);
                        }}
                        initialValue={product?.description}
                        init={{
                          branding: false,
                          height: 200,
                          menubar: false,
                          placeholder: "Description...",
                          skin: colorMode === "light" ? "oxide" : "oxide-dark",
                          content_css: colorMode === "light" ? "light" : "dark",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          formats: {
                            removeformat: [
                              {
                                selector: "b,strong,em,i,font,u,strike,code",
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                              {
                                selector: "span",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "code",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "*",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </FormControl>
                </Skeleton>
              </Stack>
              <Stack
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                p="5"
              >
                <FormControl color={useColorModeValue("gray.700", "gray.700")}>
                  <FormLabel color={useColorModeValue("gray.700", "gray.50")}>
                    Product Images
                  </FormLabel>
                  <Grid
                    templateColumns={{ base: "100%", md: "1fr 1fr" }}
                    gap="10px"
                  >
                    {product &&
                      product.images &&
                      product.images.map((img, index) => (
                        <ProductImage
                          image={img}
                          key={index}
                          position={index}
                          images={images}
                          setImages={setImages}
                        />
                      ))}

                    {product && product.images && product.images.length < 4 && (
                      <Center
                        bg="gray.200"
                        cursor="pointer"
                        minH="110px"
                        onClick={() => {
                          //setImages([...images, undefined]);
                          showWidget();
                        }}
                      >
                        <Text>+</Text>
                      </Center>
                    )}
                  </Grid>
                </FormControl>
              </Stack>
            </Stack>
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>
                    Availability :
                    {product && product.availability ? " Active" : " Draft"}
                  </FormLabel>
                  <Switch
                    colorScheme="green"
                    isChecked={product && product.availability}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        availability: e.target.checked,
                      });
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Selling Price</FormLabel>
                  <Input
                    value={product?.selling_price}
                    type="number"
                    onChange={(e) => {
                      setProduct({ ...product, selling_price: e.target.value });
                    }}
                    placeholder="10,20,30"
                  />
                </FormControl>
              </Stack>

              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Vendor/Brand</FormLabel>
                  <Input
                    value={product?.brand}
                    type="text"
                    onChange={(e) => {
                      setProduct({ ...product, brand: e.target.value });
                    }}
                    placeholder="Apple, Samsung"
                  />
                </FormControl>
              </Stack>

              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <Stack>
                  <FormLabel>Tags</FormLabel>
                  <Wrap>
                    {tags.map((tag, index) => (
                      <Badge
                        colorScheme={"blue"}
                        p="1"
                        key={index}
                        pr="2px"
                        py="2px"
                        borderRadius="5px"
                      >
                        <HStack>
                          <Text fontSize="10px">{tag}</Text>
                          <Button
                            fontSize="10px"
                            colorScheme={"blue"}
                            minW="20px"
                            px="2"
                            h="20px"
                            onClick={() => {
                              setTags(tags.filter((t) => t !== tag));
                              setProduct({...product,tags:JSON.stringify(tags)});
                            }}
                          >
                            X
                          </Button>
                        </HStack>
                      </Badge>
                    ))}
                  </Wrap>
                  <Input
                    value={text}
                    placeholder="Enter custom tags"
                    fontSize="xs"
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (
                        event.key === "Enter" &&
                        text.length > 0 &&
                        !tags.includes(text)
                      ) {
                        setTags([...tags, text]);
                        setText("");
                        setProduct({...product,tags:JSON.stringify(tags)});
                      }
                    }}
                  />
                  </Stack>
                  
                </FormControl>

                
              </Stack>
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
              <FormControl>
                  <Stack>
                    <FormLabel>Categories</FormLabel>
                    <Select isMulti onChange={(e) => {setCollects(e)}} value={collects} defaultValue={collects} options={collections} />
                  </Stack>
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Container>
    </Center>
  );
}

export default EditProduct;
