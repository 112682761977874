import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Stack, Text, HStack, Badge } from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import React from "react";
import { useNavigate } from "react-router";

function PagesListItem({ page, selectedPages, setSelectedPages }) {
  const navigate = useNavigate();
  return (
    <Tr
      color={useColorModeValue("gray.700", "gray.50")}
      bg={useColorModeValue("white", "gray.800")}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
      transition={"all 0.35s ease-in-out"}
      cursor="pointer"
      py={0}
    >
      <Td>
        <Checkbox
          disabled={page.slug === "/"}
          isChecked={page.slug !== "/" && selectedPages.includes(page.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedPages([...selectedPages, page.id]);
            } else {
              setSelectedPages(selectedPages.filter((id) => id !== page.id));
            }
          }}
        />
      </Td>
      <Td
        d={{ base: "none", md: "table-cell" }}
        onClick={() => navigate(`/app/shop/pages/${page.id}`)}
      ></Td>
      <Td
        fontSize="0.9em"
        onClick={() => navigate(`/app/shop/pages/${page.id}`)}
      >
        <Stack>
          <HStack>
            <Text fontWeight={400}>{page.title}</Text>{" "}
            {page.state === 0 && <Badge>Hidden</Badge>}
            {page.slug === "/" && <Badge colorScheme={"green"}>Front</Badge>}
          </HStack>
          <Text fontWeight={200}>{page.created.substring(0, 10)}</Text>
        </Stack>
      </Td>
      <Td
        fontSize="0.9em"
        onClick={() => navigate(`/app/shop/collections/${page.id}`)}
      >
        {page.slug}
      </Td>
    </Tr>
  );
}

export default PagesListItem;
