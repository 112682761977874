import React, { useState, useEffect, useContext, createContext } from "react";
import axiosInstance from "../axios";

const LinksContext = createContext();

// Hook for child components to get auth ojbect....
// ... and re-render when it changes
export const useLinks = () => {
  return useContext(LinksContext);
};

//Provider hook that creates auth object and haldes state
export const LinksProvider = ({ children }) => {
  ////user user user

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const runSearcch = async (search) => {
    let response = [];
    try {
      let collections = await axiosInstance.get("/collections", {
        params: { page: 1, title: search },
      });
      let pages = await axiosInstance.get("/pages", {
        params: { page: 1, title: search },
      });

      //start sorting
      let options = [
        {
          label: "Collections",
          options: [],
        },
        {
          label: "Pages",
          options: [],
        },
      ];

      collections.data.collections.map((colletion) => {
        options[0].options.push({
          label: colletion.title,
          value: "/categories/" + colletion.handle,
        });
      });

      pages.data.data.map((page) => {
        options[1].options.push({
          label: page.title,
          value: "/page/" + page.slug,
        });
      });

      response = options;
    } catch (err) {
      console.log(err);
    }

    return response;
  };

  const loadLinks = (inputValue) =>
    new Promise(async (resolve) => {
      let results = await runSearcch(inputValue);
      resolve(results);
    });

  //Subscribe the user on mounts

  useEffect(() => {
    // just search for all links
  }, []);

  const values = {
    linksLoading: loading,
    options,
    loadLinks,
  };

  return (
    <LinksContext.Provider value={values}>{children}</LinksContext.Provider>
  );
};
