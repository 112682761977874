import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import React from "react";

function GridCardCaurosel({ data }) {
  return (
    <Stack pt="5" bg>
      <Heading size="sm" color="red.500">
        {data.title}
      </Heading>
      <Box>
        <Splide
          options={{
            perPage: data.data.layout.xl,
            gap: "20px",
            lazyLoad: "nearby",
            rewind: true,
            autoplay: true,
            pagination: false,
            classes: { arrows: "nav-arrow" },
            breakpoints: {
              1200: {
                perPage: data.data.layout.lg,
              },
              975: {
                perPage: data.data.layout.md,
              },
              640: {
                perPage: data.data.layout.base,
              },
            },
          }}
        >
          {data &&
            data.data.cards.map((card, index) => (
              <SplideSlide key={index}>
                <Link href={"#"} passHref>
                  <Stack
                    shadow="md"
                    bg="white"
                    overflow="hidden"
                    p="0"
                    transition="all 0.3s ease-in-out"
                    borderRadius="3px"
                    borderWidth="2px"
                    borderColor="gray.50"
                    align="center"
                    _hover={{ cursor: "pointer", shadow: "lg" }}
                    pb="3"
                  >
                    <Image
                      src={card.imageUrl}
                      alt={card.label}
                      w="100%"
                      h="auto"
                      p="2"
                    />
                    {card.label && (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: card.label.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></Text>
                    )}

                    {card.buttonText && (
                      <Button mb="3" size="sm" colorScheme="green" bg="#4da60b">
                        {card.buttonText}
                      </Button>
                    )}
                  </Stack>
                </Link>
              </SplideSlide>
            ))}
        </Splide>
      </Box>
    </Stack>
  );
}

export default GridCardCaurosel;
