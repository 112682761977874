import { HStack, IconButton, Input, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useLinks } from "../../../../../utils/contexts/useLinks";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import AsyncCreatableSelect from "react-select/async-creatable";

function GrandChildMenu({
  columnIndex,
  rowIndex,
  templateIndex,
  child,
  childId,
  grandchild,
  menuIndex,
  data,
}) {
  const dispatch = useDispatch();

  const { linksLoading, options, loadLinks } = useLinks();

  const removeGrandChild = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let Idata = {
      ...rows[rowIndex].columns[columnIndex][templateIndex].data,
    };
    let children = [...Idata.children];
    let grandchildren = [...children[menuIndex].children];
    grandchildren.splice(childId, 1);
    children[menuIndex] = {
      ...children[menuIndex],
      children: grandchildren,
    };

    Idata = { ...Idata, children: children };
    //console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: Idata,
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };
  return (
    <>
      <Stack p="2" shadow="sm" bg="gray.50" borderRadius={3}>
        <Stack>
          <HStack justify="space-between">
            <Text fontSize={"xs"}>Label</Text>
            <IconButton
              icon={<BiTrash />}
              onClick={removeGrandChild}
              size="xs"
            />
          </HStack>

          <Input
            size="sm"
            placeholder="Label"
            bg="white"
            borderRadius={4}
            value={grandchild && grandchild.label}
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];
              let Idata = {
                ...rows[rowIndex].columns[columnIndex][templateIndex].data,
              };
              let children = [...Idata.children];

              let grandchildren = [...children[menuIndex].children];
              grandchildren[childId] = {
                ...grandchildren[childId],
                label: e.target.value,
              };

              console.log(grandchildren);

              children[menuIndex] = {
                ...children[menuIndex],
                children: grandchildren,
              };

              Idata = { ...Idata, children: children };
              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: Idata,
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          />
        </Stack>
        <Stack>
          <Text fontSize={"xs"}>Url</Text>
          <AsyncCreatableSelect
            isClearable
            value={grandchild && grandchild.url}
            isLoading={linksLoading}
            loadOptions={loadLinks}
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];
              let Idata = {
                ...rows[rowIndex].columns[columnIndex][templateIndex].data,
              };
              let children = [...Idata.children];

              let grandchildren = [...children[menuIndex].children];
              grandchildren[childId] = {
                ...grandchildren[childId],
                url: e,
              };

              console.log(grandchildren);

              children[menuIndex] = {
                ...children[menuIndex],
                children: grandchildren,
              };

              Idata = { ...Idata, children: children };
              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: Idata,
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default GrandChildMenu;
