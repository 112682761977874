import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import poolSlice from "./poolSlice";
import replySlice from "./replySlice";
import pageDataSlice from "./pageDataSlice";
import configSlice from "./configSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    pool: poolSlice,
    reply: replySlice,
    pagedata: pageDataSlice,
    config: configSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
