import { Button, IconButton } from "@chakra-ui/button";
import { useColorModeValue, useColorMode } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import Icon from "@chakra-ui/icon";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Input, InputGroup } from "@chakra-ui/input";
import {
  Center,
  Container,
  Grid,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/layout";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Editor } from "@tinymce/tinymce-react";
import axiosInstance from "../../../../utils/axios";
import { useToast } from "@chakra-ui/toast";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDisclosure } from "@chakra-ui/hooks";

function NewProduct() {
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState({
    title: "",
    images: images,
    type: "manual",
    selling_price: 10,
    vendor: "Dailysale",
  });
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  // var myWidget = window.cloudinary.createUploadWidget(
  //   {
  //     cloudName: "huvuru",
  //     uploadPreset: "ponhwf4e",
  //   },
  //   (error, result) => {
  //     if (!error && result && result.event === "success") {
  //       console.log(result);

  //       setImages([...images, result.info]);
  //       //console.log("Done! Here is the image info: ", result.info);
  //     }
  //   }
  // );

  // const showWidget = () => {
  //   myWidget.open();
  // };

  useEffect(() => {
    setProduct({ ...product, images: images });
  }, [images]);

  // const setImage = (image) => {
  //   setCollection({ ...collection, image });
  // };

  const saveProduct = async (force) => {
    setLoading(true);
    let submitedProduct = { ...product };
    if (editorRef.current) {
      submitedProduct.description = editorRef.current.getContent();
    }
    submitedProduct.force = force;
    console.log(submitedProduct);
    axiosInstance
      .post("/products/new", submitedProduct)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        console.log(data);
        if (data?.error) {
          toast({
            status: "error",
            title: "Error",
            description: data.message,
            position: "top",
          });
        } else if (data?.warning) {
          onOpen();
        } else {
          toast({
            status: "success",
            title: "Success",
            description: "Product created successfully",
            position: "top",
          });
          setTimeout(function () {
            navigate("/app/shop/products/" + data.id);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, contact dev support",
          position: "top",
        });
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p="5" spacing="20px">
          <HStack justify="space-between">
            <HStack spacing="20px">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                color={useColorModeValue("gray.700", "gray.50")}
                icon={<ChevronLeftIcon />}
              />
              <Heading
                size="md"
                color={useColorModeValue("gray.700", "gray.50")}
              >
                Create Product
              </Heading>
            </HStack>
            <Button
              onClick={() => {
                saveProduct(false);
              }}
              isLoading={loading}
              colorScheme="green"
              disabled={!product?.title || !product?.selling_price || loading}
              size="sm"
            >
              Save
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
              <ModalOverlay />
              <ModalContent
                bg={useColorModeValue("gray.100", "gray.800")}
                color={useColorModeValue("gray.700", "gray.50")}
              >
                <ModalHeader bg={useColorModeValue("gray.50", "gray.900")}>
                  Duplicate Product(s) found
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    A potential duplicate product was found. You can choose to
                    change the title, or create duplicate
                  </Text>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" size="sm" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      onClose();
                      saveProduct(true);
                    }}
                  >
                    Create Copy
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </HStack>
          <Grid
            templateColumns={{ base: "100%", md: "8fr 4fr" }}
            gap={"30px"}
            color={useColorModeValue("gray.700", "gray.50")}
          >
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    value={product?.title}
                    onChange={(e) => {
                      setProduct({ ...product, title: e.target.value });
                    }}
                    placeholder="Macbook air, under $100, Staff Picks"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description (optional)</FormLabel>
                  <Editor
                    tinymceScriptSrc="https://cdn.tiny.cloud/1/sze44wj07kdbdi9w54xvqbn53xi81mgock8dvdjgdolu31x5/tinymce/5/tinymce.min.js"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue=""
                    init={{
                      branding: false,
                      height: 200,
                      menubar: false,
                      placeholder: "Description...",
                      skin: colorMode === "light" ? "oxide" : "oxide-dark",
                      content_css: colorMode === "light" ? "light" : "dark",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      formats: {
                        removeformat: [
                          {
                            selector: "b,strong,em,i,font,u,strike,code",
                            remove: "all",
                            split: true,
                            expand: false,
                            block_expand: true,
                            deep: true,
                          },
                          {
                            selector: "span",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            deep: true,
                          },
                          {
                            selector: "code",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            deep: true,
                          },
                          {
                            selector: "*",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            block_expand: true,
                            deep: true,
                          },
                        ],
                      },
                    }}
                  />
                </FormControl>
              </Stack>
              {/* <Stack
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                p="5"
              >
                <FormControl color={useColorModeValue("gray.700", "gray.700")}>
                  <FormLabel color={useColorModeValue("gray.700", "gray.50")}>
                    Product Images
                  </FormLabel>
                  <Grid
                    templateColumns={{ base: "100%", md: "1fr 1fr" }}
                    gap="10px"
                  >
                    {images.map((img, index) => (
                      <ProductImage
                        image={img}
                        key={index}
                        position={index}
                        images={images}
                        setImages={setImages}
                      />
                    ))}

                    {images.length < 4 && (
                      <Center
                        bg="gray.200"
                        cursor="pointer"
                        minH="110px"
                        onClick={() => {
                          //setImages([...images, undefined]);
                          showWidget();
                        }}
                      >
                        <Text>+</Text>
                      </Center>
                    )}
                  </Grid>
                </FormControl>
              </Stack> */}
            </Stack>
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Selling Price</FormLabel>
                  <Input
                    value={product?.selling_price}
                    type="number"
                    onChange={(e) => {
                      setProduct({ ...product, selling_price: e.target.value });
                    }}
                    placeholder="10,20,30"
                  />
                </FormControl>
              </Stack>

              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Vendor/Brand</FormLabel>
                  <Input
                    value={product?.vendor}
                    type="text"
                    onChange={(e) => {
                      setProduct({ ...product, vendor: e.target.value });
                    }}
                    placeholder="Apple, Samsung"
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Container>
    </Center>
  );
}

export default NewProduct;
