import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import {
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  Center,
} from "@chakra-ui/layout";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import React, { useState } from "react";
import { FcFlashOn } from "react-icons/fc";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useAuth } from "../../utils/useAuth";
import { useLocation } from "react-router";
import { useToast } from "@chakra-ui/toast";
import fetchErrorMessage from "./fetchErrorMessage";

function LoginLogic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  const toast = useToast();

  const attemptLogin = async () => {
    setLoading(true);
    try {
      await login(email, password)
        .then((res) => {
          console.log(res);
          //history.push(redirect);
          navigate(search.replace("?redirect=", ""));
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.code);
          toast({
            description: fetchErrorMessage(err.code),
            position: "top-left",
            status: "error",
          });
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast({
        description: "Connection error, please try again later",
        position: "top-left",
        status: "error",
      });
    }
  };

  return (
    <Container maxW="500px" h="100%" py="10">
      <Center h="100%" justify="center">
        <Stack spacing="30px">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} w="130px" />
          <Stack>
            <HStack>
              <Heading
                size="lg"
                color={useColorModeValue("gray.500", "gray.50")}
              >
                Login to Your Account!
              </Heading>
              <FcFlashOn size="40px" />
            </HStack>
            <Text color="gray.500">
              You are about to enter a highly positive space
            </Text>

            <Stack spacing="35px" pt="40px">
              <FormControl>
                <FormLabel color="gray.500">Email</FormLabel>
                <Input
                  placeholder="Email"
                  type="email"
                  value={email}
                  color={useColorModeValue("gray.700", "gray.50")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel color="gray.500">Password</FormLabel>
                <Input
                  placeholder="Password"
                  type="password"
                  value={password}
                  color={useColorModeValue("gray.700", "gray.50")}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormControl>

              <Button
                onClick={attemptLogin}
                isLoading={loading}
                colorScheme="blue"
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Center>
    </Container>
  );
}

export default LoginLogic;
