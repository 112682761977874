import React, { useState, useEffect, useContext, createContext } from "react";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { setPool } from "../redux/poolSlice";
//initialize firebase
let socket;
const SocketContext = createContext();

// Hook for child components to get auth ojbect....
// ... and re-render when it changes
export const useSocket = () => {
  return useContext(SocketContext);
};

//Provider hook that creates auth object and haldes state
export const SocketProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {

  //   // socket.emit("joinChat", "263771564110", { name: "Ian" }, (response) => {
  //   //   console.log(response.status); // ok
  //   // });
  //   // socket.on("chat", (data) => {
  //   //   console.log(data);
  //   // });

  //   return () => {
  //     setConnected(false);
  //     socket.disconnect();
  //     socket.off();
  //   };
  // }, []);

  const connect = () => {
    socket = io("http://localhost:5000", { path: "/chat" });
    console.log(socket);
    socket.on("connect", () => {
      console.log("connected");
      setConnected(true);
    });
    socket.on("pool", (data) => {
      dispatch(
        setPool({
          unread: data?.data?.unread ? parseInt(data.data.unread) : 0,
          activeChats: data?.data?.activeChats ? data?.data?.activeChats : [],
          incomingChats: data?.data?.incomingChats
            ? data?.data?.incomingChats
            : [],
        })
      );
    });
  };

  const disconnect = () => {
    setConnected(false);
    socket.disconnect();
    socket.off();
  };

  //check if current user is administrator

  const values = {
    socket,
    connected,
    connect,
    disconnect,
  };

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
};
