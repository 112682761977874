import { useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl } from "@chakra-ui/form-control";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useRef } from "react";

function SearchCollectionProducts({ search, setSearch, modal }) {
  const ref = useRef();
  useEffect(() => {
    //
    console.log("Test");
    // console.log(search);
    // if (ref.current) {
    //   ref.current.value = search;
    // }
    // // ;
    // console.log(ref);
  }, [ref]);

  return (
    <>
      <Modal
        size={"xl"}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        maxW="1100px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottomColor={useColorModeValue("gray.500", "gray.50")}
          >
            Edit Products
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                placeholder="Search"
                ref={ref}
                onChange={(e) => setSearch(e.target.value)}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SearchCollectionProducts;
