import React, { Component } from "react";

class CloudinaryUploadWidget extends Component {
  componentDidMount() {}

  render() {
    return <></>;
  }
}

export default CloudinaryUploadWidget;
