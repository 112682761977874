import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Grid,
  Heading,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BiFullscreen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/axios";
import { setConfig } from "../../../utils/redux/configSlice";
import FooterMenuItem from "./FooterMenuItem";
import MainNavItem from "./MainNavItem";
import StickyFooterMenu from "./StickyFooterMenu";
import StickyTopMenu from "./StickyTopMenu";
import StickyTopMenuItem from "./StickyTopMenuItem";

function NavigationEntry() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config.value.config);

  console.log(config);

  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/app/config/")
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        dispatch(setConfig(data));
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const addMainMenuItem = () => {
    let nav = [
      ...config.nav,
      {
        title: "Default Title",
        image: "https://via.placeholder.com/50",
        children: [],
      },
    ];

    dispatch(setConfig({ ...config, nav: nav }));
  };

  const addStickyMenuItem = () => {
    let nav = [
      ...config.stickyfooter,
      {
        title: "Default Title",
        image: "https://via.placeholder.com/50",
        url: "#",
      },
    ];

    dispatch(setConfig({ ...config, stickyfooter: nav }));
  };

  const addStickyTopMenuItem = () => {
    let nav = [
      ...config.stickytop,
      {
        title: "Default Title",
        image: "https://via.placeholder.com/50",
        url: "#",
      },
    ];

    dispatch(setConfig({ ...config, stickytop: nav }));
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = config.nav;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, config.nav[source.index]);

    dispatch(setConfig({ ...config, nav: newArray }));

    return;
  };

  const addNewFooter = () => {
    const footer = [...config.footer, { title: "Default Title", url: "#" }];
    dispatch(setConfig({ ...config, footer: footer }));
  };

  const onDragEndFooter = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = config.stickyfooter;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, config.stickyfooter[source.index]);

    dispatch(setConfig({ ...config, stickyfooter: newArray }));

    return;
  };

  const onDragEndMobileSticky = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = config.stickyfooter;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, config.stickyfooter[source.index]);

    dispatch(setConfig({ ...config, stickyfooter: newArray }));

    return;
  };

  const onDragEndMobileTopSticky = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = config.stickytop;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, config.stickytop[source.index]);

    dispatch(setConfig({ ...config, stickytop: newArray }));

    return;
  };

  const saveMenu = () => {
    setLoading(true);
    axiosInstance
      .put("/admin/config", config)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          toast({
            description: "Menu update failed",
            status: "error",
          });
        } else {
          toast({
            description: "Menu updated successfully",
            status: "success",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          description: "Menu update failed",
          status: "error",
        });
        console.log(err);
      });
  };

  return (
    <Grid
      templateColumns={{ base: "100%", md: "1fr 1fr" }}
      gap="20px"
      color={useColorModeValue("gray.800", "gray.50")}
    >
      <Stack>
        <HStack align="center">
          <Text size="sm">Main Menu</Text>
        </HStack>

        <Skeleton isLoaded={!loading}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack shadow="md" px="3" py="5" spacing="5px">
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    <>
                      {config &&
                        config.nav &&
                        config.nav.map((nav, index) => (
                          <Draggable
                            key={"row" + index}
                            draggableId={"row" + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <MainNavItem
                                key={index}
                                position={index}
                                config={config}
                                provided={provided}
                              />
                            )}
                          </Draggable>
                        ))}
                    </>
                    <>{provided.placeholder}</>
                  </Stack>
                )}
              </Droppable>

              <HStack
                justify="space-between"
                cursor="pointer"
                p="2"
                py="3"
                shadow="sm"
                onClick={addMainMenuItem}
                borderRadius={"md"}
                bg={useColorModeValue("blue.50", "blue.700")}
                _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
                color={useColorModeValue("blue.300", "blue.100")}
              >
                <HStack flexGrow="1">
                  <BiFullscreen />
                  <Heading onClick={addMainMenuItem} size="xs">
                    Add New Nav Item
                  </Heading>
                </HStack>
              </HStack>
            </Stack>
          </DragDropContext>
        </Skeleton>

        <Button size="sm" isLoading={loading} onClick={saveMenu}>
          Save
        </Button>
      </Stack>
      <Stack>
        <HStack align="center">
          <Text size="sm">Footer Menu</Text>
        </HStack>
        <Skeleton isLoaded={!loading}>
          <DragDropContext onDragEnd={onDragEndFooter}>
            <Stack shadow="md" px="3" py="5" spacing="5px">
              <Droppable droppableId={"footer"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    <>
                      {config &&
                        config.footer &&
                        config.footer.map((nav, index) => (
                          <Draggable
                            key={"row" + index}
                            draggableId={"row" + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <FooterMenuItem
                                key={index}
                                position={index}
                                config={config}
                                provided={provided}
                              />
                            )}
                          </Draggable>
                        ))}
                    </>
                    <>{provided.placeholder}</>
                  </Stack>
                )}
              </Droppable>
              <HStack
                justify="space-between"
                cursor="pointer"
                p="2"
                py="3"
                shadow="sm"
                onClick={addNewFooter}
                borderRadius={"md"}
                bg={useColorModeValue("blue.50", "blue.700")}
                _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
                color={useColorModeValue("blue.300", "blue.100")}
              >
                <HStack flexGrow="1">
                  <BiFullscreen />
                  <Heading size="xs">Add New Footer Item</Heading>
                </HStack>
              </HStack>
              <Button size="sm" isLoading={loading} onClick={saveMenu}>
                Save
              </Button>
            </Stack>
          </DragDropContext>
        </Skeleton>
      </Stack>
      <Stack>
        <HStack align="center">
          <Text size="sm">Mobile Sticky Menu</Text>
        </HStack>
        <Skeleton isLoaded={!loading}>
          <DragDropContext onDragEnd={onDragEndMobileSticky}>
            <Stack shadow="md" px="3" py="5" spacing="5px">
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    <>
                      {config &&
                        config.stickyfooter &&
                        config.stickyfooter.map((nav, index) => (
                          <Draggable
                            key={"row" + index}
                            draggableId={"row" + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <StickyFooterMenu
                                key={index}
                                position={index}
                                config={config}
                                provided={provided}
                              />
                            )}
                          </Draggable>
                        ))}
                    </>
                    <>{provided.placeholder}</>
                  </Stack>
                )}
              </Droppable>

              <HStack
                justify="space-between"
                cursor="pointer"
                p="2"
                py="3"
                shadow="sm"
                onClick={addStickyMenuItem}
                borderRadius={"md"}
                bg={useColorModeValue("blue.50", "blue.700")}
                _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
                color={useColorModeValue("blue.300", "blue.100")}
              >
                <HStack flexGrow="1">
                  <BiFullscreen />
                  <Heading onClick={addStickyMenuItem} size="xs">
                    Add New Nav Item
                  </Heading>
                </HStack>
              </HStack>
            </Stack>
          </DragDropContext>
        </Skeleton>
        <Button size="sm" isLoading={loading} onClick={saveMenu}>
          Save
        </Button>
      </Stack>
      <Stack>
        <HStack align="center">
          <Text size="sm">Mobile Top Sticky Menu</Text>
        </HStack>
        <Skeleton isLoaded={!loading}>
          <DragDropContext onDragEnd={onDragEndMobileTopSticky}>
            <Stack shadow="md" px="3" py="5" spacing="5px">
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    <>
                      {config &&
                        config.stickytop &&
                        config.stickytop.map((nav, index) => (
                          <Draggable
                            key={"row" + index}
                            draggableId={"row" + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <StickyTopMenu
                                key={index}
                                position={index}
                                config={config}
                                provided={provided}
                              />
                            )}
                          </Draggable>
                        ))}
                    </>
                    <>{provided.placeholder}</>
                  </Stack>
                )}
              </Droppable>

              <HStack
                justify="space-between"
                cursor="pointer"
                p="2"
                py="3"
                shadow="sm"
                onClick={addStickyTopMenuItem}
                borderRadius={"md"}
                bg={useColorModeValue("blue.50", "blue.700")}
                _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
                color={useColorModeValue("blue.300", "blue.100")}
              >
                <HStack flexGrow="1">
                  <BiFullscreen />
                  <Heading onClick={addStickyTopMenuItem} size="xs">
                    Add New Nav Item
                  </Heading>
                </HStack>
              </HStack>
            </Stack>
          </DragDropContext>
        </Skeleton>
        <Button size="sm" isLoading={loading} onClick={saveMenu}>
          Save
        </Button>
      </Stack>
    </Grid>
  );
}

export default NavigationEntry;
