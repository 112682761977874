import { useSelector } from "react-redux";
import { Route, Navigate, useLocation } from "react-router-dom";

export const ProtectedRoute = (component, path, index, rest) => {
  const user = useSelector((state) => state.user.value);
  console.log("We are here");
  const { pathname } = useLocation();
  return (
    <Route
      key={index}
      path={path}
      {...rest}
      showborder=""
      element={
        user ? (
          component
        ) : (
          <Navigate to={`/login?redirect=${pathname}`} replace={true} />
        )
      }
    />
  );
};
