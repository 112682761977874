import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiCaretDown, BiCaretRight, BiTrash } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setPageData } from "../../../../utils/redux/pageDataSlice";
import CardGridColumn from "./CardGrid/CardGridColumn";

import CauroselGridCardColumn from "./CauroselGridCards/CauroselGridCardColumn";
import CollectionCauroselColumn from "./CollectionCaurosel/CollectionCauroselColumn";
import CollectionGridColumn from "./CollectionGrid/CollectionGridColumn";
import DetailedCardColumn from "./DetailedCard/DetailedCardColumn";
import ExploreSection from "./ExploreSection/ExploreSection";
import GridCardCauroselColumn from "./GridCardCaurosel/GridCardCauroselColumn";
import IconCardsColumn from "./IconCards/IconCardsColumn";
import ImageAdvertEditor from "./ImageAdvert/ImageAdvertEditor";
import ImageGridCardColumn from "./ImageGridCards/ImageGridCardColumn";
import EditSlider from "./ImageSlider/EditSlider";
import InfoCardsColumn from "./InfoCards/InfoCardsColumn";
import EditLogo from "./LogoSection/EditLogo";
import ManageSections from "./manage/ManageSections";
import PageMenuColumn from "./PageMenu/PageMenuColumn";
import SimpleCardsColumn from "./SimpleCards/SimpleCardsColumn";
import TextSection from "./TextSection/TextSection";
import TrackCardEdit from "./TrackCardEdit";
import TrackOrderEditor from "./TrackOrderEditor";

function PageColumn({ provided, columnIndex, rowIndex }) {
  const { isOpen, onToggle } = useDisclosure();

  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();

  const addSection = (section) => {
    console.log(section);

    let template = undefined;
    //switch section
    switch (section) {
      case "explore-section":
        template = {
          type: "ExploreSection",
          title: "",
          url: "",
          data: {
            layout: {
              base: "2",
              md: "3",
              lg: "4",
              xl: "4",
            },
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
            cards: [],
          },
        };
        break;
      case "page-menu":
        template = {
          type: "PageMenu",
          title: "",
          url: "",
          data: {
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
            children: [],
          },
        };
        break;
      case "slider":
        template = { type: "ImageCaurosel", data: [] };
        break;
      case "logo-section":
        template = { type: "LogoSection", data: [] };
        break;
      case "grid-cards":
        template = {
          type: "GridCards",
          cards: [],
          data: {
            layout: {
              base: "2",
              md: "3",
              lg: "4",
              xl: "4",
            },
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
          },
        };
        break;
      case "collection-caurosel":
        template = {
          type: "CollectionCaurosel",
          data: {
            id: "",
            layout: {
              base: "2",
              md: "4",
              lg: "4",
              xl: "4",
            },
          },
        };
        break;
      case "collection-grid":
        template = {
          type: "CollectionGrid",
          data: {
            id: "",
          },
        };
        break;
      case "image-card":
        template = {
          type: "ImageAdvert",
          data: {
            imageUrl: "https://via.placeholder.com/300",
            alt: "Alt",
            url: "#",
          },
        };
        break;
      case "image-grid-cards":
        template = {
          type: "ImageGridCard",
          data: {
            layout: {
              base: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr",
              xl: "1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "caurosel-grid-cards":
        template = {
          type: "GridCardCaurosel",
          title: "",
          data: {
            layout: {
              base: "2",
              md: "3",
              lg: "4",
              xl: "4",
            },
            cards: [],
          },
        };
        break;
      case "caurosel-grid-images":
        template = {
          type: "CauroselGridImages",
          title: "",
          data: {
            layout: {
              base: "2",
              md: "3",
              lg: "4",
              xl: "4",
            },
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
            cards: [],
          },
        };
        break;
      case "text-section":
        template = {
          type: "TextSection",
          title: "",
          content: "",
          data: {
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
          },
        };
        break;
      case "simple-cards":
        template = {
          type: "SimpleCard",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "detailed-cards":
        template = {
          type: "DetailedCard",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "info-cards":
        template = {
          type: "InfoCards",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr 1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "icon-cards":
        template = {
          type: "IconCards",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr 1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            visibility: {
              base: "visible",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
            cards: [],
          },
        };
        break;
      case "track":
        template = { type: "TrackButton" };
        break;
      default:
        break;
    }

    if (!template) {
      return;
    }

    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...columns[columnIndex]];
    templates.push(template);
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const removeColumn = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    columns.splice(columnIndex, 1);

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      p="0"
      spacing="0px"
    >
      <HStack
        justify="space-between"
        onClick={onToggle}
        cursor="pointer"
        p="2"
        shadow="sm"
        borderRadius={"md"}
        borderBottomRadius={isOpen ? "0px" : "md"}
      >
        <HStack flexGrow="1">
          {isOpen ? <BiCaretDown /> : <BiCaretRight />}
          <Heading size="xs">Column</Heading>
        </HStack>
        <IconButton
          children={<BiTrash />}
          onClick={removeColumn}
          size="xs"
          colorScheme="red"
        />
        <div {...provided.dragHandleProps}>
          <MdDragIndicator />
        </div>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          p="2"
          py="5"
          color={useColorModeValue("gray.800", "gray.50")}
          bg={useColorModeValue("gray.100", "gray.800")}
          rounded="md"
          borderTopRadius={"0px"}
          borderTopColor={"red.300"}
          borderTopWidth={"1px"}
          spacing="20px"
        >
          {data &&
            data.data &&
            data.data.widgets[rowIndex].columns[columnIndex].map(
              (template, index) => (
                <Box
                  key={index}
                  shadow="md"
                  bg="white"
                  _dark={{ bg: "gray.900" }}
                  // bg={useColorModeValue("white")}
                >
                  {template &&
                    (() => {
                      switch (template.type) {
                        case "ImageCaurosel":
                          return (
                            <EditSlider
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "CollectionCaurosel":
                          return (
                            <CollectionCauroselColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "CollectionGrid":
                          return (
                            <CollectionGridColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "SimpleCard":
                          return (
                            <SimpleCardsColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "DetailedCard":
                          return (
                            <DetailedCardColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "TrackButton":
                          return (
                            <TrackOrderEditor
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "ImageAdvert":
                          return (
                            <ImageAdvertEditor
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "ImageGridCard":
                          return (
                            <ImageGridCardColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "GridCardCaurosel":
                          return (
                            <GridCardCauroselColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "CauroselGridImages":
                          return (
                            <CauroselGridCardColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "InfoCards":
                          return (
                            <InfoCardsColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "IconCards":
                          return (
                            <IconCardsColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "GridCards":
                          return (
                            <CardGridColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "TextSection":
                          return (
                            <TextSection
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "ExploreSection":
                          return (
                            <ExploreSection
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "LogoSection":
                          return (
                            <EditLogo
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "PageMenu":
                          return (
                            <PageMenuColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        default:
                          return "#FFFFFF";
                      }
                    })()}
                </Box>
              )
            )}

          <ManageSections addSection={addSection} />
        </Stack>
      </Collapse>
    </Stack>
  );
}

export default PageColumn;
