import { Avatar, AvatarGroup } from "@chakra-ui/avatar";
import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
// import { Input } from "@chakra-ui/input";
import {
  Box,
  Center,
  Circle,
  Flex,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
// import { Textarea } from "@chakra-ui/textarea";
import React, { createRef, useEffect, useState, useRef } from "react";
import {
  BsCloudDownload,
  BsPlayFill,
  BsEmojiSmile,
  BsPaperclip,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { MdSend } from "react-icons/md";
// import { FaMapMarkerAlt } from "react-icons/fa";
import TextareaAutosize from "react-textarea-autosize";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import Player from "react-howler-player";
import { Collapse } from "@chakra-ui/transition";
import { Image } from "@chakra-ui/image";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ChatTopInfo from "./ChatTopInfo";
import axiosInstance from "../../../utils/axios";
import { Spinner } from "@chakra-ui/spinner";
import SingleChat from "./SingleChat";
import SendBox from "../sendbox/SendBox";
import { useSocket } from "../../../utils/sockets/useSocket";
import { useAuth } from "../../../utils/useAuth";
import ChatTopHalf from "../utils/ChatTopHalf";
import ChatBottomHalf from "../utils/ChatBottomHalf";
// import VideoImageThumbnail from "react-video-thumbnail-image";

function ChatContainer() {
  const messagesEndRef = useRef();
  const messagesTopRef = useRef();
  const pool = useSelector((state) => state.pool.value);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [joined, setJoined] = useState(false);
  const { phone } = useParams();
  const [hasPrev, setHasPrev] = useState(false);
  const [cursor, setCursor] = useState(null);

  const [tags, setTags] = useState([""]);

  // const { socket } = useSocket();
  const user = useSelector((state) => state.user.value);
  //const dispatch = useDispatch();

  //const messageEl = useRef(null);
  // const scrollToBottom = useCallback(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messagesEndRef]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.emit(
  //       "joinChat",
  //       phone.replace("@c.us", ""),
  //       { name: user.displayName || user.email },
  //       (response) => {
  //         console.log(response.status); // ok
  //       }
  //     );
  //   }

  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  //   return () => {
  //     socket.emit("leave", phone.replace("@c.us", ""));
  //   };
  // }, []);

  // useEffect(() => {
  //   socket.on("chat", (msg) => {
  //     console.log(msg);
  //     let msgs = [...messages, msg];

  //     console.log(msgs);
  //     console.log(messages);
  //     setMessages([...messages, msg]);
  //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //   });
  // }, []);

  const subscribeToChat = () => {
    // socket.emit("join", { client: phone, agent: "Ian" }, (data) => {
    //   console.log("joined");
    //   setJoined(true);
    //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // });
  };

  // useEffect(() => {
  //   if (messageEl) {
  //     messageEl.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // }, []);

  const handleScroll = (e) => {
    // if (!hasPrev) return;
    if (e.target.scrollTop === 0 && hasPrev && cursor) {
      setLoading(true);
      axiosInstance
        .get("/api/chats/" + phone + "/" + cursor)
        .then((res) => res.data)
        .then((data) => {
          if (data?.messages) {
            setMessages([...data.messages, ...messages]);
            //messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            setLoading(false);
            setHasPrev(data.hasPrev);
            //subscribeToChat();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      //loard more messages
    }
  };

  const readMessage = (message) => {
    console.log(message);
    axiosInstance.get("/api/read/" + message.id);
    // setMessages([...messages, message]);
  };
  const addMessage = (msg) =>
    setMessages((prevMessages) => [...prevMessages, msg]);
  // useEffect(() => {
  //   setLoading(true);
  //   if (!joined) {
  //   }
  //   axiosInstance
  //     .get("/api/chats/" + phone)
  //     .then((res) => res.data)
  //     .then((data) => {
  //       if (data?.messages) {
  //         setMessages(data.messages);
  //         messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //         setLoading(false);
  //         setHasPrev(data.hasPrev);
  //         setCursor(data.cursor);
  //         readMessage(data.messages[data.messages.length - 1]);
  //         //subscribeToChat();
  //       }
  //       socket.emit(
  //         "joinChat",
  //         phone.replace("@c.us", ""),
  //         user.email,
  //         (data) => {
  //           setJoined(true);
  //           messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //         }
  //       );

  //       socket.on("chat", (message) => {
  //         console.log("chat", message);
  //         addMessage(message);
  //         messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //         readMessage(message);
  //       });

  //       socket.on("update", (message) => {
  //         console.log("update", message);
  //         // let oldMessages = [...messages];
  //         // //filter through oldMessages and update the message where the id matches
  //         // let newMessages = oldMessages.map((m) => {
  //         //   if (m.api_message_id === message.id) {
  //         //     m.status = message.status;
  //         //     return m;
  //         //   }
  //         //   return m;
  //         // });

  //         // setMessages(newMessages);

  //         // addMessage(message);
  //         // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //         // readMessage(message);
  //       });

  //       // socket.on("chat", (message) => {
  //       //   concattinatemessages(message);
  //       //   console.log("chat", message);
  //       //   console.log(messages);
  //       //   console.log([message, ...messages]);
  //       // });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });

  //   return () => {
  //     if (joined) {
  //       socket.emit(
  //         "leave",
  //         {
  //           client: phone.replace("@c.us", ""),
  //           agent: user.displayName || user.email,
  //         },
  //         (data) => {
  //           console.log("left chat");
  //         }
  //       );
  //     }
  //   };
  // }, [phone]);

  return (
    <Grid h="100vh" w="100%" templateColumns={"8fr 3fr"}>
      <Stack
        bg={useColorModeValue("#e5ddd5", "transaparent")}
        h="100%"
        w="100%"
        overflowY="scroll"
        overflowX="hidden"
        // maxW="1000px"
        onScroll={handleScroll}
      >
        <Stack p="0" pb={5} flexGrow="1" spacing="20px" position="relative">
          <ChatTopInfo phone={phone} />

          <Center display={loading ? "flex" : "none"}>
            <Circle bg={useColorModeValue("gray.100", "gray.700")} p="1">
              <Spinner color={useColorModeValue("red.500", "red.300")} />
            </Circle>
          </Center>
          {hasPrev && <Box w="100%" ref={messagesTopRef}></Box>}

          <Box
            style={{ marginTop: "0 !important" }}
            backgroundImage={`url(${
              process.env.PUBLIC_URL
            }/chatbg-${useColorModeValue("light", "dark")}.png)`}
          ></Box>
          {messages.map((message, index) => (
            <SingleChat key={index} chat={message} />
          ))}
          <Box ref={messagesEndRef}></Box>
        </Stack>
        <SendBox phone={phone} addMessage={addMessage} />
      </Stack>
      <Stack
        bg={useColorModeValue("gray.100", "gray.800")}
        h="100%"
        w="100%"
        overflowY="scroll"
        overflowX="hidden"
        spacing="40px"
      >
        <ChatTopHalf tags={tags} setTags={setTags} />
        <ChatBottomHalf tags={tags} setTags={setTags} />
      </Stack>
    </Grid>
  );
}

export default ChatContainer;
