import { Stack, HStack, Heading, Link, Text, Button } from "@chakra-ui/react";
import React from "react";
import ProcurementGrid from "./ProcurementGrid";
import ProcurementTable from "./ProcurementTable";

function ProcurementList() {
  return (
    <Stack
      w="100%"
      h="100%"
      overflowY="scroll"
      p="5"
      spacing="30px"
      position="relative"
      //   maxW="1200px"
      mx="auto"
    >
      <HStack w="100%" justify="space-between">
        <Heading size="md">Orders - Procurement</Heading>
        <HStack>
          <Button colorScheme="green" size="sm">
            Export
          </Button>
        </HStack>
      </HStack>
      <ProcurementGrid />
      <ProcurementTable />
    </Stack>
  );
}

export default ProcurementList;
