import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../utils/redux/userSlice";
import Loading from "../components/Loading";

//initialize firebase
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const AuthContext = createContext();

// Hook for child components to get auth ojbect....
// ... and re-render when it changes
export const useAuth = () => {
  return useContext(AuthContext);
};

//Provider hook that creates auth object and haldes state
export const AuthProvider = ({ children }) => {
  ////user user user
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const dispatch = useDispatch();
  //register
  const register = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  //login with google
  const googleLogin = () => {
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  // login with email and password
  const login = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  // reset password
  function reset(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  function getCurrentUser() {
    return firebase.auth().currentUser;
  }

  //send verification email
  const sendVerificationEmail = (user) => {};

  //logout client
  const logout = async () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        //setuser to null redux
      });
  };

  //check if current user is administrator

  //Subscribe the user on mounts
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      //set user to redux store
      dispatch(setUser(user));
      setIsAuthenticating(false);
    });

    //cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  const values = {
    isAuthenticating,
    register,
    googleLogin,
    login,
    reset,
    sendVerificationEmail,
    logout,
    getCurrentUser,
  };

  return (
    <AuthContext.Provider value={values}>
      {isAuthenticating ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
