import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { AddIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useState } from "react";
import axiosInstance from "../../../../utils/axios";

function NewTakealot({ links, setLinks, collection }) {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const addTakealot = () => {
    setLoading(true);
    let url;
    try {
      url = new URL(link);
    } catch (e) {
      toast({
        title: "Invalid URL",
        status: "error",
      });
      setLink("");
      setLoading(false);
      return;
    }

    console.log(url);
    axiosInstance
      .post(`/collections/${collection.id}/takealot`, {
        link: link,
        host: url.host,
        pathname: url.pathname,
        search: url.search,
        collection: collection.id
      })
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        setLoading(false);
        if (res?.id) {
          setLink("");
        
          setLinks([...links, res]);
        } else {
          toast({
            status: "error",
            title: "Title",
            description: res.error || "Invalid link",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast({
          status: "error",
          title: "Title",
          description: "Error uploading link",
        });
      });
  };
  return (
    <HStack>
      <InputGroup
        isAttached
        size="md"
        color={useColorModeValue("gray.700", "gray.50")}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Input
          pr="4.5rem"
          value={link}
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          borderRightColor="transparent"
          onChange={(e) => {
            setLink(e.target.value);
          }}
          placeholder="New takealot link"
        />
        
       
        <InputRightElement width="3.1rem">
          <IconButton
            h="1.75rem"
            size="sm"
            disabled={!link}
            onClick={addTakealot}
            icon={<AddIcon />}
          />
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
}

export default NewTakealot;
