import {
  Text,
  Stack,
  Container,
  HStack,
  Box,
  Heading,
  Grid,
  Badge,
  Wrap,
  Link,
} from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import React from "react";
import useQuote from "./useQuote";
import useDailyDeals from "./useDailyDeals";
import { FcAbout } from "react-icons/fc";
import { SiMediafire } from "react-icons/si";
import { Image } from "@chakra-ui/image";
import { useColorModeValue } from "@chakra-ui/color-mode";

function LoginProducts() {
  const quote = useQuote();
  const deals = useDailyDeals();

  return (
    <Stack
      h={{ base: "auto", md: "100vh" }}
      bg={useColorModeValue("blue.50", "blue.800")}
      align="center"
      pt={{ base: 10, md: 20 }}
      pb={3}
      spacing="40px"
      px={3}
      py={5}
      color={useColorModeValue("gray.700", "gray.50")}
      overflowY={{ base: "", md: "scroll" }}
      // style={{ overflowY: "scroll" }}
    >
      <Container
        maxW="500px"
        bg="white"
        shadow="md"
        position="relative"
        borderRadius="md"
        borderLeftWidth="3px"
        borderLeftColor="blue.500"
        p={0}
      >
        <Stack p={5}>
          <Skeleton borderRadius="sm" isLoaded={!quote[1]}>
            <Text color="blue.700" lineHeight="1.5">
              {quote[1]
                ? "Lorem ipsum dolor sit amet, consectetur adip"
                : quote[0]?.content}
            </Text>
          </Skeleton>
          <HStack align="center">
            <Box>
              <FcAbout size="1.4em" style={{ marginTop: 0 }} />
            </Box>
            <Skeleton borderRadius="sm" isLoaded={!quote[1]}>
              <Text color="gray.500" fontSize="0.9em" textStyle="italics">
                {quote[1] ? "Quote Author" : quote[0]?.author}
              </Text>
            </Skeleton>
          </HStack>
        </Stack>
      </Container>
      <Container maxW="500px" p={0}>
        <Stack spacing="25px">
          <HStack color="red.500">
            <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
              Daily Deals
            </Heading>
            <SiMediafire size="30px" />
          </HStack>
          <Grid
            templateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }}
            gap={"20px"}
          >
            {deals[0].map((deal) => (
              <Box key={deal.node.id}>
                <Link
                  href={
                    deal.node.handle &&
                    `${process.env.REACT_APP_SITE_URL}/products/${deal.node.handle}`
                  }
                  isExternal
                >
                  <Image
                    borderRadius="md"
                    h="200px"
                    w="100%"
                    objectFit="contain"
                    bg={"white"}
                    p={3}
                    shadow="lg"
                    borderColor={"white"}
                    borderWidth="2px"
                    borderStyle="solid"
                    _hover={{
                      borderColor: "blue.300",
                      cursor: "pointer",
                      transform: "scale(1.04)",
                    }}
                    transition="all 0.35s ease-in-out"
                    src={
                      deal.node.images && deal.node.images.edges[0]?.node?.src
                    }
                  />
                </Link>
                <Wrap align="baseline" mt={2} spacing="3px">
                  {deal.node.tags.map((tag, index) => (
                    <Badge colorScheme="red" fontSize="0.7em" key={index} p={1}>
                      {tag}
                    </Badge>
                  ))}
                </Wrap>
                <Text
                  mt={2}
                  fontSize="md"
                  fontWeight="semibold"
                  lineHeight="short"
                >
                  {deal.node.title}
                </Text>
                <HStack align="center" mt={3}>
                  <Text>
                    $
                    {parseFloat(
                      deal.node.priceRange.minVariantPrice.amount
                    ).toFixed(2)}
                  </Text>
                  {deal.node.priceRange.minVariantPrice.amount !==
                    deal.node.priceRange.maxVariantPrice.amount && (
                    <Text as="s">
                      $
                      {parseFloat(
                        deal.node.priceRange.maxVariantPrice.amount
                      ).toFixed(2)}
                    </Text>
                  )}
                </HStack>
              </Box>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
}

export default LoginProducts;
