import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Flex, Grid, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { SearchIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Th, Thead, Tr, Tbody } from "@chakra-ui/table";
import { Checkbox } from "@chakra-ui/checkbox";
import axiosInstance from "../../../utils/axios";
import ProductListItem from "./ProductListItem";
import { debounce } from "throttle-debounce";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [status, setStatus] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState([]);
  const [type, setType] = useState();
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [collection, setCollection] = useState();
  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const debounceFunc = debounce(1000, false, (query) => {
    setTitle(query);
    setPage(1);
  });

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .post("/admin/products", {
        page: page,
        status: status,
        vendors: vendors,
        tags: tags,
        sort: sort,
        type: type,
        collection: collection,
        title: title,
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.products) {
          setProducts([...products, ...data.products]);
          setPages(data.pages);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [page, title]);
  return (
    <Stack p="5">
      <HStack justify="space-between">
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Products
        </Heading>
        <HStack>
          <Text
            cursor="pointer"
            color={useColorModeValue("gray.700", "gray.50")}
          >
            Export csv
          </Text>
          <Link to="/app/shop/products/new">
            <Button colorScheme="green" size="sm">
              Create Product
            </Button>
          </Link>
        </HStack>
      </HStack>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        borderRadius="md"
        shadow="md"
        overflow="hidden"
      >
        <Stack position="relative">
          <Flex p="3" direction={{ base: "column", md: "row" }}>
            <InputGroup m="1" color={useColorModeValue("gray.700", "gray.50")}>
              <InputLeftElement children={<SearchIcon />} />
              <Input
                placeholder="Filter products"
                onChange={(e) => {
                  debounceFunc(e.target.value);
                }}
              />
            </InputGroup>
            <Grid></Grid>
          </Flex>
          {loading && (
            <HStack
              p={3}
              bg={"blue.50"}
              position="absolute"
              w="100%"
              top="3em"
              zIndex="1"
            >
              <Spinner size="sm" color="blue.500" />
              <Text color="blue.200">Loading products</Text>
            </HStack>
          )}
        </Stack>
        <Table variant="simple" position="relative">
          <Thead position="sticky" top="0">
            <Tr>
              <Th>
                <Checkbox
                //   isChecked={
                //     collections.length > 0 &&
                //     collections.length === selectedCollections.length
                //   }
                //   onChange={(e) => {
                //     let selections = [];
                //     collections.forEach((collection) => {
                //       if (e.target.checked) {
                //         selections.push(collection.id);
                //       }
                //     });
                //     setSelectedCollections(selections);
                //   }}
                />
              </Th>
              <Th w="40px" d={{ base: "none", md: "table-cell" }}></Th>
              <Th>Product</Th>
              <Th>Status</Th>
              <Th>Source</Th>
              <Th>Price</Th>
            </Tr>
          </Thead>

          <Tbody>
            {products.map((product) => (
              <ProductListItem
                product={product}
                key={product.id}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
              />
            ))}
          </Tbody>
        </Table>
        <Stack color={useColorModeValue("gray.700", "gray.50")}>
          {page < pages && (
            <Button
              isLoading={loading}
              onClick={() => {
               // loadProducts(true);
               setPage(page + 1);
              }}
            >
              Load More
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ProductList;
