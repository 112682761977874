import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Stack, Text } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import React from "react";

function ProfileNotes() {
  return (
    <Stack align="start" color={useColorModeValue("gray.700", "gray.50")}>
      <Text fontSize="sm">Leave client note:</Text>
      <Textarea
        fontSize="xs"
        bg={useColorModeValue("gray.200", "gray.700")}
        placeholder="Leave client note"
      />
      <Button size="xs" colorScheme="green">
        Save
      </Button>
    </Stack>
  );
}

export default ProfileNotes;
