import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import theme from "./utils/chakra";
import { store } from "./utils/redux/store";
import { AuthProvider } from "./utils/useAuth";

ReactDOM.render(
  <ChakraProvider theme={theme} resetCSS={true}>
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  </ChakraProvider>,
  document.getElementById("root")
);
