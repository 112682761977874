import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Badge, Heading, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { BsBasket } from "react-icons/bs";
import React from "react";
import { BiMailSend } from "react-icons/bi";

function ShopifyProduct({ product }) {
  return (
    <HStack
      cursor="pointer"
      p="2"
      //   my="2px"
      bg={useColorModeValue("gray.200", "gray.900")}
      borderLeftWidth="2px"
      transition="all 0.15s ease-in-out"
      _hover={{
        bg: useColorModeValue("gray.100", "gray.800"),
        borderLeftColor: "red.500",
        borderLeftWidth: "2px",
      }}
    >
      <Image
        width="80px"
        height="80px"
        objectFit="cover"
        borderRadius="5px"
        src={
          product?.images && product.images.length > 0
            ? product.images[0].src
            : ""
        }
      />
      <Stack flexGrow="1">
        <Heading size="xs" color={useColorModeValue("gray.700", "gray.50")}>
          {product?.title}
        </Heading>
        <Wrap>
          <Text fontSize="sm" color={useColorModeValue("gray.700", "gray.50")}>
            ${product?.priceRange?.minVariantPrice.amount}
          </Text>
          {/* {product?.tags &&
            product?.tags.map((tag, index) => (
              <Badge key={index} colorScheme="blue">
                {tag}
              </Badge>
            ))} */}
        </Wrap>
        <HStack align="end" justify="end" w="100%">
          <IconButton
            colorScheme="blue"
            bg={useColorModeValue("gray.700", "blue.700")}
            size="sm"
            children={<BsBasket />}
          />
          <IconButton colorScheme="red" size="sm" children={<BiMailSend />} />
        </HStack>
      </Stack>
    </HStack>
  );
}

export default ShopifyProduct;
