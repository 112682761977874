import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import axiosInstance from "./axios";

function useWhatsApp() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const toast = useToast();

  const url =
    process.env.REACT_APP_INSTANCE_URL ||
    "https://api.chat-api.com/instance334898/";
  const token = process.env.REACT_APP_INSTANCE_TOKEN || "clnmpczxu5yowz3a";

  const sendMedia = async ({
    phone,
    mediaurl,
    caption,
    quotedMsgId,
    filename,
    callback,
  }) => {
    setLoading(true);
    console.log("Hello");

    if (!mediaurl.includes("https://")) {
      axiosInstance
        .post(
          url + "sendFile?token=" + token,
          {
            chatId: phone,
            body: mediaurl,
            filename: filename.replace("/", "."),
            caption: caption,
            quotedMsgId: quotedMsgId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          if (data.sent) {
            toast({
              status: "success",
              description: "Message Sent",
            });
            callback("success");
          } else {
            toast({
              status: "error",
              description: "Something went wrong, while sending message",
            });
            callback("failed");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError("Error sending message");
          toast({ status: "error", description: "Error sending message" });
          callback("failed");
        });
    } else
      axiosInstance
        .get(
          process.env.REACT_APP_EXTERNAL_API + "/imagebase64?link=" + mediaurl
        )
        .then((res) => res.data)
        .then((data) => {
          let dat = {
            chatId: phone,
            body: data,
            filename: filename.replace("/", "."),
            caption: caption,
            quotedMsgId: quotedMsgId,
          };
          axiosInstance
            .post(url + "sendFile?token=" + token, dat, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => res.data)
            .then((data) => {
              console.log(data);
              if (data.sent) {
                toast({
                  status: "success",
                  description: "Message Sent",
                });
                callback("success");
              } else {
                toast({
                  status: "error",
                  description: "Something went wrong, while sending message",
                });
                callback("failed");
              }
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError("Error sending message");
              toast({ status: "error", description: "Error sending message" });
              callback("failed");
            });
        })
        .catch((err) => {
          console.log(err);
          callback("failed");
        });
  };

  const sendText = async ({ phone, message, quotedMsgId, callback }) => {
    setLoading(true);
    let data = {
      chatId: phone,
      body: message,
      quotedMsgId: quotedMsgId,
    };
    axiosInstance
      .post(url + "sendMessage?token=" + token, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.sent) {
          toast({
            status: "success",
            description: "Message Sent",
          });
          callback("success");
        } else {
          toast({
            status: "error",
            description: "Something went wrong, while sending message",
          });
          callback("failed");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Error sending message");
        toast({ status: "error", description: "Error sending message" });
        callback("failed");
      });
  };

  return { sendMedia, sendText, loading, error };
}

export default useWhatsApp;
