import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  BiCaretDown,
  BiCaretRight,
  BiFullscreen,
  BiPencil,
  BiTrash,
} from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setConfig } from "../../../utils/redux/configSlice";
import ChildMainNav from "./ChildMainNav";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function MainNavItem({ config, position, provided }) {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isNavOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const removeNav = () => {
    let nav = [...config.nav];

    nav.splice(position, 1);

    dispatch(setConfig({ ...config, nav: nav }));
  };

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let nav = [...config.nav];
            nav[position] = {
              ...nav[position],
              image: result.info.url,
            };

            dispatch(setConfig({ ...config, nav: nav }));
          }
        }
      }
    );
    myWidget.open();
  };

  const addNewChild = () => {
    let nav = [...config.nav];
    let children = [
      ...nav[position].children,
      { title: "Default Title", url: "#", children: [] },
    ];

    nav[position] = { ...nav[position], children: children };

    dispatch(setConfig({ ...config, nav: nav }));
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = config.nav[position].children;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(
      destination.index,
      0,
      config.nav[position].children[source.index]
    );

    let nav = [...config.nav];
    nav[position] = {
      ...nav[position],
      children: newArray,
    };

    dispatch(setConfig({ ...config, nav: nav }));

    return;
  };

  return (
    <>
      <Stack ref={provided.innerRef} {...provided.draggableProps}>
        <HStack
          justify="space-between"
          cursor="pointer"
          p="2"
          shadow="sm"
          borderRadius={"md"}
          borderBottomRadius={isOpen ? "0px" : "md"}
          bg={useColorModeValue("gray.50", "gray.700")}
        >
          <HStack flexGrow="1">
            {isOpen ? <BiCaretDown /> : <BiCaretRight />}
            <Heading
              onClick={onToggle}
              size="xs"
              dangerouslySetInnerHTML={{
                __html: config.nav[position].title.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></Heading>
          </HStack>
          <IconButton children={<BiPencil />} onClick={onOpen} size="xs" />

          <IconButton
            children={<BiTrash />}
            onClick={removeNav}
            size="xs"
            colorScheme="red"
          />
          <div {...provided.dragHandleProps}>
            <MdDragIndicator />
          </div>
        </HStack>
        <Collapse in={isOpen} animateOpacity>
          <Stack p="2" py="5" rounded="md" borderTopRadius={"0px"} shadow="sm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    {config.nav[position].children.map((childnav, index) => (
                      <Draggable
                        key={"row" + position + index}
                        draggableId={"row" + position + index}
                        index={position + index}
                      >
                        {(provided, snapshot) => (
                          <ChildMainNav
                            config={config}
                            mainNavPos={position}
                            position={index}
                            provided={provided}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>

            <HStack
              justify="space-between"
              cursor="pointer"
              p="2"
              py="3"
              shadow="sm"
              onClick={addNewChild}
              borderRadius={"md"}
              bg={useColorModeValue("blue.50", "blue.700")}
              _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
              color={useColorModeValue("blue.300", "blue.100")}
            >
              <HStack flexGrow="1">
                <BiFullscreen />
                <Heading size="xs">Add New Child Nav Item</Heading>
              </HStack>
            </HStack>
          </Stack>
        </Collapse>
      </Stack>

      <Drawer isOpen={isNavOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent color={useColorModeValue("gray.800", "gray.50")}>
          <DrawerCloseButton />
          <DrawerHeader>Edit Menu</DrawerHeader>

          <DrawerBody>
            <Stack>
              <Box bg="blue.50" p="3" borderRadius={"5px"} position="relative">
                <Image
                  src={config.nav[position].image}
                  w="100%"
                  opacity="0.5"
                />
                <IconButton
                  children={<BiPencil />}
                  position="absolute"
                  top="48%"
                  left="48%"
                  onClick={showWidget}
                />
              </Box>

              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  value={config.nav[position].title}
                  onChange={(e) => {
                    let nav = [...config.nav];
                    nav[position] = {
                      ...nav[position],
                      title: e.target.value,
                    };

                    dispatch(setConfig({ ...config, nav: nav }));
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Url</FormLabel>
                <Input
                  value={config.nav[position].url}
                  onChange={(e) => {
                    let nav = [...config.nav];
                    nav[position] = {
                      ...nav[position],
                      url: e.target.value,
                    };

                    dispatch(setConfig({ ...config, nav: nav }));
                  }}
                />
              </FormControl>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MainNavItem;
