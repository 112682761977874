import { extendTheme } from "@chakra-ui/react";
const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  styles: {
    global: (props) => ({
      "html, body": {
        fontSize: "16px",
        fontFamily: "Helvetica",
        color: "#24292e",
        background: "#FBFBFB",
        lineHeight: "1",
        height: "100vh",
        scrollBehavior: "smooth",
        // scrollbarWidth: "thin",
      },
      "#root": {
        height: "100vh",
      },
      "*": {
        overFlowX: "hidden",
      },
      button: {
        boxShadow: "none !important",
      },
      messageBox: {
        boxShadow: "none !important",
      },
      ".emoji-mart-dark": {
        borderColor: "#676767",
        backgroundColor: "#1a202c",
      },
      ".emoji-mart-dark .emoji-mart-category-label span": {
        backgroundColor: "#171923",
        borderRadius: "11px",
      },
      ".emoji-mart-dark .emoji-mart-search input": {
        backgroundColor: "171923",
        marginBottom: "10px",
      },
      ".styles_r-howler__3PySh.styles_dark-themed__1Mmdx.styles_player__3QVPP":
        {
          backgroundColor: "#1a202c",
          borderRadius: "5px",
          boxShadow: "none",
        },
      ".styles_r-howler__3PySh.styles_light-themed__Pji_D.styles_player__3QVPP":
        {
          backgroundColor: "#edf2f7",
          borderRadius: "5px",
          boxShadow: "none !important",
        },
      ".mapboxgl-map": {
        borderRadius: "5px",
      },
      "canvas.snapshot-img-generator": {
        display: "none",
      },
      ".snapshot-img-generator": {
        maxWidth: "300px",
        opacity: "0.8",
        borderRadius: "5px",
      },
      ".snapshot-img-generator:hover": {
        opacity: "0.4",
      },
      ".react-video-thumbnail-image": {
        background: "black",
        borderRadius: "5px",
      },
      ".baseScreenshotimg": {
        width: "100%",
      },
    }),
  },
  colors: {
    maroon: {
      100: "rgb(255, 99, 113)",
      700: "#70000A",
    },
    primary: {
      100: "#a80100",
    },
  },
});

export default theme;
