import { ChevronRightIcon } from "@chakra-ui/icons";
import { HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function SingleExplore({ card }) {
  return (
    <Stack bg="white" h="100%">
      <HStack justify={"space-between"} p="2">
        <Text>{card.label}</Text>

        <Text fontSize="xs" cursor="pointer">
          View
        </Text>
      </HStack>
      <Image src={card.imageUrl} />
      <Stack spacing="0">
        {card.children &&
          card.children.map((child, index) => (
            <HStack
              p="2"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              transition="all 0.5s ease-in-out"
              justify={"space-between"}
              style={{ borderBottom: "1px solid rgb(230 230 230)" }}
            >
              <Text>{child.label}</Text>
              <ChevronRightIcon />
            </HStack>
          ))}
      </Stack>
    </Stack>
  );
}
