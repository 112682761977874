import { PlusSquareIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import SingleExploreSection from "./SingleExploreSection";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useLinks } from "../../../../../utils/contexts/useLinks";

function ExploreSection({ columnIndex, rowIndex, templateIndex }) {
  const data = useSelector((state) => state.pagedata.value);

  const { linksLoading, loadLinks } = useLinks();

  // console.log(
  //   data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data.layout
  //     .base
  // );

  console.log("Data", data.data.widgets, rowIndex);
  const dispatch = useDispatch();

  const removeSection = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    templates.splice(templateIndex, 1);

    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };
  const addCard = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let cards = [
      ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
    ];

    //console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: {
        ...rows[rowIndex].columns[columnIndex][templateIndex].data,
        cards: [
          ...cards,
          {
            imageUrl: "https://via.placeholder.com/300x100",
            url: "#",
            label: "Deault Label",
            children: [],
          },
        ],
      },
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack
      p="2"
      borderColor={"gray.100"}
      borderWidth={"1px"}
      borderStyle={"solid"}
    >
      <HStack align="center">
        <Heading
          size="xs"
          borderBottomWidth={"1px"}
          borderBottomColor={"gray.100"}
          flexGrow="1"
        >
          Explore Section
        </Heading>
        <IconButton size="xs" children={<BiPlus />} onClick={addCard} />
        <IconButton
          size="xs"
          children={<BiTrash />}
          onClick={() => {
            removeSection();
          }}
        />
      </HStack>

      <FormControl>
        <FormLabel fontSize="xs">Title</FormLabel>
        <Input
          value={data && data.title}
          fontSize={"xs"}
          onChange={(e) => {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];

            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              title: e.target.value,
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }}
        />
      </FormControl>

      <FormControl>
        <FormLabel fontSize="xs">Url</FormLabel>
        <AsyncCreatableSelect
          isClearable
          value={data && data.url}
          isLoading={linksLoading}
          loadOptions={loadLinks}
          placeholder="Url"
          onChange={(e) => {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];

            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              url: e,
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }}
        />
      </FormControl>

      <Grid templateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }} gap="10px">
        <FormControl>
          <FormLabel fontSize="xs">Mobile</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.layout.base
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  layout: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .layout,
                    base: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
              // setLayout({ ...layout, base: e.target.value });
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Tablet View</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.layout.md
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  layout: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .layout,
                    md: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Base Laptop</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.layout.lg
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  layout: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .layout,
                    lg: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Large Screens</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.layout.xl
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  layout: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .layout,
                    xl: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
      </Grid>
      <Text fontSize={"sm"}>[visible or hidden]</Text>
      <Grid templateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }} gap="10px">
        <FormControl>
          <FormLabel fontSize="xs">Mobile</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.base
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    base: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
              // setLayout({ ...layout, base: e.target.value });
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Tablet View</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.md
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    md: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Base Laptop</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.lg
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    lg: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="xs">Large Screens</FormLabel>
          <Input
            fontSize={"xs"}
            value={
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.visibility.xl
            }
            onChange={(e) => {
              let rows = [...data.data.widgets];
              let columns = [...rows[rowIndex].columns];
              let templates = [...rows[rowIndex].columns[columnIndex]];

              //console.log(images);
              //columns.splice(columnIndex, 1);
              templates[templateIndex] = {
                ...templates[templateIndex],
                data: {
                  ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                  visibility: {
                    ...rows[rowIndex].columns[columnIndex][templateIndex].data
                      .visibility,
                    xl: e.target.value,
                  },
                },
              };
              columns[columnIndex] = templates;

              rows[rowIndex] = { ...rows[rowIndex], columns: columns };
              dispatch(setPageData({ ...data.data, widgets: rows }));
            }}
          ></Input>
        </FormControl>
      </Grid>

      {data &&
        data.data &&
        data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
          .cards.length > 0 &&
        data.data.widgets[rowIndex].columns[columnIndex][
          templateIndex
        ].data.cards.map((card, index) => (
          <SingleExploreSection
            key={index}
            cardIndex={index}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            templateIndex={templateIndex}
          />
        ))}
    </Stack>
  );
}

export default ExploreSection;
