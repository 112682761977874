import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import React from "react";
import { useNavigate } from "react-router";

function CollectionListItem({
  collection,
  setSelectedCollections,
  selectedCollections,
}) {
  const navigate = useNavigate();
  return (
    <Tr
      color={useColorModeValue("gray.700", "gray.50")}
      bg={useColorModeValue("white", "gray.800")}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
      transition={"all 0.35s ease-in-out"}
      cursor="pointer"
      py={0}
    >
      <Td>
        <Checkbox
          isChecked={selectedCollections.includes(collection.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedCollections([...selectedCollections, collection.id]);
            } else {
              setSelectedCollections(
                selectedCollections.filter((id) => id !== collection.id)
              );
            }
          }}
        />
      </Td>
      <Td
        d={{ base: "none", md: "table-cell" }}
        onClick={() => navigate(`/app/shop/collections/${collection.id}`)}
      >
        {collection.image ? (
          <Box w="60px" h="60px">
            <Image bg="white" src={process.env.REACT_APP_EXTERNAL_API+collection.image} borderRadius="md" shadow="md" />
          </Box>
        ) : (
          <Box
            w="40px"
            h="40px"
            p="1"
            borderColor="gray.300"
            borderWidth="1px"
            borderRadius="md"
            opacity="0.6"
          >
            <svg viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1h-15zm5 3.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM16.499 17H3.497c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01L9 14l3.06-4.781a.5.5 0 0 1 .84.02l4.039 7.011c.18.34-.06.75-.44.75z"></path>
            </svg>
          </Box>
        )}
      </Td>
      <Td
        fontSize="0.9em"
        onClick={() => navigate(`/app/shop/collections/${collection.id}`)}
      >
        {collection.title}
      </Td>
      
    </Tr>
  );
}

export default CollectionListItem;
