import {
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  BiCaretDown,
  BiCaretRight,
  BiFullscreen,
  BiPencil,
  BiTrash,
} from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { setConfig } from "../../../utils/redux/configSlice";
import { useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import GrandChildNav from "./GrandChildNav";

function ChildMainNav({ config, mainNavPos, position, provided }) {
  const { isOpen: isNavOpen, onOpen, onClose } = useDisclosure();
  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();

  const removeNav = () => {
    let nav = [...config.nav];
    let children = [...nav[mainNavPos].children];
    children.splice(position, 1);
    nav[mainNavPos] = {
      ...nav[mainNavPos],
      children: children,
    };

    dispatch(setConfig({ ...config, nav: nav }));
  };

  console.log("Nav", config.nav);
  const addNewChild = () => {
    let nav = [...config.nav];
    let children = [...nav[mainNavPos].children];

    let grandChildren = [...children[position].children];

    // console.log("GrandChildren", grandChildren);
    grandChildren.push({
      title: "New Child",
      url: "",
    });

    children[position] = {
      ...children[position],
      children: [
        ...children[position].children,
        { title: "New Child", url: "" },
      ],
    };

    console.log("Children", children);

    nav[mainNavPos] = { ...nav[mainNavPos], children: children };
    console.log("Nav", nav);
    dispatch(setConfig({ ...config, nav: nav }));
  };
  return (
    <>
      <Stack>
        <HStack
          p="2"
          shadow="sm"
          justify="space-between"
          cursor="pointer"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <HStack flexGrow="1">
            {isOpen ? <BiCaretDown /> : <BiCaretRight />}
            <Heading
              onClick={onToggle}
              size="xs"
              dangerouslySetInnerHTML={{
                __html: config.nav[mainNavPos].children[position].title.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></Heading>
          </HStack>
          <HStack>
            <IconButton children={<BiPencil />} onClick={onOpen} size="xs" />
            <IconButton children={<BiTrash />} onClick={removeNav} size="xs" />
            <div {...provided.dragHandleProps}>
              <MdDragIndicator />
            </div>
          </HStack>
        </HStack>
        <Collapse in={isOpen} animateOpacity>
          <Stack p="2" py="5" rounded="md" borderTopRadius={"0px"} shadow="sm">
            <DragDropContext onDragEnd={() => {}}>
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                  >
                    {config.nav[mainNavPos].children[position].children.map(
                      (childnav, index) => (
                        <Draggable
                          key={"row" + mainNavPos + index + position}
                          draggableId={"row" + mainNavPos + position}
                          index={mainNavPos + index + position}
                        >
                          {(provided, snapshot) => (
                            <GrandChildNav
                              config={config}
                              mainNavPos={mainNavPos}
                              parentPos={position}
                              position={index}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>

            <HStack
              justify="space-between"
              cursor="pointer"
              p="2"
              py="3"
              shadow="sm"
              onClick={addNewChild}
              borderRadius={"md"}
              bg={useColorModeValue("blue.50", "blue.700")}
              _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}
              color={useColorModeValue("blue.300", "blue.100")}
            >
              <HStack flexGrow="1">
                <BiFullscreen />
                <Heading size="xs">Add Grand-Child Nav Item</Heading>
              </HStack>
            </HStack>
          </Stack>
        </Collapse>
        <Drawer isOpen={isNavOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent color={useColorModeValue("gray.800", "gray.50")}>
            <DrawerCloseButton />
            <DrawerHeader>Edit Menu</DrawerHeader>

            <DrawerBody>
              <Stack>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Textarea
                    value={config.nav[mainNavPos].children[position].title}
                    onChange={(e) => {
                      let nav = [...config.nav];
                      let children = [...nav[mainNavPos].children];
                      children[position] = {
                        ...children[position],
                        title: e.target.value,
                      };
                      nav[mainNavPos] = {
                        ...nav[mainNavPos],
                        children: children,
                      };

                      dispatch(setConfig({ ...config, nav: nav }));
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Url</FormLabel>
                  <Input
                    value={config.nav[mainNavPos].children[position].url}
                    onChange={(e) => {
                      let nav = [...config.nav];
                      let children = [...nav[mainNavPos].children];
                      children[position] = {
                        ...children[position],
                        url: e.target.value,
                      };
                      nav[mainNavPos] = {
                        ...nav[mainNavPos],
                        children: children,
                      };

                      dispatch(setConfig({ ...config, nav: nav }));
                    }}
                  />
                </FormControl>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Stack>
    </>
  );
}

export default ChildMainNav;
