import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Center,
  Circle,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/layout";
import React, { useState } from "react";
import { BsTruck } from "react-icons/bs";
import { SiDropbox } from "react-icons/si";

function ClientChatOrders() {
  const [orders, setOrders] = useState([]);
  return (
    <Stack color={useColorModeValue("gray.700", "gray.50")}>
      <HStack>
        <Circle
          my="1px"
          size="40px"
          bg={useColorModeValue("gray.700", "transparent")}
          color={useColorModeValue("gray.50", "gray.50")}
          borderWidth="1px"
          borderColor={useColorModeValue("gray.700", "gray.600")}
        >
          <BsTruck />
          <Text
            position="absolute"
            top="-1"
            right="-3"
            bg="red.500"
            px="2"
            py="1"
            fontSize="xs"
            borderRadius="full"
            color="white"
          >
            0
          </Text>
        </Circle>
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Orders
        </Heading>
      </HStack>
      {/* {orders.length === 0 && (
        <Center minH="300px" w="100%">
          <Stack w="100" align="center">
            <SiDropbox size="60px" />
            <Heading size="md">No orders found</Heading>
          </Stack>
        </Center>
      )} */}
      <Accordion>
        <AccordionItem>
          <AccordionButton
            bg={useColorModeValue("gray.200", "gray.900")}
            borderRadius="md"
            shadow="md"
          >
            <HStack flex="1" textAlign="left" justify="space-between" py="1">
              <HStack>
                <Text fontSize="sm" fontWeight="bold">
                  #21032
                </Text>
                <Text fontSize="xs"> 07 Dec 2021 </Text>
              </HStack>
              <Badge colorScheme="blue" p="1" borderRadius="md">
                Draft Order
              </Badge>
            </HStack>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Stack>
              <HStack align="stretch">
                <Stack w="60px">
                  <Text fontSize="xs" fontWeight="bold">
                    Dec 7
                  </Text>
                  <Text fontSize="xs">04:56</Text>
                </Stack>
                <Stack align="center" h="100%">
                  <Circle bg={"green.500"} color="gray.50" size="30px">
                    <CheckIcon />
                  </Circle>
                  <Box
                    w="1px"
                    bg={useColorModeValue("gray.500", "gray.400")}
                    h="100%"
                  ></Box>
                </Stack>
                <Stack pb="100px">
                  <Heading size="xs">
                    <Text fontWeight="bold">Order Created</Text>
                    <Text fontSize="xs">Order was created by Jane Doe</Text>
                  </Heading>
                </Stack>
              </HStack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
}

export default ClientChatOrders;
