import React from "react";
import { Route, Routes } from "react-router";
import EditProduct from "../products/editproduct/EditProduct";
import NewProduct from "../products/newproduct/NewProduct";
import ProductList from "../products/ProductList";

function ProductsEntry() {
  return (
    <Routes>
      <Route path="/" element={<ProductList />} />
      <Route path="/new" element={<NewProduct />} />
      <Route path="/:id" element={<EditProduct />} />
      {/* <Route path="/:id" element={<EditCollection />} /> */}
    </Routes>
  );
}

export default ProductsEntry;
