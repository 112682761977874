import React from "react";
import { Route, Routes } from "react-router";
import Loading from "../../../components/Loading";
import CollectionsList from "./CollectionsList";
import EditCollection from "./editcollection/EditCollection";
import CreateCollection from "./newcollection/CreateCollection";

function CollectionsEntry() {
  return (
    <Routes>
      <Route path="/" element={<CollectionsList />} />
      <Route path="/new" element={<CreateCollection />} />
      <Route path="/:id" element={<EditCollection />} />
    </Routes>
  );
}

export default CollectionsEntry;
