import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Divider,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function PageMenu({ data }) {
  console.log(data);
  return (
    <Stack bg="white" borderRadius={3} shadow="sm" spacing="0">
      <HStack align="center" justify={"space-between"} p="3">
        <Heading
          size="sm"
          dangerouslySetInnerHTML={{
            __html:
              data && data.title.replace(/(<? *script)/gi, "illegalscript"),
          }}
        ></Heading>
        <Text fontSize={"xs"} color="red.500" textDecoration={"underline"}>
          View More
        </Text>
      </HStack>
      <Divider />
      <Stack w="100%" spacing="0">
        {data &&
          data.data.children.map((child, index) => (
            <Popover w="100%" placement="right" gutter="0" trigger={"hover"}>
              <PopoverTrigger w="100%">
                <HStack
                  w="100%"
                  _hover={{ bg: "gray.50" }}
                  justify={"space-between"}
                  px="3"
                  py="2"
                  cursor="pointer"
                >
                  <Text fontSize={"sm"}>{child.label}</Text>
                  <ChevronRightIcon />
                </HStack>
              </PopoverTrigger>
              <PopoverContent borderRadius={0} bg="gray.50">
                {child.children.map((grandChild, index) => (
                  <HStack
                    w="100%"
                    bg="gray,50"
                    _hover={{ bg: "white" }}
                    justify={"space-between"}
                    px="3"
                    py="2"
                    cursor="pointer"
                  >
                    <Text fontSize={"sm"}>{grandChild.label}</Text>
                  </HStack>
                ))}
              </PopoverContent>
            </Popover>
          ))}
      </Stack>
    </Stack>
  );
}
