import { IconButton } from "@chakra-ui/button";
import { HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import React, { useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { BsBasket } from "react-icons/bs";
import TakealotSubOption from "./TakealotSubOption";

function TakealotOption({ option, fees, product }) {
  return (
    <Stack>
      <Text fontSize="sm" color="red.500">
        {option.call_to_action}
      </Text>
      {option.options.map((opt, index) => (
        <>
          <TakealotSubOption
            opt={opt}
            key={index}
            product={product}
            fees={fees}
          />
        </>
      ))}
    </Stack>
  );
}

export default TakealotOption;
