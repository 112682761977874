import {
  Box,
  Center,
  Grid,
  Spinner,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import CaurosrlGridImages from "../../templates/CauroselGridImages";
import CollectionCaurosel from "../../templates/CollectionCaurosel";
import CollectionGrid from "../../templates/CollectionGrid";
import DetailedCard from "../../templates/DetailedCard";
import ExploreSection from "../../templates/ExploreSection";
import GridCardCaurosel from "../../templates/GridCardCaurosel";
import GridCards from "../../templates/GridCards";
import IconCards from "../../templates/IconCards";
import ImageAdvert from "../../templates/ImageAdvert";
import ImageCaurosel from "../../templates/ImageCaurosel";
import ImageGridCard from "../../templates/ImageGridCard";
import InfoCards from "../../templates/InfoCards";
import LogoSection from "../../templates/LogoSection";
import PageMenu from "../../templates/PageMenu";
import SimpleCard from "../../templates/SimpleCard";
import TextSection from "../../templates/TextSection";
import TrackCard from "../../templates/TrackCard";

function PageContent() {
  const data = useSelector((state) => state.pagedata.value);
  return (
    <Stack w="100%" p="2" color={"gray.800"}>
      {data.loading && (
        <Center h="calc(100vh - 300px)">
          <Spinner color="red.500" />
        </Center>
      )}
      {!data.loading &&
        data.data &&
        data.data.widgets &&
        data.data.widgets.map((layout, index) => (
          <Grid templateColumns={layout.layout} gap="10px" key={index}>
            {layout.columns &&
              layout.columns.map((column, pos) => (
                <Stack
                  spacing="50px"
                  key={pos}
                  borderWidth={"1px"}
                  borderStyle={"dashed"}
                  borderColor={"blue.100"}
                  p="1"
                >
                  {column.map((template, posi) => (
                    <Box key={posi}>
                      {template &&
                        (() => {
                          switch (template.type) {
                            case "ImageCaurosel":
                              return <ImageCaurosel data={template} />;
                            case "SimpleCard":
                              return <SimpleCard data={template} />;
                            case "TrackButton":
                              return <TrackCard />;
                            case "ImageAdvert":
                              return <ImageAdvert data={template} />;
                            case "ImageGridCard":
                              return <ImageGridCard data={template} />;
                            case "InfoCards":
                              return <InfoCards data={template} />;
                            case "GridCards":
                              return <GridCards data={template} />;
                            case "CollectionCaurosel":
                              return <CollectionCaurosel data={template} />;
                            case "GridCardCaurosel":
                              return <GridCardCaurosel data={template} />;
                            case "CauroselGridImages":
                              return <CaurosrlGridImages data={template} />;
                            case "DetailedCard":
                              return <DetailedCard data={template} />;
                            case "IconCards":
                              return <IconCards data={template} />;
                            case "CollectionGrid":
                              return <CollectionGrid data={template} />;
                            case "TextSection":
                              return <TextSection data={template} />;
                            case "ExploreSection":
                              return <ExploreSection data={template} />;
                            case "LogoSection":
                              return <LogoSection data={template} />;
                            case "PageMenu":
                              return <PageMenu data={template} />;
                            default:
                              return "#FFFFFF";
                          }
                        })()}
                    </Box>
                  ))}
                </Stack>
              ))}
          </Grid>
        ))}
    </Stack>
  );
}

export default PageContent;
