import { Circle, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaTruckMoving } from "react-icons/fa";

function TrackCard() {
  return (
    <Flex
      shadow="md"
      bg="white"
      overflow="hidden"
      align="center"
      p="3"
      transition="all 0.3s ease-in-out"
      borderRadius="3px"
      borderWidth="2px"
      borderColor="gray.50"
      _hover={{ bg: "gray.50", cursor: "pointer" }}
      direction={{ md: "column", lg: "row", xl: "row" }}
    >
      <Circle
        bg="primary.100"
        borderColor="gray.300"
        borderWidth="2px"
        size="60px"
        shadow="md"
        margin="10px"
      >
        <FaTruckMoving
          color="white"
          size="1.8em"
          transition="all 0.3s ease-in-out"
          _hover={{ size: "1.6em" }}
        />
      </Circle>
      <Stack spacing="3px" align={{ md: "center", lg: "start", xl: "start" }}>
        <Heading size="xs" fontSize="1em">
          Where is my order?
        </Heading>
        <Text
          fontSize="xs"
          textAlign={{ md: "center", lg: "start", xl: "start" }}
        >
          Check your delivery status or collection
        </Text>
      </Stack>
    </Flex>
  );
}

export default TrackCard;
