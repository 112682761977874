import { Avatar, AvatarGroup } from "@chakra-ui/avatar";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { SearchIcon } from "@chakra-ui/icons";
import { Badge, Box, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

function ChatTopInfo({ phone }) {
  const [loadingChat, setLoadingChat] = useState(true);
  useEffect(() => {
    setLoadingChat(false);
    console.log(phone);
  }, []);
  return (
    <HStack
      position="sticky"
      top="0"
      py="6.5px"
      zIndex="9"
      px="7"
      bg={useColorModeValue("gray.100", "gray.800")}
    >
      <Box>
        <Skeleton borderRadius="full" isLoaded={!loadingChat}>
          <Avatar src="" size="md" borderColor="white" />
        </Skeleton>
      </Box>
      <Stack flexGrow="1">
        <Skeleton isLoaded={!loadingChat}>
          <Text
            fontSize="0.85em"
            color={useColorModeValue("gray.700", "gray.50")}
            fontWeight="600"
          >
            263771564110
          </Text>
        </Skeleton>
        <Skeleton isLoaded={!loadingChat}>
          <Text
            fontSize="0.75em"
            color={useColorModeValue("gray.600", "gray.500")}
          >
            last seen yesterday
          </Text>
        </Skeleton>
      </Stack>
      <Wrap>
        <Badge
          colorScheme="orange"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          Followup
        </Badge>
        <Badge
          colorScheme="green"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          New Customer
        </Badge>
        <Badge
          colorScheme="blue"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          Wants Fridge
        </Badge>
        <Badge
          colorScheme="red"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          MVP
        </Badge>
        <Badge
          colorScheme="orange"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          9 orders
        </Badge>
        <Badge
          colorScheme="green"
          px="3"
          py="1"
          fontWeight="bold"
          borderRadius="full"
          fontSize="0.6em"
        >
          New Lead
        </Badge>
      </Wrap>
      <HStack spacing="20px" color={useColorModeValue("gray.700", "gray.50")}>
        <AvatarGroup size="xs" max={2}>
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />

          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />

          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />

          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />

          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
        </AvatarGroup>
        <SearchIcon cursor="pointer" />
        <Menu>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>
          <MenuList>
            <MenuItem command="⌘T">New Tab</MenuItem>
          </MenuList>
        </Menu>
      </HStack>{" "}
    </HStack>
  );
}

export default ChatTopInfo;
