import React from "react";
import { Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import Slim from "../../../../utils/slim/slim.react";
import "../../../../utils/slim/slim.min.css";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { useState } from "react";
import { useEffect } from "react";
import CloudinaryUploadWidget from "../../../../utils/cloudinary/CloudinaryUploadWidget";

function ProductImage({ image, images, position, setImages }) {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "ponhwf4e",
    },
  });

  const myImage = cld.image("kdvlhrtx7iym8ebjnx1d");

  const cloudinaryCore = new Cloudinary({ cloud_name: "ponhwf4e" });

  return (
    <Box position="relative">
      {/* <AdvancedImage cldImg={myImage} />
      <img src={cloudinaryCore.url("kdvlhrtx7iym8ebjnx1d")} /> */}
      <Image src={image.replace("{size}","pdpxl")} />
      {
        <Button
          colorScheme="red"
          size="xs"
          //   position="absolute"
          top="-5px"
          right="-5px"
          onClick={() => {
            let newimages = [...images];
            newimages.splice(position, 1);
            setImages(newimages);
          }}
        >
          -
        </Button>
      }
    </Box>
  );
}

export default ProductImage;
