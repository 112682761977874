import React from "react";
import {
  Box,
  Center,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PageSideBar from "./PageSideBar";
import PageContent from "./PageContent";
import { useState } from "react";
import axiosInstance from "../../../../utils/axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setPageData,
  setPageLoading,
} from "../../../../utils/redux/pageDataSlice";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdSave } from "react-icons/md";
import { BiCollapse, BiFullscreen } from "react-icons/bi";
import { LinksProvider } from "../../../../utils/contexts/useLinks";

function PageEditor() {
  const { id } = useParams();

  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();

  const toast = useToast();

  const [show, setShow] = useState(true);

  const [winReady, setwinReady] = useState(false);
  useEffect(() => {
    setwinReady(true);
  }, []);

  useEffect(() => {
    //setData({ ...data, loading: true });
    dispatch(setPageLoading(true));
    axiosInstance
      .get("/pages/" + id)
      .then((res) => res.data)
      .then((res) => {
        if (res.status && res.status === "success") {
          //setData();

          dispatch(setPageData(res));

          //setWidgets(data.widgets);
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, [id]);

  const savePage = () => {
    dispatch(setPageLoading(true));
    let page = { ...data.page, layout: data.data.widgets };
    axiosInstance
      .put("/pages/" + id, page)
      .then((res) => res.data)
      .then((res) => {
        dispatch(setPageLoading(false));
        if (res.error === false) {
          toast({
            status: "success",
            description: "Page updated successfully",
          });
        } else {
          toast({
            status: "error",
            description: "Page update error",
          });
        }
      })
      .catch((err) => {
        dispatch(setPageLoading(false));
        toast({
          status: "error",
          description: "Page update error",
        });
        console.log(err);
      });
  };
  return (
    <LinksProvider>
      <HStack
        h="100%"
        flexGrow="1"
        align="start"
        justify="start"
        w="100%"
        spacing="0"
        overflowX="clip"
        color={useColorModeValue("gray.800", "gray.50")}
        position="relative"
      >
        {data.loading ? (
          <Center w="100%" minH="300px">
            <Spinner />
          </Center>
        ) : (
          <Box position="sticky" top={"50px"}>
            {winReady && (
              <Stack>
                <HStack>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setShow(!show);
                      }}
                      children={!show ? <BiCollapse /> : <BiFullscreen />}
                      size="sm"
                    />
                  </Box>
                  <Box>
                    {show && (
                      <IconButton
                        children={<MdSave />}
                        size="sm"
                        onClick={savePage}
                      />
                    )}
                  </Box>
                </HStack>

                {show && <PageSideBar />}
              </Stack>
            )}
          </Box>
        )}

        <PageContent />
      </HStack>
    </LinksProvider>
  );
}

export default PageEditor;
