import { Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function TextSection({ data }) {
  return (
    <Stack bg="white" p="5" borderRadius={5}>
      <Heading size="md">{data.title}</Heading>
      <Text>{data.content}</Text>
    </Stack>
  );
}
