import { useEffect, useState } from "react";

function useQuote() {
  const [quote, setQuote] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.quotable.io/random?tags=business|famous-quotes|famous-quotes|future|success|happiness|wisdom|education"
        );
        const data = await response.json();
        setQuote(data);
      } catch (e) {}

      setLoading(false);
    };
    fetchData();
  }, []);

  return [quote, loading];
}

export default useQuote;
