import {
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import GrandChildMenu from "./GrandChildMenu";
import { AddIcon } from "@chakra-ui/icons";

export default function ParentMenu({
  rowIndex,
  columnIndex,
  templateIndex,
  menuIndex,
  child,
}) {
  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const addChildNav = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let Idata = {
      ...rows[rowIndex].columns[columnIndex][templateIndex].data,
    };
    let children = [...Idata.children];

    let grandchildren = [
      ...children[menuIndex].children,
      { label: "Default", url: "#" },
    ];
    children[menuIndex] = { ...children[menuIndex], children: grandchildren };

    Idata = { ...Idata, children: children };
    //console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: Idata,
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const removeParent = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let Idata = {
      ...rows[rowIndex].columns[columnIndex][templateIndex].data,
    };
    let children = [...Idata.children];

    children.splice(menuIndex, 1);

    Idata = { ...Idata, children: children };
    //console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: Idata,
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };
  return (
    <>
      {data && (
        <HStack justify="space-between">
          <Text fontSize="xs">{child && child.label}</Text>
          <ButtonGroup>
            <IconButton size="xs" children={<BiPencil />} onClick={onOpen} />
            <IconButton
              size="xs"
              children={<BiTrash />}
              onClick={removeParent}
            />
          </ButtonGroup>
        </HStack>
      )}

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{child && child.label}</DrawerHeader>

          <DrawerBody>
            <Stack>
              <HStack>
                <Input
                  placeholder="Label"
                  value={child && child.label}
                  onChange={(e) => {
                    let rows = [...data.data.widgets];
                    let columns = [...rows[rowIndex].columns];
                    let templates = [...rows[rowIndex].columns[columnIndex]];
                    let Idata = {
                      ...rows[rowIndex].columns[columnIndex][templateIndex]
                        .data,
                    };
                    let children = [...Idata.children];
                    console.log(children);
                    children[menuIndex] = {
                      ...children[menuIndex],
                      label: e.target.value,
                    };

                    Idata = { ...Idata, children: children };
                    //console.log(images);
                    //columns.splice(columnIndex, 1);
                    templates[templateIndex] = {
                      ...templates[templateIndex],
                      data: Idata,
                    };
                    columns[columnIndex] = templates;

                    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                    dispatch(setPageData({ ...data.data, widgets: rows }));
                  }}
                />
                <IconButton
                  icon={<AddIcon />}
                  onClick={addChildNav}
                  size="xs"
                />
              </HStack>

              <Stack spacing="4">
                {data &&
                  data.data.widgets[rowIndex].columns[columnIndex][
                    templateIndex
                  ].data.children[menuIndex].children.map((grandchild, i) => (
                    <GrandChildMenu
                      key={i}
                      grandchild={grandchild}
                      childId={i}
                      child={child}
                      menuIndex={menuIndex}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                      templateIndex={templateIndex}
                      data={data}
                    />
                  ))}
              </Stack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
