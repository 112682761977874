import {
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  BiCaretDown,
  BiCaretRight,
  BiFullscreen,
  BiPencil,
  BiTrash,
} from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { setConfig } from "../../../utils/redux/configSlice";
import { useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function GrandChildNav({ config, mainNavPos, parentPos, position, provided }) {
  const { isOpen: isNavOpen, onOpen, onClose } = useDisclosure();
  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();

  const removeNav = () => {
    let nav = [...config.nav];
    let children = [...nav[mainNavPos].children];
    children.splice(position, 1);
    nav[mainNavPos] = {
      ...nav[mainNavPos],
      children: children,
    };

    dispatch(setConfig({ ...config, nav: nav }));
  };

  console.log("Nav", config.nav);
  const addNewChild = () => {
    let nav = [...config.nav];
    let children = [...nav[mainNavPos].children];

    let grandChildren = [...children[position].children];

    // console.log("GrandChildren", grandChildren);
    grandChildren.push({
      title: "New Child",
      url: "",
    });

    children[position] = {
      ...children[position],
      children: [
        ...children[position].children,
        { title: "New Child", url: "" },
      ],
    };

    console.log("Children", children);

    nav[mainNavPos] = { ...nav[mainNavPos], children: children };
    console.log("Nav", nav);
    dispatch(setConfig({ ...config, nav: nav }));
  };
  return (
    <>
      <Stack>
        <HStack
          p="2"
          shadow="sm"
          justify="space-between"
          cursor="pointer"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <HStack flexGrow="1">
            <Text
              onClick={onToggle}
              fontSize="sm"
              dangerouslySetInnerHTML={{
                __html: config.nav[mainNavPos].children[parentPos].children[
                  position
                ].title.replace(/(<? *script)/gi, "illegalscript"),
              }}
            ></Text>
          </HStack>
          <HStack>
            <IconButton children={<BiPencil />} onClick={onOpen} size="xs" />
            <IconButton children={<BiTrash />} onClick={removeNav} size="xs" />
            <div {...provided.dragHandleProps}>
              <MdDragIndicator />
            </div>
          </HStack>
        </HStack>
        <Drawer isOpen={isNavOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent color={useColorModeValue("gray.800", "gray.50")}>
            <DrawerCloseButton />
            <DrawerHeader>Edit Menu</DrawerHeader>

            <DrawerBody>
              <Stack>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Textarea
                    value={
                      config.nav[mainNavPos].children[parentPos].children[
                        position
                      ].title
                    }
                    onChange={(e) => {
                      let nav = [...config.nav];
                      let children = [...nav[mainNavPos].children];
                      let grands = [...children[parentPos].children];
                      grands[position] = {
                        ...grands[position],
                        title: e.target.value,
                      };
                      children[parentPos] = {
                        ...children[parentPos],
                        children: grands,
                      };
                      nav[mainNavPos] = {
                        ...nav[mainNavPos],
                        children: children,
                      };

                      dispatch(setConfig({ ...config, nav: nav }));
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Url</FormLabel>
                  <Input
                    value={
                      config.nav[mainNavPos].children[parentPos].children[
                        position
                      ].url
                    }
                    onChange={(e) => {
                      let nav = [...config.nav];
                      let children = [...nav[mainNavPos].children];
                      let grands = [...children[parentPos].children];
                      grands[position] = {
                        ...grands[position],
                        url: e.target.value,
                      };
                      children[parentPos] = {
                        ...children[parentPos],
                        children: grands,
                      };
                      nav[mainNavPos] = {
                        ...nav[mainNavPos],
                        children: children,
                      };

                      dispatch(setConfig({ ...config, nav: nav }));
                    }}
                  />
                </FormControl>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Stack>
    </>
  );
}

export default GrandChildNav;
