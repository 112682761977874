import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Center,
  Circle,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/layout";
import React, { useState } from "react";
import { BsCart } from "react-icons/bs";

import { SiDropbox } from "react-icons/si";

function ClientChatCart() {
  const [cart, setCart] = useState([]);
  return (
    <Stack color={useColorModeValue("gray.700", "gray.50")}>
      <HStack>
        <Circle
          my="1px"
          size="40px"
          bg={useColorModeValue("gray.700", "transparent")}
          color={useColorModeValue("gray.50", "gray.50")}
          borderWidth="1px"
          borderColor={useColorModeValue("gray.700", "gray.600")}
        >
          <BsCart />
          <Text
            position="absolute"
            top="-1"
            right="-3"
            bg="red.500"
            px="2"
            py="1"
            fontSize="xs"
            borderRadius="full"
            color="white"
          >
            0
          </Text>
        </Circle>
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Client's Cart
        </Heading>
      </HStack>
      {cart.length === 0 && (
        <Center minH="300px" w="100%">
          <Stack w="100" align="center">
            <SiDropbox size="60px" />
            <Heading size="md">Cart empty</Heading>
          </Stack>
        </Center>
      )}
    </Stack>
  );
}

export default ClientChatCart;
