import { Box, Grid, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import HorizontalProductCard from "../../products/HorizontalProductCard";

export default function CollectionGrid({ data }) {
  console.log(data);
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState();
  const [products, setProducts] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  const page = 1;

  useEffect(() => {
    if (data.data.id) {
      axiosInstance
        .get("/collections/" + data.data.id.value + "/products", {
          params: { sort: `["created","DESC"]`, page: 1, query: "" },
        })
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          setLoading(false);
          if (data.status === 200) {
            //setCategory(data.category);
            //setCount(data.count);
            setCollection(data.collection);
            setProducts(data.products);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [data]);

  return (
    <Stack bg="white" w="100%">
      <HStack shadow="sm" p="2" justify={"space-between"}>
        <Heading size="sm">Collection Title</Heading>
        <Text textDecoration={"underline"} fontSize="0.8em" color="red.600">
          View More
        </Text>
      </HStack>
      <Grid
        templateColumns={{ base: "100%", md: "1fr 1fr 1fr" }}
        p="3"
        gap="5px"
        display={{ base: "none", md: "grid" }}
      >
        {products.map((product, index) => {
          if (index > 5) {
            return;
          }
          console.log(index);
          return <HorizontalProductCard product={product} key={index} />;
        })}
      </Grid>
      <HStack
        w="100%"
        overflowX={"scroll"}
        display={{ base: "flex", md: "none" }}
      >
        {products.map((product, index) => {
          if (index > 5) {
            return;
          }
          return (
            <Box key={index} minW="80%">
              <HorizontalProductCard product={product} />
            </Box>
          );
        })}
      </HStack>
    </Stack>
  );
}
