import React, { useRef, useState } from "react";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { Input } from "@chakra-ui/input";
import axiosInstance from "../../../utils/axios";
import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function UploadPages() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState("");

  const navigate = useNavigate();

  const savePage = () => {
    setLoading(true);
    axiosInstance
      .post("/pages", { title: name })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.slug) {
          toast({
            status: "success",
            description: "Page has been created successfully",
          });
          onClose();
          navigate.push("/app/shop/pages/" + data.id);
        } else {
          toast({ status: "error", description: "Error while creating page" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button colorScheme="green" onClick={onOpen} size="sm">
        Create New Page
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Page</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Page Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <Button
              mt="5"
              size="sm"
              colorScheme={"red"}
              isLoading={loading}
              disabled={!name.length || loading}
              onClick={savePage}
            >
              Save
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UploadPages;
