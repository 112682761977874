import React from "react";
import { Grid, Stack, Heading, Image, Link, Box } from "@chakra-ui/react";

function SimpleCard({ data }) {
  return (
    <Grid templateColumns={data.data.layout} gap="10px">
      {data.data &&
        data.data.cards.map((card, index) => (
          <Link key={index} to="#">
            <Stack
              shadow="md"
              bg="white"
              overflow="hidden"
              p="3"
              transition="all 0.3s ease-in-out"
              borderRadius="3px"
              borderWidth="2px"
              borderColor="gray.50"
              align="center"
              _hover={{ cursor: "pointer", shadow: "lg" }}
            >
              <Box display="flex" justify="center" flex="0 1 100%" minH="0">
                <Image
                  src={card.imageUrl}
                  alt={card.label}
                  w="auto"
                  maxH="120px"
                  maxW={"100%"}
                  h="auto"
                />
              </Box>

              <Heading
                fontSize={"18px"}
                flexShink="0"
                textAlign={"center"}
                whiteSpace="nowrap"
                overflow={"hidden"}
                textOverflow="ellipsis"
                maxW="100%"
                fontWeight="400"
                mb="0"
              >
                {card.label}
              </Heading>
            </Stack>
          </Link>
        ))}
    </Grid>
  );
}

export default SimpleCard;
