import React, { useEffect, useRef, useState } from "react";
import {
  Center,
  Container,
  Grid,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/layout";
import { Button, IconButton } from "@chakra-ui/button";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { useNavigate, useParams } from "react-router";
import { Skeleton } from "@chakra-ui/skeleton";
import axiosInstance from "../../../../utils/axios";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { Link as ExternalLink } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import EditCollectionImage from "./EditCollectionImage";
import TakealotLinks from "../takealot/TakealotLinks";
import CollectionSEO from "../seo/CollectionSEO";
import { useToast } from "@chakra-ui/toast";
import CollectionProducts from "../products/CollectionProducts";

function EditCollection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [collection, setCollection] = useState({
    title: "",
    image: null,
    type: "manual"
  });
  const [descriptionReady, setDescriptionReady] = useState(false);
  const { id } = useParams();
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();

  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    setDescriptionReady(false);
    axiosInstance
      .get("/collections/" + id)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data.collection) {
          setCollection(data.collection);
          setLinks(data.takealot);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const saveCollection = () => {
    setLoading(true);

    let submitedCollection = { ...collection };
    if (editorRef.current) {
      submitedCollection.description = editorRef.current.getContent();
    }
    axiosInstance
      .put(`/collections/${collection.id}`, submitedCollection)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          //setCollection({ ...collection, image: data.src });
          toast({
            status: "success",
            description: "Collection updated successfully",
          });
        } else {
          toast({ status: "error", title: "Error", description: data.message });
        }
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, try again",
        });
        setLoading(false);
      });
  };
  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p={{ base: 0, md: "5" }} spacing="20px">
          <HStack justify="space-between">
            <HStack spacing="20px">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                color={useColorModeValue("gray.700", "gray.50")}
                icon={<ChevronLeftIcon />}
              />
              <Skeleton isLoaded={!loading}>
                <Heading
                  size="md"
                  minW="200px"
                  minH="25px"
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  {collection?.title}
                </Heading>
              </Skeleton>
            </HStack>
            <HStack>
              <Link to={`/app/shop/collections/new`}>
                <Button colorScheme="blue" size="sm">
                  New Collection
                </Button>
              </Link>
              <Button
                onClick={() => {
                  saveCollection();
                }}
                isLoading={loading}
                colorScheme="green"
                disabled={!collection || loading}
                size="sm"
              >
                Save
              </Button>
              <Skeleton isLoaded={!loading}>
                <ExternalLink
                  href={`${process.env.REACT_APP_SITE_URL}/collections/${collection?.handle}`}
                  target="_blank"
                >
                  <IconButton
                    size="sm"
                    color={useColorModeValue("gray.700", "gray.50")}
                    icon={<FaEye />}
                  />
                </ExternalLink>
              </Skeleton>
            </HStack>
          </HStack>
          <Grid
            templateColumns={{ base: "100%", md: "8fr 4fr" }}
            gap={"30px"}
            color={useColorModeValue("gray.700", "gray.50")}
          >
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <Skeleton isLoaded={!loading}>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      value={collection?.title}
                      onChange={(e) => {
                        setCollection({ ...collection, title: e.target.value });
                      }}
                      placeholder="Summer collection, under $100, Staff Picks"
                    />
                  </FormControl>
                </Skeleton>
                <Skeleton isLoaded={collection && !loading && descriptionReady}>
                  <FormControl>
                    <FormLabel>Description (optional)</FormLabel>
                    {collection && (
                      <Editor
                        tinymceScriptSrc="https://cdn.tiny.cloud/1/sze44wj07kdbdi9w54xvqbn53xi81mgock8dvdjgdolu31x5/tinymce/5/tinymce.min.js"
                        onInit={(evt, editor) => {
                          editorRef.current = editor;
                          setDescriptionReady(true);
                        }}
                        initialValue={collection?.description}
                        init={{
                          branding: false,
                          height: 200,
                          menubar: false,
                          placeholder: "Description...",
                          skin: colorMode === "light" ? "oxide" : "oxide-dark",
                          content_css: colorMode === "light" ? "light" : "dark",
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          formats: {
                            removeformat: [
                              {
                                selector: "b,strong,em,i,font,u,strike,code",
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                              {
                                selector: "span",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "code",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                deep: true,
                              },
                              {
                                selector: "*",
                                attributes: ["style", "class"],
                                remove: "all",
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </FormControl>
                </Skeleton>
              </Stack>
            </Stack>
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl color={useColorModeValue("gray.700", "gray.700")}>
                  <Skeleton isLoaded={!loading}>
                    <FormLabel color={useColorModeValue("gray.700", "gray.50")}>
                      Collection image
                    </FormLabel>
                  </Skeleton>
                  <Skeleton isLoaded={!loading}>
                    {collection && (
                      <EditCollectionImage
                        collection={collection}
                        setCollection={setCollection}
                      />
                    )}
                  </Skeleton>
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
          {collection?.id && <TakealotLinks collection={collection} tlinks={links} />}
          {collection?.id && (
            <CollectionSEO
              collection={collection}
              setCollection={setCollection}
            />
          )}
          {collection?.id && <CollectionProducts collection={collection} />}
        </Stack>
      </Container>
    </Center>
  );
}

export default EditCollection;
