import { Button, ButtonGroup } from "@chakra-ui/button";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import {
  Badge,
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import { useToast } from "@chakra-ui/toast";
import React, { useState } from "react";
import axiosInstance from "../../../../utils/axios";

function TakealotLink({ link, setLinks }) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const toast = useToast();

  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const now =
    year +
    "-" +
    month +
    "-" +
    ("0" + day).slice(-2) +
    " " +
    "00" +
    ":" +
    "00" +
    ":" +
    "00";

  console.log(now);

  const testLink = () => {
    setLoading(true);
    axiosInstance
      .get("/testlink?link=" + encodeURIComponent(link.api))
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.sections?.products?.paging?.total_num_found) {
          toast({
            status: "success",
            title:
              data.sections.products.paging.total_num_found + " products found",
            description: "Link is valid",
          });
        } else {
          toast({
            status: "error",
            title: "Error",
            description: "Link is invalid",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast({
          status: "error",
          title: "Error",
          description: "Connection problem",
        });
      });
  };

  const removeLink = () => {
    // remove link from state
    setLinks((prev) => prev.filter((l) => l.id !== link.id));
    axiosInstance.get(
      `/removelink?removelink=${link.tid}`
    );
  };
  return (
    <>
      {/* <HStack
        bg={useColorModeValue("gray.50", "gray.700")}
        p={2}
        justify="space-between"
      >
        <Stack maxW="100%">
          <Text fontSize="sm" maxW={{ base: "100px", md: "340px" }}>
            {link.url.replace(
              "https://api.takealot.com/rest/v-1-10-0/searches/products,filters,facets,sort_options,breadcrumbs,slots_audience,context,seo?",
              ""
            )}
          </Text>
        </Stack>
        <HStack>
          <Button
            size="sm"
            colorScheme="red"
            isLoading={loading}
            disabled={loading}
            variant="outline"
          >
            Remove
          </Button>
          <Button
            size="sm"
            colorScheme="blue"
            isLoading={loading}
            disabled={loading}
            onClick={testLink}
            variant="outline"
          >
            Test Link
          </Button>
        </HStack>
      </HStack> */}
      <Tr>
        <Td
          bg={useColorModeValue("gray.50", "gray.800")}
          _hover={{ bg: useColorModeValue("white", "gray.900") }}
        >
          <Stack color={useColorModeValue("gray.700", "gray.50")} py={3}>
            <Text>
              {link.url.replace(
                "https://api.takealot.com/rest/v-1-10-0/searches/products,filters,facets,sort_options,breadcrumbs,slots_audience,context,seo?",
                ""
              )}
            </Text>
            <Wrap>
              <ButtonGroup isAttached>
                <Button
                  size="sm"
                  onClick={removeLink}
                  isLoading={loading}
                  disabled={loading}
                >
                  Remove
                </Button>
                <Button
                  size="sm"
                  isLoading={loading}
                  disabled={loading}
                  onClick={testLink}
                >
                  Test Link
                </Button>
              </ButtonGroup>
              <Wrap>
                {link.ran && (
                  <Badge colorScheme={link.ran > now ? "green" : "orange"}>
                    Last Ran: {link.ran}
                  </Badge>
                )}
                {/* {link.vendor && (
                  <Badge colorScheme="purple">Vendor: {link.vendor}</Badge>
                )}
                {parseFloat(link.shipping) > 0 && (
                  <Badge colorScheme="orange">Shipping: ${link.shipping}</Badge>
                )} */}
              </Wrap>
            </Wrap>
          </Stack>
        </Td>
      </Tr>
    </>
  );
}

export default TakealotLink;
