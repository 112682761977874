import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { client } from "../../utils/shopify";

function useDailyDeals() {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);

      try {
        const productsQuery = client.graphQLClient.query((root) => {
          root.addConnection(
            "products",
            { args: { first: 10, query: "tag:'deal'" } },
            (product) => {
              product.add("title");
              product.add("tags"); // Add fields to be returned
              product.add("handle");
              product.add("priceRange", (priceRange) => {
                priceRange.add("minVariantPrice", (minVariantPrice) => {
                  minVariantPrice.add("amount");
                });
                priceRange.add("maxVariantPrice", (maxVariantPrice) => {
                  maxVariantPrice.add("amount");
                });
              });
              product.add("onlineStoreUrl");
              //add image connection
              product.addConnection(
                "images",
                { args: { first: 1 } },
                (image) => {
                  image.add("src");
                }
              );
            }
          );
        });
        // Call the send method with the custom products query
        client.graphQLClient
          .send(productsQuery)
          .then(({ model, data }) => {
            // Do something with the products
            setDeals(data.products.edges);
            setLoading(false);
          })
          .catch(setLoading(false));
      } catch (e) {
        toast({ status: "error", description: "Connection problem" });
      }
    };

    fetchData();
  }, []);

  return [deals, loading];
}

export default useDailyDeals;
