import {
  Box,
  Button,
  Heading,
  HStack,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ProductCard from "../../products/ProductCard";

function CollectionCaurosel({ data }) {
  //(data);
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState();
  const [products, setProducts] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  const page = 1;

  useEffect(() => {
    if (data.data.id) {
      axiosInstance
        .get("/collections/" + data.data.id.value + "/products", {
          params: { sort: `["created","DESC"]`, page: 1, query: "" },
        })
        .then((res) => res.data)
        .then((data) => {
          //console.log(data);
          setLoading(false);
          if (data.status === 200) {
            //setCategory(data.category);
            //setCount(data.count);
            setCollection(data.collection);
            setProducts(data.products);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [data]);

  return (
    <Stack w="100%" overflow={"hidden"}>
      <Skeleton isLoaded={!loading && collection}>
        <HStack justify={"space-between"}>
          <Heading size="md">
            {collection ? collection.title : "Loading"}
          </Heading>
          <Button variant={"outline"} borderRadius="0" size="sm">
            View More
          </Button>
        </HStack>
      </Skeleton>
      <Box w="100%">
        <Splide
          options={{
            perPage: 5,
            gap: "20px",
            lazyLoad: "nearby",
            rewind: true,
            pagination: false,
            classes: { arrows: "nav-arrow" },
            breakpoints: {
              1700: {
                perPage: parseInt(data.data.layout.xl),
              },
              1200: {
                perPage: parseInt(data.data.layout.lg),
              },
              975: {
                perPage: parseInt(data.data.layout.md),
              },
              640: {
                perPage: parseInt(data.data.layout.base),
              },
            },
          }}
        >
          {products &&
            products.map((product, index) => (
              <SplideSlide key={index}>
                <ProductCard p={product} />
              </SplideSlide>
            ))}
        </Splide>
      </Box>
    </Stack>
  );
}

export default CollectionCaurosel;
