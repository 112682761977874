import { Box } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import SingleExplore from "./SingleExplore";

export default function ExploreSection({ data }) {
  return (
    <Box>
      <Splide
        options={{
          perPage: 5,
          gap: "20px",
          lazyLoad: "nearby",
          rewind: true,
          pagination: false,
          classes: { arrows: "nav-arrow" },
          breakpoints: {
            1700: {
              perPage: parseInt(data.data.layout.xl),
            },
            1200: {
              perPage: parseInt(data.data.layout.lg),
            },
            975: {
              perPage: parseInt(data.data.layout.md),
            },
            640: {
              perPage: parseInt(data.data.layout.base),
            },
          },
        }}
      >
        {data &&
          data.data.cards.map((card, index) => (
            <SplideSlide key={index}>
              <SingleExplore card={card} />
            </SplideSlide>
          ))}
      </Splide>
    </Box>
  );
}
