import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "throttle-debounce";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axiosInstance from "../../../../../utils/axios";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useLinks } from "../../../../../utils/contexts/useLinks";

function CollectionGridColumn({ columnIndex, rowIndex, templateIndex }) {
  const data = useSelector((state) => state.pagedata.value);

  const { linksLoading, loadLinks } = useLinks();

  const dispatch = useDispatch();

  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchItems("", setOptions);
  }, []);

  const debounceFunc = debounce(1000, false, (query) => {
    searchItems(query, setOptions);
  });

  const searchItems = async (inputValue, callback) => {
    console.log(inputValue);
    setLoading(true);
    axiosInstance
      .get("/collections", {
        params: { page: 1, title: inputValue },
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);

        if (data?.collections) {
          setOptions(
            data.collections.map((col) => {
              return {
                label: col.title,
                value: col.id,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Stack p="2">
      <HStack align="center">
        <Heading
          size="xs"
          borderBottomWidth={"1px"}
          borderBottomColor={"gray.100"}
          flexGrow="1"
        >
          Collection Grid
        </Heading>
        {/* <IconButton size="xs" children={<BiPlus />} onClick={addCard} /> */}
        <IconButton
          size="xs"
          children={<BiTrash />}
          onClick={() => {
            //removeSection();
          }}
        />
      </HStack>
      <Select
        value={
          data &&
          data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
            .id
        }
        onChange={(e) => {
          let rows = [...data.data.widgets];
          let columns = [...rows[rowIndex].columns];
          let templates = [...rows[rowIndex].columns[columnIndex]];
          templates[templateIndex] = {
            ...templates[templateIndex],
            data: {
              ...rows[rowIndex].columns[columnIndex][templateIndex].data,
              id: e,
            },
          };
          columns[columnIndex] = templates;
          rows[rowIndex] = { ...rows[rowIndex], columns: columns };
          dispatch(setPageData({ ...data.data, widgets: rows }));
        }}
        // loadOptions={(inputValue, setOptions) =>
        //   searchItems(inputValue, setOptions)
        // }
        isLoading={loading}
        //onInputChange={this.handleInputChange}
        onInputChange={(e) => debounceFunc(e)}
        isSearchable={true}
        options={options}
      />
    </Stack>
  );
}

export default CollectionGridColumn;
