import React from "react";
import { Grid, Stack, Heading, Image, Link, Box, Text } from "@chakra-ui/react";

function DetailedCard({ data }) {
  return (
    <Grid templateColumns={data.data.layout} gap="10px">
      {data.data &&
        data.data.cards.map((card, index) => (
          <Stack
            shadow="md"
            bg="white"
            overflow="hidden"
            transition="all 0.3s ease-in-out"
            borderRadius="3px"
            borderWidth="2px"
            borderColor="gray.50"
            align="center"
            key={index}
            _hover={{ cursor: "pointer", shadow: "lg" }}
          >
            <Image
              src={card.imageUrl}
              alt={card.label}
              w="100%"
              maxH="200px"
              objectFit="cover"
              h="auto"
              borderTopRadius={"3px"}
            />

            <Stack p="3" textAlign={"center"} spacing="20px">
              <Stack>
                <Heading size="sm" textTransform={"capitalize"}>
                  {card.label}
                </Heading>
                <Text>{card.description}</Text>
              </Stack>

              <Link href="#">
                <Text color="red.600" textDecoration="underscore">
                  {card.linkLabel}
                </Text>
              </Link>
            </Stack>
          </Stack>
        ))}
    </Grid>
  );
}

export default DetailedCard;
