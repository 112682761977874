import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Badge, Heading, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { BsBasket } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import axiosInstance from "../../../utils/axios";
import TakealotOption from "./TakealotOption";
import useWhatsApp from "../../../utils/useWhatsApp";
import { useParams } from "react-router";

function TakealotProduct({ product, fees }) {
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(null);
  const [variantsloading, setVariantsLoading] = useState(false);
  const calculate = () => {
    return Math.ceil(
      (product.product_views?.buybox_summary.app_prices[0] // zar to usd
        ? parseFloat(product.product_views?.buybox_summary.app_prices[0])
        : 0) /
        (fees.zar_rate * 100) +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.sa_zim +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.sorting +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.ads +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.transport +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.mfi +
        (
          product.product_views?.buybox_summary.app_prices[0] /
          (fees.zar_rate * 100)
        ).toFixed() *
          fees.wages
    );
  };

  const { phone } = useParams();

  useEffect(() => {
    if (
      product &&
      product?.product_views &&
      product?.product_views.variant_summary.has_more_colours
    ) {
      setVariantsLoading(true);
      axiosInstance
        .get(
          "https://api.takealot.com/rest/v-1-10-0/product-details/PLID" +
            product.product_views.core.id +
            "?platform=desktop"
        )
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          if (data?.variants) {
            setOptions(data.variants.selectors);
          }
          setVariantsLoading(false);
        })
        .catch((err) => {
          setVariantsLoading(false);
          console.log(err);
        });
    }
  }, [product]);

  const {
    sendMedia,
    loading: loadingSendMedia,
    error: errorSendMedia,
  } = useWhatsApp();

  return (
    <HStack
      cursor="pointer"
      p="2"
      //   my="2px"
      bg={useColorModeValue("gray.200", "gray.900")}
      borderLeftWidth="2px"
      transition="all 0.15s ease-in-out"
      _hover={{
        bg: useColorModeValue("gray.100", "gray.800"),
        borderLeftColor: "red.500",
        borderLeftWidth: "2px",
      }}
    >
      {product?.product_views &&
        !product.product_views.variant_summary.has_more_colours && (
          <Image
            width="80px"
            height="80px"
            borderRadius="5px"
            src={
              product?.product_views?.gallery?.images.length > 0
                ? product.product_views?.gallery?.images[0].replace(
                    "{size}",
                    "fb"
                  )
                : ""
            }
          />
        )}

      <Stack flexGrow="1" color={useColorModeValue("gray.700", "gray.50")}>
        <Heading size="xs" color={useColorModeValue("gray.700", "gray.50")}>
          {product.product_views?.core?.title}
        </Heading>
        {product &&
          product?.product_views &&
          !product?.product_views.variant_summary.has_more_colours && (
            <>
              <Wrap>
                <Text fontSize="sm">${calculate()}</Text>
                {product?.product_views?.stock_availability_summary?.distribution_centres?.map(
                  (center) => (
                    <Badge key={center.id} colorScheme="blue">
                      {center.text}
                    </Badge>
                  )
                )}
              </Wrap>
              <HStack align="end" justify="end" w="100%">
                <IconButton
                  colorScheme="blue"
                  bg="blue.700"
                  size="sm"
                  children={<BsBasket />}
                />
                <IconButton
                  colorScheme="red"
                  size="sm"
                  isLoading={loadingSendMedia}
                  onClick={() => {
                    if (
                      product &&
                      product?.product_views?.gallery?.images.length > 0
                    ) {
                      sendMedia({
                        phone: phone,
                        mediaurl:
                          product?.product_views?.gallery?.images[0].replace(
                            "{size}",
                            "zoom"
                          ),
                        caption:
                          "*" +
                          product?.product_views?.core?.title +
                          "*\n\nUS$" +
                          calculate() +
                          "\n_ID#PID" +
                          product?.product_views?.core?.id +
                          "_",
                        filename: "image.jpg",
                        callback: () => console.log("sent"),
                      });
                    }
                  }}
                  children={<BiMailSend />}
                />
              </HStack>
            </>
          )}
        {variantsloading && <HStack>Loading Options...</HStack>}
        {fees &&
          product &&
          options.map((option, index) => (
            <TakealotOption
              option={option}
              product={product}
              key={index}
              fees={fees}
            />
          ))}
      </Stack>
    </HStack>
  );
}

export default TakealotProduct;
