import { useColorModeValue } from "@chakra-ui/color-mode";
import { Grid } from "@chakra-ui/layout";
import React from "react";
import LoginLogic from "./LoginLogic";
import LoginProducts from "./LoginProducts";

function Login() {
  return (
    <Grid
      templateColumns={{ base: "100%", md: "4fr 4fr" }}
      h={{ base: "auto", md: "100%" }}
      gap={"10px"}
      bg={useColorModeValue("gray.50", "gray.900")}
    >
      <LoginLogic />
      <LoginProducts />
    </Grid>
  );
}

export default Login;
