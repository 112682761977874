import { useColorModeValue } from "@chakra-ui/color-mode";
import { Circle, Stack, Text } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { BiUser } from "react-icons/bi";
import { BsCart, BsTruck, BsListNested } from "react-icons/bs";
import React from "react";
import ProfileInfo from "./ProfileInfo";
import ProfileTags from "./ProfileTags";
import SearchContainer from "../search/SearchContainer";
import Links from "../links/Links";
import SendMedia from "../media/SendMedia";

function ChatBottomHalf({ tags, setTags }) {
  return (
    <Tabs isFitted>
      <TabList
        position="sticky"
        top="0"
        zIndex="2"
        bg={useColorModeValue("gray.100", "gray.800")}
      >
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Text>Shop</Text>
        </Tab>

        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Text>Links</Text>
        </Tab>
        <Tab
          color={useColorModeValue("gray.700", "gray.400")}
          _selected={{
            bg: useColorModeValue("gray.300", "gray.700"),
            borderColor: useColorModeValue("gray.300", "gray.500"),
          }}
        >
          <Text>Media</Text>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SearchContainer tags={tags} setTags={setTags} />
        </TabPanel>
        <TabPanel>
          <Links />
        </TabPanel>
        <TabPanel>
          <SendMedia />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default ChatBottomHalf;
