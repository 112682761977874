import { Box, Button, Center, CircularProgress, CircularProgressLabel, Stack, Text, toast, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
  } from '@chakra-ui/react'
import { BsFillPatchCheckFill } from 'react-icons/bs';
import axiosInstance from '../../../utils/axios';


function RunNow(link) {


  const toast = useToast();  
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(0);

  const [takealot, setTakealot] = useState(link.link);
  const navigate = useNavigate();
  useState(() =>{

    setTakealot(link.link);

  },[link.link])
  
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [hasMore, setHasMore] = useState(false);


  const resetEveryThing = () => {
      setTotal(0);
      setHasMore(true);
      console.log('Reset Called');
      setLoaded(0);

      startRunning();

  }

  useEffect(() => {

    if(loaded < total){
        //setTimeout(function () {
        startRunning();
        //}, 2000);
    }

  },[loaded]);
  const startRunning = async() => {

    setLoading(true);
  

    axiosInstance.get('/sync/runlink/' + takealot.tid)
    .then(res => res.data)
    .then(data => {
        if(data.count){
            //check if hasMore

            setTotal(data.total);
            if(data.hasMore){
               
                let init = loaded;

                console.log("Init " + loaded);
                console.log("Received " + data.count);
                setLoaded(init + data.count);
               
                
            }else{
                setLoaded(data.total);
                setLoading(false);
            }
        }else{
            console.log(data)
            setLoaded(loaded + data.count);
            setLoading(false);
            toast({"description": "Something went wrong", "status": "error"});
        }
    }).catch((error) => {
        console.log(error)
    });


  }
  return (
    <>
     <Button size="sm" onClick={onOpen}> Run Now </Button>
     <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          
          {!loading && <ModalCloseButton />}
          <ModalBody py="30px">
              <Stack spacing={"30px"}>
                <Center with="200px">
                    <Stack>
                        <CircularProgress size='200px' thickness='2px' value={total && loaded ? ((loaded/total)*100).toFixed() : 0} color='green.400'>
                        <CircularProgressLabel color={useColorModeValue("gray.500","gray.50")}>
                            <StatGroup>
                            <Stat>
                                <StatLabel>Products</StatLabel>
                                <StatNumber>{loaded ? loaded : "--"}</StatNumber>
                                <StatHelpText>
                                <StatArrow type='increase' />
                                  {total && loaded ? ((loaded/total)*100).toFixed() : "--"}%
                                </StatHelpText>
                            </Stat>
                            </StatGroup>
{/* <Box>
<BsFillPatchCheckFill color="green" />
</Box> */}
                         
                        </CircularProgressLabel>
                        </CircularProgress>
                        {!total && <Button colorScheme="red" onClick={resetEveryThing} isLoading={loading}>{total ? "Re-run Now" : "Run Now"}</Button> }
                        
                    </Stack>
                    
                </Center>
                <Text color={"blue.500"} align="center" cursor="pointer" onClick={() => {!loading && window.open(takealot.url,'_blank')}}>{takealot.url}</Text>
              </Stack>
              
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RunNow