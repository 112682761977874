import React, { useState, useEffect } from "react";
import {
  Center,
  Container,
  Grid,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/progress";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Skeleton } from "@chakra-ui/skeleton";
import axiosInstance from "../../utils/axios";
import { useNavigate } from "react-router";

function HomeGrid() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [data, setData] = useState();
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/sundry/products")
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.collections) {
          setData(data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const redirect = (to) => {
    navigate.push(to);
  };

  return (
    <Grid
      p={0}
      bg={useColorModeValue("gray.900", "#12131b")}
      borderRadius="md"
      shadow="lg"
      color={"gray.50"}
      templateColumns="1fr 1fr 1fr 1fr"
    >
      <Stack
        borderRightWidth="1px"
        borderStyle="solid"
        borderColor="gray.600"
        p={3}
        transition="all 0.35s ease-in-out"
        cursor="pointer"
        _hover={{
          bg: "blue.900",
          borderTopLeftRadius: "md",
          borderBottomLeftRadius: "md",
        }}
        onClick={() => navigate("/app/shop/products")}
      >
        <Heading size="xs" opacity="0.7">
          Products
        </Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="1.2em" fontWeight="bold">
            {data?.products || 0}
          </Text>
        </Skeleton>
      </Stack>
      <Stack
        borderRightWidth="1px"
        borderStyle="solid"
        borderColor="gray.600"
        p={3}
        transition="all 0.35s ease-in-out"
        cursor="pointer"
        _hover={{
          bg: "blue.900",
        }}
        onClick={() => navigate("/app/shop/collections")}
      >
        <Heading size="xs" opacity="0.7">
          Collections
        </Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="1.2em" fontWeight="bold">
            {data?.collections || 0}
          </Text>
        </Skeleton>
      </Stack>
      <Stack
        borderRightWidth="1px"
        borderStyle="solid"
        borderColor="gray.600"
        position="relative"
        spacing="0"
        transition="all 0.35s ease-in-out"
        cursor="pointer"
        _hover={{
          bg: "blue.900",
        }}
      >
        <Progress
          position="absolute"
          colorScheme="blue"
          hasStripe
          opacity="0.7"
          //height="32px"
          value={data?.sync || 0}
          w="100%"
          left="0"
          right="0"
          height="100%"
          bg={"transparent"}
          isIndeterminate={false}
        />
        <Stack p={3} zIndex="2">
          <Heading size="xs" zIndex="2">
            Synced Products
          </Heading>
          <Skeleton isLoaded={!loading}>
            <Text fontSize="1.2em" fontWeight="bold" zIndex="2">
              {(data?.sync ? data.sync : "--") + "%" || 0}
            </Text>
          </Skeleton>
        </Stack>
      </Stack>
      <Stack p={3}>
        <Heading size="xs" opacity="0.7">
          New Products
        </Heading>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="1.2em" fontWeight="bold">
            {data?.new || 0}
          </Text>
        </Skeleton>
      </Stack>
    </Grid>
  );
}

export default HomeGrid;
