import React from "react";
import { Route, Routes } from "react-router-dom";
import ProcurementList from "./ProcurementList";

function ProcurementEntry() {
  return (
    <Routes>
      <Route path="/" element={<ProcurementList />} />
      {/* <Route path="/new" element={<CreateCollection />} />
      <Route path="/:id" element={<EditCollection />} /> */}
    </Routes>
  );
}

export default ProcurementEntry;
