import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import AppRoutes from "./utils/AppRoutes";
import { ProtectedRoute } from "./utils/ProtectedRoute";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/app" replace={true} />} />
      {AppRoutes.map((props, index) =>
        ProtectedRoute(props.element, props.path, index, props)
      )}
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
