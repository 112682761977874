import { Heading, HStack, Image, Stack } from "@chakra-ui/react";
import React from "react";

export default function IconCards({ data }) {
  return (
    <HStack w="100%" overflowX={"scroll"}>
      {data.data &&
        data.data.cards.map((card, index) => (
          <HStack
            align={"center"}
            key={index}
            bg="white"
            py="2"
            px="3"
            w="100%"
            minW="200px"
            borderRadius={"4px"}
            shadow="sm"
            justify={"start"}
            cursor="pointer"
          >
            <HStack>
              <Image
                src={card.imageUrl}
                maxW="40px"
                w="100%"
                h="auto"
                maxH="40px"
                shadow="sm"
              />
              <Heading fontSize="xs">{card.heading}</Heading>
            </HStack>
          </HStack>
        ))}
    </HStack>
  );
}
