import React from "react";
import { Route, Routes } from "react-router-dom";
import PageEditor from "./editor/PageEditor";
import PageEntry from "./page/PageEntry";
import PagesList from "./PagesList";

function PagesEntry() {
  return (
    <Routes>
      <Route path="/" element={<PagesList />} />
      <Route path="/:id" element={<PageEntry />} />
      <Route path="/:id/editor" element={<PageEditor />} />
      {/* <Route path="/new" element={<CreateCollection />} />
  <Route path="/:id" element={<EditCollection />} /> */}
    </Routes>
  );
}

export default PagesEntry;
