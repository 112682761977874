import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Box, Flex, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import React, { useEffect, useState } from "react";
import { BiSort } from "react-icons/bi";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import { debounce } from "throttle-debounce";
import PagesListItem from "./PagesListItem";
import { client } from "../../../utils/shopify";
import UploadPages from "./UploadPages";

function PagesList() {
  const [shopifyPages, setShopifyPages] = useState([]);

  const [selectedPages, setSelectedPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const debounceFunc = debounce(1000, false, (query) => {
    setTitle(query);
  });

  useEffect(() => {
    setLoading(true);
    setSelectedPages([]);

    // try {
    //   const pagesQuery = client.graphQLClient.query((root) => {
    //     root.addConnection(
    //       "pages",
    //       { args: { first: 20, reverse: true } },
    //       (page) => {
    //         page.add("title");
    //         page.add("handle");
    //         page.add("onlineStoreUrl");
    //         page.add("bodySummary");
    //         page.add("seo", (seo) => {
    //           seo.add("title");
    //           seo.add("description");
    //         });
    //       }
    //     );
    //   });
    //   // Call the send method with the custom products query
    //   client.graphQLClient
    //     .send(pagesQuery)
    //     .then(({ model, data }) => {
    //       // Do something with the products
    //       if (model?.pages) {
    //         setShopifyPages(model.pages);
    //       }
    //       console.log(data);
    //       console.log(model);
    //       //setDeals(data.products.edges);
    //       setLoading(false);
    //     })
    //     .catch(setLoading(false));
    // } catch (e) {
    //   //   toast({ status: "error", description: "Connection problem" });
    // }
    axiosInstance
      .get("/pages", { params: { page: page, title: title } })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.pages) {
          setShopifyPages(data.data);
          setPages(data.pages);
          setPage(data.page);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, title]);

  return (
    <Stack p="5">
      {/* The Heading */}
      <HStack justify="space-between">
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Pages
        </Heading>

        <UploadPages />
      </HStack>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        borderRadius="md"
        shadow="md"
        overflow="hidden"
      >
        <Stack position="relative">
          <Flex p="3" direction={{ base: "column", md: "row" }}>
            <InputGroup m="1" color={useColorModeValue("gray.700", "gray.50")}>
              <InputLeftElement children={<SearchIcon />} />
              <Input
                placeholder="Filter pages"
                onChange={(e) => {
                  debounceFunc(e.target.value);
                }}
              />
            </InputGroup>
            <HStack m="1">
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Type
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup>
                    <MenuGroup title="Sort by">
                      <MenuItem mt={5}>
                        <Radio value="1" colorScheme="red" w="100%">
                          Automated
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="2" colorScheme="red" w="100%">
                          Manual
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Sort
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup>
                    <MenuGroup title="Sort by">
                      <MenuItem mt={5}>
                        <Radio value="1" colorScheme="red" w="100%">
                          title A-Z
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="2" colorScheme="red" w="100%">
                          title Z-A
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="3" colorScheme="red" w="100%">
                          Updated (oldest first)
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="4" colorScheme="red" w="100%">
                          Updated (newest first)
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
          {loading && (
            <HStack
              p={3}
              bg={"blue.50"}
              position="absolute"
              w="100%"
              top="3em"
              zIndex="1"
            >
              <Spinner size="sm" color="blue.500" />
              <Text color="blue.200">Loading pages</Text>
            </HStack>
          )}
        </Stack>
        <Table variant="simple" position="relative">
          <Thead position="sticky" top="0">
            <Tr>
              <Th col="1" w="20px">
                <Checkbox
                  isChecked={
                    shopifyPages.length > 0 &&
                    shopifyPages.length === selectedPages.length
                  }
                  onChange={(e) => {
                    let selections = [];
                    shopifyPages.forEach((page) => {
                      if (e.target.checked) {
                        selections.push(page.id);
                      }
                    });
                    setSelectedPages(selections);
                  }}
                />
              </Th>
              <Th w="40px" d={{ base: "none", md: "table-cell" }}></Th>
              <Th>Title</Th>
              <Th>Slug</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shopifyPages.map((page) => (
              <PagesListItem
                key={page.id}
                page={page}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
              />
            ))}
          </Tbody>
        </Table>
      </Stack>
      {/* Collections Filters */}

      {/* Collections List */}
    </Stack>
  );
}

export default PagesList;
