import { SearchIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { BiSort } from "react-icons/bi";

function ProcurementTable() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  return (
    <Stack
      bg={useColorModeValue("white", "gray.700")}
      borderRadius="md"
      shadow="md"
      color={useColorModeValue("gray.700", "gray.50")}
      //overflow={"hidden"}
    >
      <HStack>
        <HStack
          borderBottomColor={useColorModeValue("gray.200", "gray.600")}
          borderBottomWidth="1px"
          w="100%"
        >
          <Text
            cursor="pointer"
            py="2"
            px="5"
            borderBottomWidth={"2px"}
            borderColor={"green.200"}
            _hover={{
              borderColor: useColorModeValue("gray.200", "gray.100"),
            }}
          >
            All
          </Text>
          <Text
            cursor="pointer"
            py="2"
            px="5"
            borderBottomWidth={"2px"}
            borderColor={"transparent"}
            _hover={{
              borderColor: useColorModeValue("gray.200", "gray.100"),
            }}
          >
            Unfullfilled
          </Text>
          <Text
            cursor="pointer"
            py="2"
            px="5"
            borderBottomWidth={"2px"}
            borderColor={"transparent"}
            _hover={{
              borderColor: useColorModeValue("gray.200", "gray.100"),
            }}
          >
            Unpaid
          </Text>
          <Text
            cursor="pointer"
            py="2"
            px="5"
            borderBottomWidth={"2px"}
            borderColor={"transparent"}
            _hover={{
              borderColor: useColorModeValue("gray.200", "gray.100"),
            }}
          >
            Cancelled
          </Text>
          <Menu>
            <MenuButton
              px="5"
              borderBottomWidth={"2px"}
              borderColor={"transparent"}
              _hover={{
                borderColor: useColorModeValue("gray.200", "gray.100"),
              }}
            >
              <Text p="2">Location: All</Text>
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Text p="2">All</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Pending Procurement</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Purchased</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Awaiting Supplier Delivery</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Arrived Initial Sorting</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">in Transit - Final Sorting</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Final Sorting</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Out For Delivery</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Delivered</Text>
              </MenuItem>
              <MenuItem>
                <Text p="2">Returned Sorting</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>
      <Stack borderRadius="md" shadow="md" overFlow="hidden">
        <Stack position="relative">
          <Flex p="3" direction={{ base: "column", md: "row" }}>
            <InputGroup m="1" color={useColorModeValue("gray.700", "gray.50")}>
              <InputLeftElement children={<SearchIcon />} />
              <Input
                placeholder="Filter order items"
                // onChange={(e) => {
                //   debounceFunc(e.target.value);
                // }}
              />
            </InputGroup>
            <HStack m="1">
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Type
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup>
                    <MenuGroup title="Sort by">
                      <MenuItem mt={5}>
                        <Radio value="1" colorScheme="red" w="100%">
                          Local
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="2" colorScheme="red" w="100%">
                          Takealot
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Sort
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup>
                    <MenuGroup title="Sort by">
                      <MenuItem>
                        <Radio value="4" colorScheme="red" w="100%">
                          Updated (newest first)
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
          {loading && (
            <HStack
              p={3}
              bg={"blue.50"}
              position="absolute"
              w="100%"
              top="3em"
              zIndex="1"
            >
              <Spinner size="sm" color="blue.500" />
              <Text color="blue.200">Loading order items</Text>
            </HStack>
          )}
        </Stack>
        <Table variant="simple" position="relative">
          <Thead position="sticky" top="0">
            <Tr>
              <Th col="1" w="20px">
                <Checkbox
                //   isChecked={
                //     shopifyPages.length > 0 &&
                //     shopifyPages.length === selectedPages.length
                //   }
                //   onChange={(e) => {
                //     let selections = [];
                //     shopifyPages.forEach((page) => {
                //       if (e.target.checked) {
                //         selections.push(page.id);
                //       }
                //     });
                //     setSelectedPages(selections);
                //   }}
                />
              </Th>
              <Th>Order</Th>
              <Th>Barcode</Th>
              <Th>Date</Th>
              <Th>Title</Th>
              <Th>Cost</Th>
              <Th>Selling Price</Th>
              <Th>Order State</Th>
              <Th>Procurement State</Th>
              <Th>Location</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr
              _hover={{ bg: useColorModeValue("gray.50", "gray.800") }}
              cursor={"pointer"}
            >
              <Td>
                <Checkbox
                //   isChecked={
                //     shopifyPages.length > 0 &&
                //     shopifyPages.length === selectedPages.length
                //   }
                //   onChange={(e) => {
                //     let selections = [];
                //     shopifyPages.forEach((page) => {
                //       if (e.target.checked) {
                //         selections.push(page.id);
                //       }
                //     });
                //     setSelectedPages(selections);
                //   }}
                />
              </Td>
              <Td fontSize="xs">#3903</Td>
              <Td fontSize="xs">2023212343</Td>

              <Td fontSize="xs">2021/12/13</Td>
              <Td fontSize="xs" maxW="200px">
                Wahl Cutek Advanced Professional Ceramic Hair Straightener
              </Td>
              <Td fontSize="xs">$59.00</Td>
              <Td fontSize="xs">$89.00</Td>
              <Td fontSize="xs">
                <Badge colorScheme="green" fontSize="10px">
                  Confirmed
                </Badge>
              </Td>
              <Td fontSize="xs">
                <Badge colorScheme="green" fontSize="10px">
                  Purchased
                </Badge>
              </Td>
              <Td fontSize="xs">inTransit - Final Sorting</Td>
            </Tr>
            <Tr
              _hover={{ bg: useColorModeValue("gray.50", "gray.800") }}
              cursor={"pointer"}
            >
              <Td>
                <Checkbox
                //   isChecked={
                //     shopifyPages.length > 0 &&
                //     shopifyPages.length === selectedPages.length
                //   }
                //   onChange={(e) => {
                //     let selections = [];
                //     shopifyPages.forEach((page) => {
                //       if (e.target.checked) {
                //         selections.push(page.id);
                //       }
                //     });
                //     setSelectedPages(selections);
                //   }}
                />
              </Td>
              <Td fontSize="xs">#3902</Td>
              <Td fontSize="xs">2023212342</Td>

              <Td fontSize="xs">2021/12/13</Td>
              <Td fontSize="xs" maxW="200px">
                Side-by-Side Frost Free Fridge with Water and Ice Dispenser
              </Td>
              <Td fontSize="xs">$1,932.00</Td>
              <Td fontSize="xs">$2,132.00</Td>
              <Td fontSize="xs">
                <Badge colorScheme="green" fontSize="10px">
                  Confirmed
                </Badge>
              </Td>
              <Td fontSize="xs">
                <Badge colorScheme="blue" cursor="pointer">
                  Add To Cart
                </Badge>
              </Td>
              <Td fontSize="xs">N/A</Td>
            </Tr>
            <Tr
              _hover={{ bg: useColorModeValue("gray.50", "gray.800") }}
              cursor={"pointer"}
            >
              <Td>
                <Checkbox
                //   isChecked={
                //     shopifyPages.length > 0 &&
                //     shopifyPages.length === selectedPages.length
                //   }
                //   onChange={(e) => {
                //     let selections = [];
                //     shopifyPages.forEach((page) => {
                //       if (e.target.checked) {
                //         selections.push(page.id);
                //       }
                //     });
                //     setSelectedPages(selections);
                //   }}
                />
              </Td>
              <Td fontSize="xs">#3902</Td>
              <Td fontSize="xs">2023212341</Td>

              <Td fontSize="xs">2021/12/13</Td>
              <Td fontSize="xs" maxW="200px">
                Side-by-Side Frost Free Fridge with Water and Ice Dispenser
              </Td>
              <Td fontSize="xs">$1,932.00</Td>
              <Td fontSize="xs">$2,132.00</Td>
              <Td fontSize="xs">
                <Badge colorScheme="green" fontSize="10px">
                  Confirmed
                </Badge>
              </Td>
              <Td fontSize="xs">
                <Badge colorScheme="red" cursor="pointer">
                  Remove From Cart
                </Badge>
              </Td>
              <Td fontSize="xs">N/A</Td>
            </Tr>
            {/* {shopifyPages.map((page) => (
              <PagesListItem
                key={page.id}
                page={page}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
              />
            ))} */}
          </Tbody>
        </Table>
      </Stack>
    </Stack>
  );
}

export default ProcurementTable;
