import { Heading, HStack, Stack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router";
import Loading from "../../components/Loading";
import { FaShopify } from "react-icons/fa";
import ShopHome from "./ShopHome";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { IconButton } from "@chakra-ui/button";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import CollectionsList from "./collections/CollectionsList";
import CollectionsEntry from "./collections/CollectionsEntry";
import EditFees from "./fees/EditFees";
import ProductsEntry from "./collections/ProductsEntry";
import PagesEntry from "./pages/PagesEntry";
import LinksEntry from "./links/LinksEntry";
import NavigationEntry from "./navigation/NavigationEntry";

function ShopifyFrame() {
  const [page, setPage] = useState();
  const location = useParams();
  console.log();
  useEffect(() => {
    if (location["*"]) {
      let array = [];
      location["*"].split("/").filter((direction) => {
        array.push(direction.charAt(0).toUpperCase() + direction.slice(1));
        return direction;
      });
      setPage(array[0]);
    } else {
      setPage("Home");
    }
  }, [location]);
  return (
    <Stack
      w="100%"
      h="100%"
      overflowY="scroll"
      p="5"
      spacing="30px"
      position="relative"
    >
      <HStack px={5} position="sticky" top="0" zIndex="3" bg={useColorModeValue("rgba(255,255,255,0.7)", "rgba(0,0,0,0.7)")} py="2" borderRadius={5}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="solid"
            color="gray.50"
            colorScheme="blue"
            bg={"gray.700"}
            _hover={{ bg: "gray.800" }}
          />
          <MenuList color={useColorModeValue("gray.700", "gray.50")} zIndex="3">
            <Link to="/app/shop">
              <MenuItem py="3">Home</MenuItem>
            </Link>
            <Link to="/app/shop/products">
              <MenuItem py="3">Products </MenuItem>
            </Link>
            <Link to="/app/shop/pages">
              <MenuItem py="3">Pages </MenuItem>
            </Link>
            <Link to="/app/shop/navigation">
              <MenuItem py="3">Navigation </MenuItem>
            </Link>
            <Link to="/app/shop/collections">
              <MenuItem py="3">Collections </MenuItem>
            </Link>
            <Link to="/app/shop/links">
              <MenuItem py="3">Links </MenuItem>
            </Link>
            <Link to="/app/shop/fees">
              <MenuItem py="3">Fees </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </HStack>

      <Stack h="100%">
        <Routes>
          <Route index element={<ShopHome />} />
          <Route path="/products/*" element={<ProductsEntry />} />
          <Route path="/pages/*" element={<PagesEntry />} />
          <Route path="/collections/*" element={<CollectionsEntry />} />
          <Route path="/fees" element={<EditFees />} />
          <Route path="/links" element={<LinksEntry />} />
          <Route path="/navigation" element={<NavigationEntry />} />
        </Routes>
      </Stack>
    </Stack>
  );
}

export default ShopifyFrame;
