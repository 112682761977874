import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/input";
import { Center, Heading, Stack, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";

function CollectionSEO({ collection, setCollection }) {
  const [handle, setHandle] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    setHandle(collection.handle);
  }, [collection]);

  const submitCollection = () => {
    setLoading(true);
    axiosInstance
      .put("/collections/" + collection.id, { handle: handle })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.error) {
          toast({ description: data.error, status: "error", title: "Error" });
        } else {
          toast({
            description: "Collection handle updated",
            status: "success",
            title: "Success",
          });
          setCollection({ ...collection, handle: handle });
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={5}
      borderRadius="md"
      shadow="md"
      spacing="25px"
    >
      <Heading size="sm" color={useColorModeValue("gray.700", "gray.50")}>
        Search Engine Optimisation
      </Heading>

      <Stack spacing="2px">
        <Heading
          size="md"
          fontSize="1.3em"
          mb={0}
          pb={0}
          fontWeight="regular"
          color={useColorModeValue("blue.500", "cyan.200")}
        >
          {collection.title}
        </Heading>
        <Text
          mt="0"
          color="green.600"
          fontSize="sm"
        >{`https://${process.env.REACT_APP_SHOPIFY_DOMAIN}/collections/${collection.handle}`}</Text>
      </Stack>

      <Stack color={useColorModeValue("gray.700", "gray.50")}>
        <Text>URL and handle</Text>
        <InputGroup size="sm" borderRadius="md">
          <InputLeftAddon
            display={{ base: "none", md: "flex" }}
            children={`https://${process.env.REACT_APP_SHOPIFY_DOMAIN}/collections/`}
          />
          <Input
            placeholder="handle"
            pr="2rem"
            value={handle}
            onChange={(e) => {
              setHandle(e.target.value);
            }}
            borderRadius="md"
          />
          <InputRightElement width="3.8rem">
            <Button
              isLoading={loading}
              h="1.75rem"
              size="sm"
              onClick={submitCollection}
            >
              {"Save"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Stack>
    </Stack>
  );
}

export default CollectionSEO;
