import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { Collapse } from "@chakra-ui/transition";
import { Picker } from "emoji-mart";
import React, { useState, useEffect } from "react";
import { BsEmojiSmile, BsReply } from "react-icons/bs";
import { Button } from "@chakra-ui/button";
import { MdSend } from "react-icons/md";
import TextareaAutosize from "react-textarea-autosize";
import { useToast } from "@chakra-ui/toast";
import { useSelector, useDispatch } from "react-redux";
import Attach from "./Attach";
import Editor from "screenshot-editor-test";
import ReactPlayer from "react-player";
import Player from "react-howler-player";
import useWhatsApp from "../../../utils/useWhatsApp";
import { setReply } from "../../../utils/redux/replySlice";
import { FaFileVideo } from "react-icons/fa";

function SendBox({ phone, addMessage }) {
  const [type, setType] = useState("chat");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const toast = useToast();
  let reply = useSelector((state) => state.reply.value);
  const dispatch = useDispatch();
  const [quotedMessage, setQuotedMessage] = useState();

  useEffect(() => {
    if (reply) {
      setQuotedMessage(JSON.parse(reply.type_data));
    } else {
      setQuotedMessage(null);
    }
  }, [reply]);

  const colorMode = useColorModeValue(false, true);

  const url =
    process.env.REACT_APP_INSTANCE_URL ||
    "https://api.chat-api.com/instance334898/";
  const token = process.env.REACT_APP_INSTANCE_TOKEN || "clnmpczxu5yowz3a";

  const {
    sendMedia,
    sendText,
    loading: loadingSend,
    error: errorSendMedia,
  } = useWhatsApp();

  const callback = (res) => {
    console.log(res);
    if (res === "success") {
      setMessageText("");
      setOpenEmoji(false);
      setFile(null);
      setQuotedMessage(null);
      dispatch(setReply(null));
    }
  };

  const sendMessage = async () => {
    let res = "";
    if (!file) {
      if (messageText.length > 0) {
        res = await sendText({
          phone: phone,
          message: messageText,
          quotedMsgId: quotedMessage ? quotedMessage.id : "",
          callback: callback,
        });
      } else {
        toast({
          description: "Message is empty",
          status: "error",
        });
        return;
      }
    } else {
      sendMedia({
        phone: phone,
        mediaurl: file?.base64,
        caption: messageText,
        filename: file?.type,
        callback: callback,
        quotedMsgId: quotedMessage ? quotedMessage.id : "",
      });
    }

    console.log(res);

    // axiosInstance
    //   .post(
    //     url + "sendMessage?token=" + token,
    //     {
    //       chatId: phone,
    //       body: messageText,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     setLoading(false);
    //     setMessageText("");
    //     setFile(null);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast({
    //       status: "error",
    //       title: "Sending Failed",
    //       description: "Something went wrong",
    //       position: "bottom-right",
    //     });
    //     setLoading(false);
    //   });
  };

  return (
    <Box
      position="sticky"
      bottom="0"
      // bg="white"
      zIndex="2"
      bg={useColorModeValue("gray.200", "gray.800")}
    >
      <Stack py="3" px="3">
        <HStack align="start" color={useColorModeValue("gray.600", "gray.50")}>
          {quotedMessage && (
            <Stack
              bg={colorMode ? "gray.900" : "gray.100"}
              p="2"
              borderRadius="5px"
              borderLeftWidth="4px"
              borderLeftColor={quotedMessage.from_me ? "blue.500" : "red.500"}
              spacing="10px"
              display="block"
              minW="300px"
            >
              <HStack w="100%" spacing="10px">
                <BsReply /> <Text fontSize="xs">Reply</Text>{" "}
                <Button
                  colorScheme="red"
                  ml="5"
                  onClick={() => dispatch(setReply(null))}
                  style={{ marginLeft: "auto" }}
                  size="xs"
                >
                  Cancel
                </Button>
              </HStack>
              {quotedMessage.type === "image" && (
                <Image
                  maxW="80px"
                  borderRadius="5px"
                  src={quotedMessage.body}
                  fallbackSrc=""
                />
              )}
              {quotedMessage.type === "audio" && (
                <Player
                  style={{ boxShadow: "none" }}
                  src={[quotedMessage.body]}
                  isDark={colorMode}
                />
              )}

              {quotedMessage.type === "chat" && (
                <Text fontSize="0.7em">{quotedMessage.body}</Text>
              )}
              {quotedMessage.type === "video" && (
                <Wrap>
                  <FaFileVideo />
                </Wrap>
              )}
              <Text fontSize="0.7em">{quotedMessage.caption}</Text>
            </Stack>
          )}
        </HStack>
        <HStack align="start" color={useColorModeValue("gray.600", "gray.50")}>
          {file && (
            <>
              <Box
                shadow="lg"
                borderRadius="lg"
                overflow="hidden"
                maxW="50%"
                w="100%"
              >
                {file.type.includes("image/") && (
                  <Editor
                    imgSrc={file.base64}
                    width="100px"
                    onDrawingEnd={(e) => {
                      setFile({ ...file, base64: e });
                    }}
                    lineWidth={5}
                  />
                )}
                {file.type.includes("video/") && (
                  <ReactPlayer width="100%" controls={true} url={file.base64} />
                )}
                {file.type.includes("audio/") && (
                  <Player
                    style={{ boxShadow: "none" }}
                    src={[file.base64]}
                    isDark={colorMode}
                  />
                )}
              </Box>
              <IconButton
                icon={<CloseIcon shadow="lg" />}
                onClick={() => setFile(null)}
                colorScheme="red"
                size="sm"
              />
            </>
          )}
        </HStack>
        <Collapse in={openEmoji} animateOpacity>
          <Picker
            theme={useColorModeValue("light", "dark")}
            style={{ width: "100%" }}
            title="Pick your emoji…"
            emoji="point_up"
            skin={1}
            showPreview={false}
            showSkinTones={false}
            onSelect={(e) => {
              setMessageText(messageText + "" + e.native);
            }}
          />
        </Collapse>
        <HStack spacing="5px">
          <IconButton
            onClick={() => {
              setOpenEmoji(true);
            }}
            bg={useColorModeValue("yellow.300", "yellow.400")}
            _hover={{
              color: useColorModeValue("gray.800", "gray.300"),
              bg: useColorModeValue("yellow.400", "gray.500"),
            }}
            size="sm"
            color={useColorModeValue("gray.700", "gray.800")}
            icon={<BsEmojiSmile size="18px" />}
          />

          <IconButton
            display={openEmoji ? "flex" : "none"}
            onClick={() => {
              setOpenEmoji(false);
            }}
            transition="all 0.35s ease-in-out"
            // _hover={{
            //   color: useColorModeValue("gray.800", "gray.300"),
            //   bg: useColorModeValue("gray.200", "gray.700"),
            // }}
            size="sm"
            //color={useColorModeValue("gray.600", "gray.500")}
            colorScheme="red"
            icon={<CloseIcon size="18px" />}
          />
          <Attach setFile={setFile} setType={setType} />
          <Stack flexGrow="1">
            {/* <Stack maxH="300px" h="100%" overflowY="scroll">
                <HStack>
                  <Text
                    fontSize="0.8em"
                    color={useColorModeValue("gray.600", "gray.300")}
                  >
                    QUICK REPLIES
                  </Text>
                </HStack>
                <Stack spacing="10px">
                  <Text
                    cursor="pointer"
                    fontSize="0.8em"
                    color={useColorModeValue("gray.700", "gray.200")}
                  >
                    / Test Quick Reply
                  </Text>
                  <Text
                    cursor="pointer"
                    fontSize="0.8em"
                    color={useColorModeValue("gray.700", "gray.200")}
                  >
                    / Test Quick Reply 3
                  </Text>
                </Stack>
              </Stack> */}
            <TextareaAutosize
              autoFocus
              maxRows="3"
              minRows="1"
              className="messageBox"
              placeholder="Type a message..."
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value);
                setOpenEmoji(false);
              }}
              // disabled={true}
              style={{
                width: "100%",
                borderRadius: "5px",
                padding: "13px",
                background: `${useColorModeValue("white", "#171923")}`,
                boxShadow: "none",
                outline: "none",
                lineHeight: "1.2",
                letterSpacing: "1px",
                color: `${useColorModeValue("#171923", "white")}`,
              }}
            />
          </Stack>
          <Box color={useColorModeValue("gray.600", "gray.500")}>
            <IconButton
              isLoading={loadingSend}
              onClick={sendMessage}
              size="sm"
              icon={<MdSend size="18px" />}
            />
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
}

export default SendBox;
