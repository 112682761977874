import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Circle, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { FcInfo, FcAddressBook } from "react-icons/fc";
import { BsWhatsapp } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { Button } from "@chakra-ui/button";

function ProfileInfo() {
  return (
    <Stack>
      <HStack>
        <Circle
          my="1px"
          size="40px"
          bg={useColorModeValue("gray.700", "transparent")}
          color={useColorModeValue("gray.50", "gray.50")}
          borderWidth="1px"
          borderColor={useColorModeValue("gray.700", "gray.600")}
        >
          <BiUser />
        </Circle>
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Ian Aleck
        </Heading>
      </HStack>
      <Stack borderLeftColor="red.500" borderLeftWidth="2px" pl="15px">
        <HStack color={useColorModeValue("blue.500", "blue.200")}>
          <MdEmail />
          <Text fontSize="md">ianaleckm@gmail.com</Text>
        </HStack>
        <HStack color={useColorModeValue("gray.700", "gray.50")}>
          <Box>
            <BsWhatsapp />
          </Box>
          <Text fontSize="sm" color={useColorModeValue("gray.700", "gray.50")}>
            263771564110
          </Text>
        </HStack>
        <HStack w="100%">
          <Button w="100%" colorScheme="red" size="sm">
            Edit Profile
          </Button>
          <Button w="100%" colorScheme="red" size="sm">
            Transfer Chat
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default ProfileInfo;
