import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Center,
} from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import React, { useEffect, useState } from "react";
import { BiSort } from "react-icons/bi";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import { debounce } from "throttle-debounce";

import { client } from "../../../utils/shopify";
import LinkItem from "./LinkItem";

function LinksList() {
  const [links, setLinks] = useState([]);

  const [type, setType] = useState("all");

  const [order, setOrder] = useState("created:DESC");

  const [selectedLinks, setSelectedLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");

  const [collection, setCollection] = useState();

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const debounceFunc = debounce(1000, false, (query) => {
    setTitle(query);
  });

  useEffect(() => {
    setLoading(true);
    setSelectedLinks([]);

    axiosInstance
      .get("/links", {
        params: { page: page, url: title, type: type, order: order },
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.links) {
          setLinks(data.links);
          setPages(data.pages);
          setPage(data.page);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [title, type, order]);

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      axiosInstance
        .get("/links", {
          params: { page: page, url: title, type: type, order: order },
        })
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          if (data?.data) {
            setLinks([...links, ...data.data]);
            setPages(data.pages);
            setPage(data.page);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [page]);

  const removeLink = (link) => {
    // remove link from state
    setLinks((prev) => prev.filter((l) => l.id !== link.id));
    axiosInstance.get(
      `/removelink?removelink=${link.id}`
    );
  };

  return (
    <Stack p="5">
      {/* The Heading */}
      <HStack justify="space-between">
        <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
          Links
        </Heading>
      </HStack>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        borderRadius="md"
        shadow="md"
        overflow="hidden"
      >
        <Stack position="relative">
          <Flex p="3" direction={{ base: "column", md: "row" }}>
            <InputGroup
              m="1"
              color={useColorModeValue("gray.700", "gray.50")}
              // style={{ visibility: "hidden" }}
            >
              <InputLeftElement children={<SearchIcon />} />
              <Input
                placeholder="Filter links"
                onChange={(e) => {
                  debounceFunc(e.target.value);
                }}
              />
            </InputGroup>
            <HStack m="1">
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Type : {type}
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup onChange={setType} value={type}>
                    <MenuGroup title="Sort by">
                      <MenuItem mt={5}>
                        <Radio value="all" colorScheme="blue" w="100%">
                          All
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="ran" colorScheme="green" w="100%">
                          Ran Today
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="pending" colorScheme="orange" w="100%">
                          Outstanding
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
              <Menu color={useColorModeValue("gray.700", "gray.50")}>
                <MenuButton
                  fontWeight="400"
                  as={Button}
                  leftIcon={<BiSort />}
                  fontSize="0.9em"
                  bg={useColorModeValue("gray.100", "gray.900")}
                  color={useColorModeValue("gray.700", "gray.50")}
                >
                  Sort : {order}
                </MenuButton>
                <MenuList color={useColorModeValue("gray.700", "gray.50")}>
                  <RadioGroup onChange={setOrder} value={order}>
                    <MenuGroup title="Sort by">
                      <MenuItem mt={5}>
                        <Radio value="created:DESC" colorScheme="red" w="100%">
                          Created Latest
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="created:ASC" colorScheme="red" w="100%">
                          Created Oldest
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="ran:DESC" colorScheme="red" w="100%">
                          Updated Latest
                        </Radio>
                      </MenuItem>
                      <MenuItem>
                        <Radio value="ran:ASC" colorScheme="red" w="100%">
                          Updated Oldest
                        </Radio>
                      </MenuItem>
                    </MenuGroup>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
          {loading && (
            <HStack
              p={3}
              bg={"blue.50"}
              position="absolute"
              w="100%"
              top="3em"
              zIndex="1"
            >
              <Spinner size="sm" color="blue.500" />
              <Text color="blue.200">Loading links</Text>
            </HStack>
          )}
        </Stack>
        <Table variant="simple" position="relative">
          <Thead position="sticky" top="0">
            <Tr bg="gray.700">
              <Th col="1" w="20px">
                <Checkbox
                  isChecked={
                    links.length > 0 && links.length === selectedLinks.length
                  }
                  onChange={(e) => {
                    let selections = [];
                    links.forEach((link) => {
                      if (e.target.checked) {
                        selections.push(link.id);
                      }
                    });
                    setSelectedLinks(selections);
                  }}
                />
              </Th>
              <Th w="40px" d={{ base: "none", md: "table-cell" }}></Th>
              <Th>COLLECTION</Th>
              <Th>API</Th>
              <Th>Last Ran</Th>
              <Th>Created</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {links.map((link, index) => (
              <LinkItem
                link={link}
                key={link.tid}
                selectedLinks={selectedLinks}
                setSelectedLinks={setSelectedLinks}
                removeLink={removeLink}
              />
            ))}
          </Tbody>
        </Table>
      </Stack>
      {/* Collections Filters */}
      <Center>
        {pages > page && (
          <Button
            isLoading={loading}
            colorScheme="red"
            onClick={() => {
              setPage(parseInt(page) + 1);
            }}
          >
            Load More
          </Button>
        )}
      </Center>

      {/* Collections List */}
    </Stack>
  );
}

export default LinksList;
