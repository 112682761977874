import { Center, Stack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import React from "react";
import { Spinner } from "@chakra-ui/spinner";

function Loading() {
  return (
    <Center w="100%" h="100vh">
      <Stack align="center">
        <Image src={`/logo.png`} w="100px" h="auto" />
        <Spinner size="40px" />
      </Stack>
    </Center>
  );
}

export default Loading;
