import { Flex, Grid, Image } from "@chakra-ui/react";
import React from "react";

export default function LogoSection({ data }) {
  return (
    <Grid templateColumns={"repeat(4, 1fr)"} shadow="sm" gap={"1px"}>
      {data &&
        data.data &&
        data.data.map((item, index) => (
          <Flex
            bg="white"
            cursor="pointer"
            key={index}
            w="100%"
            p="5"
            justify="center"
            align="center"
            _hover={{ transform: "scale(1.05)", shadow: "md" }}
            transition="all 0.3s ease-in-out"
          >
            <Image
              src={item.imageUrl}
              height={{ base: "50px", md: "80px" }}
              w="100%"
              objectFit={"contain"}
            />
          </Flex>
        ))}
    </Grid>
  );
}
