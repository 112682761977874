import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useToast } from "@chakra-ui/toast";
import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ButtonGroup, Button } from "@chakra-ui/button";
import axiosInstance from "../../../utils/axios";
import RunNow from "./RunNow";

function LinkItem({ link, selectedLinks, setSelectedLinks, removeLink }) {
  const [itemlink, setItemLink] = useState(link);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const testLink = () => {
    setLoading(true);
    axiosInstance
      .get("/testlink?link=" + encodeURIComponent(itemlink.api))
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.sections?.products?.paging?.total_num_found) {
          toast({
            status: "success",
            title:
              data.sections.products.paging.total_num_found + " products found",
            description: "Link is valid",
          });
        } else {
          toast({
            status: "error",
            title: "Error",
            description: "Link is invalid",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast({
          status: "error",
          title: "Error",
          description: "Connection problem",
        });
      });
  };

  const runnow = () => {
    setLoading(true);
    axiosInstance
      .get("/links/runnow/" + itemlink.id)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status && data.status === 200) {
          setItemLink({ ...link, ran: data.ran });
          toast({
            status: "success",
            description: data.message,
          });
        } else {
          toast({
            status: "error",
            description: "Something went wrong, try again or contact dev",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast({
          status: "error",
          description: err.message,
        });
      });
  };

  
  return (
    <Tr
      color={useColorModeValue("gray.700", "gray.50")}
      bg={useColorModeValue("white", "gray.800")}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
      transition={"all 0.35s ease-in-out"}
      cursor="pointer"
      py={0}
    >
      <Td>
        <Checkbox
          isChecked={selectedLinks.includes(itemlink.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedLinks([...selectedLinks, itemlink.id]);
            } else {
              setSelectedLinks(
                selectedLinks.filter((id) => id !== itemlink.id)
              );
            }
          }}
        />
      </Td>
      <Td
        d={{ base: "none", md: "table-cell" }}
        // onClick={() => navigate(`/app/shop/link/${page.id}`)}
      ></Td>
      <Td
        fontSize="0.9em"
        // onClick={() => navigate(`/app/shop/pages/${page.id}`)}
      >
        {itemlink.title}
      </Td>
      <Td
        fontSize="0.7em"
        maxW="200px"
        //onClick={() => navigate(`/app/shop/collections/${page.id}`)}
      >
        {itemlink.url}
      </Td>
      <Td fontSize="0.9em">{itemlink.ran}</Td>
      <Td fontSize="0.9em">
        {itemlink.created.substring(0, 16).replace("T", " ")}
      </Td>
      <Td>
        {" "}
        <ButtonGroup isAttached>
          <Button
            size="sm"
             onClick={() => {removeLink(itemlink)}}
            isLoading={loading}
            disabled={loading}
          >
            Remove
          </Button>
          <Button
            size="sm"
            isLoading={loading}
            disabled={loading}
            onClick={testLink}
          >
            Test Link
          </Button>
          {itemlink && <RunNow link={itemlink} />}
        </ButtonGroup>
      </Td>
    </Tr>
  );
}

export default LinkItem;
