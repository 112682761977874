import { Circle, Heading, HStack, IconButton } from "@chakra-ui/react";
import React from "react";
import { BiTrash } from "react-icons/bi";
import { FaTruckMoving } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";

function TrackOrderEditor({ columnIndex, rowIndex, templateIndex }) {
  const data = useSelector((state) => state.pagedata.value);

  const dispatch = useDispatch();
  const removeSection = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    templates.splice(templateIndex, 1);

    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };
  return (
    <HStack pr="2">
      <Circle
        bg="primary.100"
        borderColor="gray.300"
        borderWidth="2px"
        size="40px"
        shadow="md"
        margin="10px"
      >
        <FaTruckMoving
          color="white"
          size="1em"
          transition="all 0.3s ease-in-out"
          _hover={{ size: "1.6em" }}
        />
      </Circle>
      <Heading size="xs" flexGrow={1}>
        Track Order
      </Heading>
      <IconButton
        size="xs"
        children={<BiTrash />}
        onClick={() => {
          removeSection();
        }}
      />
    </HStack>
  );
}

export default TrackOrderEditor;
