import { Button, IconButton } from "@chakra-ui/button";
import { useColorModeValue, useColorMode } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import Icon from "@chakra-ui/icon";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Input, InputGroup } from "@chakra-ui/input";
import {
  Center,
  Container,
  Grid,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/layout";
import Slim from "../../../../utils/slim/slim.react";
import "../../../../utils/slim/slim.min.css";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Editor } from "@tinymce/tinymce-react";
import axiosInstance from "../../../../utils/axios";
import { useToast } from "@chakra-ui/toast";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDisclosure } from "@chakra-ui/hooks";

function CreateCollection() {
  const [image, setImage] = useState(null);
  const [collection, setCollection] = useState({
    title: "",
    image: null,
    type: "manual",
    description: ""
  });
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    setCollection({ ...collection, image: image });
  }, [image]);

  // const setImage = (image) => {
  //   setCollection({ ...collection, image });
  // };

  const saveCollection = async (force) => {
    setLoading(true);
    let submitedCollection = { ...collection };
    if (editorRef.current) {
      submitedCollection.description = editorRef.current.getContent();
    }
    submitedCollection.force = force;
    console.log(submitedCollection);
    axiosInstance
      .post("/collections/new", submitedCollection)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        console.log(data);
        if (data?.error) {
          toast({
            status: "error",
            title: "Error",
            description: data.message,
            position: "top",
          });
        } else if (data?.warning) {
          onOpen();
        } else {
          toast({
            status: "success",
            title: "Success",
            description: "Collection created successfully",
            position: "top",
          });
          setTimeout(function () {
            navigate("/app/shop/collections/" + data.data.id);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Error",
          description: "Connection error, contact dev support",
          position: "top",
        });
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p="5" spacing="20px">
          <HStack justify="space-between">
            <HStack spacing="20px">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                color={useColorModeValue("gray.700", "gray.50")}
                icon={<ChevronLeftIcon />}
              />
              <Heading
                size="md"
                color={useColorModeValue("gray.700", "gray.50")}
              >
                Create Collection
              </Heading>
            </HStack>
            <Button
              onClick={() => {
                saveCollection(false);
              }}
              isLoading={loading}
              colorScheme="green"
              disabled={!collection?.title}
              size="sm"
            >
              Save
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
              <ModalOverlay />
              <ModalContent
                bg={useColorModeValue("gray.100", "gray.800")}
                color={useColorModeValue("gray.700", "gray.50")}
              >
                <ModalHeader bg={useColorModeValue("gray.50", "gray.900")}>
                  Duplicate Collection(s) found
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    A potential duplicate collection was found. You can choose
                    to change the title, or create duplicate
                  </Text>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" size="sm" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      onClose();
                      saveCollection(true);
                    }}
                  >
                    Create Copy
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </HStack>
          <Grid
            templateColumns={{ base: "100%", md: "8fr 4fr" }}
            gap={"30px"}
            color={useColorModeValue("gray.700", "gray.50")}
          >
            <Stack spacing="30px">
              <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    value={collection?.title}
                    onChange={(e) => {
                      setCollection({ ...collection, title: e.target.value });
                    }}
                    placeholder="Summer collection, under $100, Staff Picks"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description (optional)</FormLabel>
                  <Editor
                    tinymceScriptSrc="https://cdn.tiny.cloud/1/sze44wj07kdbdi9w54xvqbn53xi81mgock8dvdjgdolu31x5/tinymce/5/tinymce.min.js"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue=""
                    init={{
                      branding: false,
                      height: 200,
                      menubar: false,
                      placeholder: "Description...",
                      skin: colorMode === "light" ? "oxide" : "oxide-dark",
                      content_css: colorMode === "light" ? "light" : "dark",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      formats: {
                        removeformat: [
                          {
                            selector: "b,strong,em,i,font,u,strike,code",
                            remove: "all",
                            split: true,
                            expand: false,
                            block_expand: true,
                            deep: true,
                          },
                          {
                            selector: "span",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            deep: true,
                          },
                          {
                            selector: "code",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            deep: true,
                          },
                          {
                            selector: "*",
                            attributes: ["style", "class"],
                            remove: "all",
                            split: true,
                            expand: false,
                            block_expand: true,
                            deep: true,
                          },
                        ],
                      },
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Stack spacing="30px">
              <Stack
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                p="5"
              >
                <FormControl color={useColorModeValue("gray.700", "gray.700")}>
                  <FormLabel color={useColorModeValue("gray.700", "gray.50")}>
                    Collection image
                  </FormLabel>
                  <Slim
                    label="Add collection image"
                    instantEdit={true}
                    maxFileSize={2}
                    didSave={(e) => {
                      setImage(e.output);
                    }}
                    didRemove={() => {
                      setImage(null);
                    }}
                  />
                </FormControl>
              </Stack>
              {/* <Stack
                p="5"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="md"
                shadow="md"
                spacing="30px"
              >
                <FormControl>
                  <FormLabel>Markup</FormLabel>
                  <Input
                    value={collection?.markup}
                    type="number"
                    onChange={(e) => {
                      setCollection({ ...collection, markup: e.target.value });
                    }}
                    placeholder="10,20,30"
                  />
                </FormControl>
              </Stack> */}
            </Stack>
          </Grid>
        </Stack>
      </Container>
    </Center>
  );
}

export default CreateCollection;
