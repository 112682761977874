import { Badge, Box, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { TagsInput } from "react-tag-input-component";
import "./tags.css";
import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";

function ProfileTags({ tags, setTags }) {
  const [text, setText] = useState("");
  return (
    <Stack color={useColorModeValue("gray.700", "gray.50")}>
      <Text fontSize="sm">Client Tags</Text>
      <Wrap>
        {tags.map((tag) => (
          <Badge
            colorScheme={"blue"}
            p="1"
            pr="2px"
            py="2px"
            borderRadius="5px"
          >
            <HStack>
              <Text fontSize="10px">{tag}</Text>
              <Button
                fontSize="10px"
                colorScheme={"blue"}
                minW="20px"
                px="2"
                h="20px"
                onClick={() => {
                  setTags(tags.filter((t) => t !== tag));
                }}
              >
                X
              </Button>
            </HStack>
          </Badge>
        ))}
      </Wrap>
      <Box
        bg={useColorModeValue("gray.200", "gray.700")}
        borderRadius="md"
        // borderColor={useColorModeValue("gray.300", "gray.600")}
        // borderWidth="1px"
      >
        <Input
          value={text}
          placeholder="Enter custom tags"
          fontSize="xs"
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyPress={(event) => {
            if (
              event.key === "Enter" &&
              text.length > 0 &&
              !tags.includes(text)
            ) {
              setTags([...tags, text]);
              setText("");
            }
          }}
        />
        {/* <TagsInput
          // value={tags}
          onChange={setTags}
          name="fruits"
          placeHolder="enter tags"
        /> */}
      </Box>
    </Stack>
  );
}

export default ProfileTags;
