import { useColorModeValue } from "@chakra-ui/color-mode";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Heading, HStack, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import React, { useState } from "react";

function Links() {
  const [loading, setLoading] = useState(false);
  return (
    <Stack spacing="20px">
      <HStack>
        <Heading size="sm">Search Collections</Heading>
      </HStack>
      <InputGroup>
        <Input
          bg={useColorModeValue("gray.50", "gray.700")}
          color={useColorModeValue("gray.700", "gray.50")}
          placeholder={`Search Collections`}
          borderRadius="20px"
          shadow="sm"
          fontSize="0.8em"
          // value={phone}
          // onChange={(e) => setPhone(e.target.value)}
          borderColor={useColorModeValue("gray.300", "gray.500")}
        />

        {loading && (
          <InputRightElement
            cursor="pointer"
            color="gray.300"
            children={<Spinner />}
          />
        )}
      </InputGroup>
    </Stack>
  );
}

export default Links;
