import { Button, IconButton } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Badge, Box, HStack, Stack, Text, Wrap } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { BsBasket } from "react-icons/bs";
import { useParams } from "react-router";
import axiosInstance from "../../../utils/axios";
import useWhatsApp from "../../../utils/useWhatsApp";

function TakealotSubOption({ opt, fees }) {
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(true);
  const [product, setProduct] = useState();
  const [sending, setSending] = useState(false);

  const { phone } = useParams();

  const {
    sendMedia,
    loading: loadingSendMedia,
    error: errorSendMedia,
  } = useWhatsApp();

  const calculate = () => {
    if (product && product?.buybox) {
      return Math.ceil(
        (product.buybox.prices[0] // zar to usd
          ? parseFloat(product.buybox.prices[0])
          : 0) /
          (fees.zar_rate * 100) +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.sa_zim +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.sorting +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.ads +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.transport +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.mfi +
          (product.buybox.prices[0] / (fees.zar_rate * 100)).toFixed() *
            fees.wages
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(
        "https://api.takealot.com/rest/v-1-10-0/product-details/" +
          opt.link_data.fields.plid +
          "?" +
          opt.value.type +
          "=" +
          opt.value.value +
          "&platform=desktop"
      )
      .then((res) => res.data)
      .then((data) => {
        setProduct(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <HStack flexGrow="1" w="100%">
      <Image
        width="80px"
        height="80px"
        borderRadius="5px"
        src={
          opt && opt?.image && opt?.image.length > 0
            ? opt.image[0].replace("{size}", "fb")
            : ""
        }
      />
      <Stack flexGrow="1">
        <Wrap>
          <Skeleton isLoaded={!loading}>
            <Text fontSize="sm">{opt.value.name}</Text>
          </Skeleton>
          <Text fontSize="sm">${calculate()}</Text>
          {product &&
            product?.stock_availability &&
            product?.stock_availability?.distribution_centres &&
            product?.stock_availability?.distribution_centres?.map((center) => (
              <Badge key={center.id} colorScheme="blue">
                {center.text}
              </Badge>
            ))}
        </Wrap>

        {!loading &&
        product &&
        product?.stock_availability &&
        product.stock_availability.distribution_centres.length > 0 ? (
          <HStack align="end" justify="end" w="100%">
            <IconButton
              isLoading={loading}
              colorScheme="blue"
              bg="blue.700"
              size="sm"
              children={<BsBasket />}
            />
            <IconButton
              isLoading={loading || loadingSendMedia}
              colorScheme="red"
              size="sm"
              onClick={() => {
                if (opt && opt?.image && opt?.image.length > 0) {
                  sendMedia({
                    phone: phone,
                    mediaurl: opt.image[0].replace("{size}", "zoom"),
                    caption:
                      "*" +
                      product?.core?.title +
                      "*\n\nUS$" +
                      calculate() +
                      "\n_ID#PID" +
                      product?.core?.id +
                      "_",
                    filename: "image.jpg",
                    callback: () => console.log("sent"),
                  });
                }
              }}
              children={<BiMailSend />}
            />
          </HStack>
        ) : (
          <Box>
            <Badge colorScheme={"red"} p="1">
              Out of Stock
            </Badge>
          </Box>
        )}
      </Stack>
    </HStack>
  );
}

export default TakealotSubOption;
