import React from "react";
import { Route, Routes } from "react-router-dom";
import LinksList from "./LinksList";

function LinksEntry() {
  return (
    <Routes>
      <Route path="/" element={<LinksList />} />
      {/* <Route path="/new" element={<CreateCollection />} />
  <Route path="/:id" element={<EditCollection />} /> */}
    </Routes>
  );
}

export default LinksEntry;
