import { Box, Image, Link } from "@chakra-ui/react";

import React from "react";

function ImageAdvert({ data }) {
  return (
    <Link href="#">
      <Box
        shadow="md"
        bg="white"
        overflow="hidden"
        p="1px"
        transition="all 0.3s ease-in-out"
        borderRadius="3px"
        borderWidth="2px"
        borderColor="gray.50"
        _hover={{ bg: "gray.50", cursor: "pointer" }}
      >
        <Image
          borderRadius="3px"
          src={data.data.imageUrl}
          alt={data.data.alt}
        />
      </Box>
    </Link>
  );
}

export default ImageAdvert;
