import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { setConfig } from "../../../utils/redux/configSlice";
import { useDispatch } from "react-redux";

function FooterMenuItem({ config, position, provided }) {
  const { isOpen: isNavOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const removeNav = () => {
    let nav = [...config.footer];

    nav.splice(position, 1);
    nav[position] = {
      ...nav[position],
    };

    dispatch(setConfig({ ...config, footer: nav }));
  };
  return (
    <>
      <HStack
        p="2"
        shadow="sm"
        justify="space-between"
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <Text>{config.footer[position].title}</Text>

        <HStack>
          <IconButton children={<BiPencil />} onClick={onOpen} size="xs" />
          <IconButton children={<BiTrash />} onClick={removeNav} size="xs" />
          <div {...provided.dragHandleProps}>
            <MdDragIndicator />
          </div>
        </HStack>
      </HStack>
      <Drawer isOpen={isNavOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent color={useColorModeValue("gray.800", "gray.50")}>
          <DrawerCloseButton />
          <DrawerHeader>Edit Menu</DrawerHeader>

          <DrawerBody>
            <Stack>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  value={config.footer[position].title}
                  onChange={(e) => {
                    let nav = [...config.footer];

                    nav[position] = {
                      ...nav[position],
                      title: e.target.value,
                    };

                    dispatch(setConfig({ ...config, footer: nav }));
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Url</FormLabel>
                <Input
                  value={config.footer[position].url}
                  onChange={(e) => {
                    let nav = [...config.footer];

                    nav[position] = {
                      ...nav[position],
                      url: e.target.value,
                    };

                    dispatch(setConfig({ ...config, footer: nav }));
                  }}
                />
              </FormControl>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default FooterMenuItem;
